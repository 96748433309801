import { memo, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { account_create_organization_modal_visible_selector, user_selector } from "entities/account";
import LogoutButton from "entities/account/ui/logout-button";
import { ROUTES } from "shared/config/interface";
import { short_username } from "shared/lib/helpers";
import { ORGANIZATION_ICON } from "shared/lib/helpers/images/images";
import { useAppSelector, useClickOutside } from "shared/lib/hooks";
import { Divider } from "shared/ui";
import { Balance, OrganizationSelect, UserNameInformation } from "widgets/account";

const HeaderDropdown = (): JSX.Element => {
    const { email, username } = useAppSelector(user_selector);
    const [activeDropdown, setActiveDropdown] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);
    const createNewVisible = useAppSelector(account_create_organization_modal_visible_selector);

    useClickOutside(ref, () => {
        if (createNewVisible) return;
        setActiveDropdown(false);
    });

    return (
        <div className="header_widget" data-testid="header_widget">
            <span onClick={() => setActiveDropdown(true)} className="header_widget_main">
                {short_username(username)}
            </span>
            <div ref={ref} className={`dropdown ${activeDropdown ? "active" : ""}`}>
                <UserNameInformation user_name={username} email={email} />
                <Divider />
                <span className="header_widget_org">Organization</span>
                {activeDropdown && <OrganizationSelect />}
                <Balance />
                <Divider />
                <NavLink to={ROUTES.ORGANIZATION_MEMBRS} className="header_widget_link" onClick={() => setActiveDropdown(false)}>
                    <ORGANIZATION_ICON />
                    Organization management
                </NavLink>
                <LogoutButton />
                <div className="header_widget_terms">
                    Our <NavLink to={ROUTES.TERMS}>Terms of use</NavLink> and <NavLink to={ROUTES.PRIVACY}> Privacy and cookies policy</NavLink>
                </div>
            </div>
        </div>
    );
};

export default memo(HeaderDropdown);
