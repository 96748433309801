import { FailResponse, ProfileSuccessResponse, SuccessResponse } from "@greatsumini/react-facebook-login";
import { ITokens } from "shared/config/interface";
import { local_logger } from "shared/lib/helpers";
import { useFacebookLoginMutation } from "../api/useFacebookAuth-api";
import { useNotification } from "../../useNotification/useNotification";

interface IFacebookAuthResponse {
    // facebookLoginAsync: (args: any) => void;
    isLoading: boolean;
    isSuccess: boolean;
    data: ITokens | undefined;
    onSuccess: (response: SuccessResponse) => void;
    onFail: (error: FailResponse) => void;
    onProfileSuccess: (response: ProfileSuccessResponse) => void;
}

const useFacebookAuth = (): IFacebookAuthResponse => {
    const [facebookLoginAsync, { isLoading, isSuccess, data }] = useFacebookLoginMutation();
    const notification = useNotification();

    const onSuccess = (response: SuccessResponse): void => {
        facebookLoginAsync({
            access_token: response.accessToken
        });
        local_logger("@/Facebook Login Success!", response);
    };

    const onFail = (error: FailResponse): void => {
        notification.error({
            message: `Facebook login failed ${error.status}`,
            duration: 6
        });
        local_logger("@/Facebook Login Failed!", error);
    };

    const onProfileSuccess = (response: ProfileSuccessResponse): void => {
        local_logger("@/Facebook Get Profile Success!", response);
    };

    return {
        // facebookLoginAsync,
        isLoading,
        isSuccess,
        data,
        onSuccess,
        onFail,
        onProfileSuccess
    };
};

export default useFacebookAuth;
