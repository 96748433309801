import { FEEDBACK_STEPS } from "entities/account";

export const stars = Array(5).fill("");

export const step_descriptions: Record<number, string> = {
    0: "👋 Please rate your experience on a 5-star scale.",
    1: "😡 Poor experience: Needs significant improvement.",
    2: "😕 Below average: Not quite satisfied.",
    3: "😐 Average, need improvement.",
    4: "😀 Good experience: Mostly satisfied.",
    5: "😍 Amazing experience: Love it."
};

export const descriptions: Record<FEEDBACK_STEPS, string> = {
    [FEEDBACK_STEPS.create_project]: "the project creation process",
    [FEEDBACK_STEPS.create_task]: "the task creation process",
    [FEEDBACK_STEPS.top_up_balance]: "the top up balance process"
};
