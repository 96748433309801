import { memo } from "react";
import { LYD_LOGO } from "shared/lib/helpers/images/images";
import "./title.scss";

const Title = ({ text }: { text: string }): JSX.Element => {
    return (
        <>
            <img className="authorization_logo" src={LYD_LOGO} alt="LYD" />
            <h1 className="authorization_title">{text}</h1>
        </>
    );
};

export default memo(Title);
