import { ILabel, MIXPANEL_TRACK_KEYS } from "shared/config/interface";
import { ICreateProject, ITaskPriceOptions } from "../types/interface";
import { Mixpanel } from "shared/lib/services/mixpanel";
import { InstrumentsEnum } from "entities/calculator";

export class CreateProjectService {
    static get_attributes_count(labels: ILabel[]): number {
        let result: number = 0;

        labels.forEach((label) => {
            if (label?.attributes) {
                result += label?.attributes?.length;
            }
        });

        return result;
    }

    static log_project_data(project: ICreateProject): void {
        let data = {
            labels_count: project.labels.length,
            attributes_count: this.get_attributes_count(project.labels),
            annotation_type: project.annotation_type,
            annotation_tool: project.annotation_tool
        };

        Mixpanel.track(MIXPANEL_TRACK_KEYS.CREATE_PROJECT, data);
    }

    static price_options(current_price_key: InstrumentsEnum, base_cost: number): ITaskPriceOptions[] {
        let price_options: ITaskPriceOptions[] = [];

        price_options.push({
            object_name: current_price_key,
            base_cost
        });

        return price_options;
    }

    static attributes_for_calculator(labels: ILabel[]): number {
        let attr = labels.reduce((accumulator: number, currentValue: ILabel) => {
            if (currentValue.attributes) {
                return accumulator + currentValue.attributes.length;
            } else {
                return accumulator + 0;
            }
        }, 0);

        return attr;
    }
}
