import { useCallback } from "react";
import { useAppDispatch } from "../../../../../shared/lib/hooks/redux/redux";
import { ITokens, TOKENS } from "shared/config/interface";
import { set_is_auth } from "entities/account";

interface IUseLoginResponse {
    login: (tokens: ITokens) => void;
}

export const useLogin = (): IUseLoginResponse => {
    const dispatch = useAppDispatch();

    const login = useCallback(
        (tokens: ITokens): void => {
            localStorage.setItem(TOKENS.TOKEN, tokens.key);
            localStorage.setItem(TOKENS.TOKEN_CVAT, tokens.key_cvat);
            dispatch(set_is_auth(true));
        },
        [dispatch]
    );

    return {
        login
    };
};
