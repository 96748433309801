import { DashboardProjectType } from "entities/dashboard";

export function mergeProjects(project1: DashboardProjectType, project2: DashboardProjectType): DashboardProjectType {
    let newID: number = project1.id;
    let completed = project2.progress_bar.completed_tasks === project2.progress_bar.total_tasks;

    if (completed) {
        newID = project2.id;
    }

    return {
        ...project1,
        progress_bar: {
            total_tasks: (project1.progress_bar ? project1.progress_bar.total_tasks : 0) + (project2.progress_bar ? project2.progress_bar.total_tasks : 0),
            completed_tasks: (project1.progress_bar ? project1.progress_bar.completed_tasks : 0) + (project2.progress_bar ? project2.progress_bar.completed_tasks : 0)
        },
        id: newID,
        name: project1.name
    };
}
