import { useState } from "react";
import "./organization-tabs.scss";
import { ORGANIZATION_PAGE_TABS } from "entities/account";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "shared/config/interface";
import { ITab, Tabs } from "shared/ui";

const TABS: ITab<ORGANIZATION_PAGE_TABS>[] = [
    { label: "Members", value: ORGANIZATION_PAGE_TABS.MEMBERS, visible: true },
    { label: "API Keys", value: ORGANIZATION_PAGE_TABS.API, visible: true },
    { label: "Cloud Storage", value: ORGANIZATION_PAGE_TABS.CLOUD_STORAGE, visible: true }
];

interface IProps {
    defaultActive: ORGANIZATION_PAGE_TABS;
}

const OrganizationTabs = ({ defaultActive }: IProps): JSX.Element => {
    const [active, setActive] = useState<ORGANIZATION_PAGE_TABS>(() => defaultActive);
    const navigate = useNavigate();

    const onChange = (value: ORGANIZATION_PAGE_TABS): void => {
        navigate("/" + ROUTES.ORGANIZATION + "/" + value);
        setActive(value);
    };

    return (
        <div className="organization_tabs">
            <Tabs active={active} tabs={TABS} onChange={onChange} />
        </div>
    );
};

export default OrganizationTabs;
