import { LOGOUT_ICON } from "shared/lib/helpers/images/images";
import { useAppDispatch } from "shared/lib/hooks";
import { memo } from "react";
import { accountApiSlice } from "../model/api/api";
import { set_is_auth, logout as logout_reducer } from "../model/slice/account-slice";
import { local_logger } from "shared/lib/helpers";
import { Spin } from "shared/ui";
import { Mixpanel } from "shared/lib/services/mixpanel";
import { clearOnboarding } from "entities/onboarding";

const LogoutButton = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const [logout, { isLoading }] = accountApiSlice.useLogoutMutation();

    async function onLogout(e: React.MouseEvent<HTMLElement>): Promise<void> {
        e.preventDefault();
        try {
            await logout();
            dispatch(logout_reducer());
            dispatch(set_is_auth(false));
            dispatch(clearOnboarding());
            Mixpanel.reset();
        } catch (error) {
            local_logger("@/Logout error", { error });
        }
    }

    if (isLoading) return <Spin />;

    return (
        <a href="/" onClick={onLogout} className="header_widget_link" data-testid="logout_btn">
            <LOGOUT_ICON />
            Logout
        </a>
    );
};

export default memo(LogoutButton);
