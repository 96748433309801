/* eslint-disable react/no-array-index-key */
import { Button, Information, Modal } from "shared/ui";
import { useMemo, useState } from "react";
import { RATE_STAR, SUCCESS_ICON } from "shared/lib/helpers/images/images";
import { FEEDBACK_STEPS, IFeedbackPayload, IFeedbackPayloadSkip } from "entities/account";
import { descriptions, stars, step_descriptions } from "./data";
import "./styles.scss";

interface IProps {
    current_key: FEEDBACK_STEPS;
    onClose?: () => void;
}
const Feedback = ({ current_key, onClose }: IProps): JSX.Element | null => {
    // const step = FEEDBACK_STEPS.create_project;
    const step = current_key;

    const [rate, setRate] = useState<number>(0);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [comment, setComment] = useState<string>("");

    // const feedback_steps = useAppSelector(feedback_steps_selector);
    const feedback_steps = [FEEDBACK_STEPS.create_project];

    const onRate = (index: number): void => {
        setRate(index + 1);
    };

    const onSkip = (): void => {
        let data: IFeedbackPayloadSkip = {
            skip: true,
            step: FEEDBACK_STEPS[step]
        };

        if (onClose) {
            onClose();
        }
    };

    const onSubmit = (): void => {
        let data: IFeedbackPayload = {
            rate,
            comment,
            step: FEEDBACK_STEPS[step]
        };

        if (onClose) {
            onClose();
        }
    };

    const isVisible = useMemo(() => {
        if (feedback_steps.includes(current_key)) {
            return true;
        }

        return false;
    }, [current_key]);

    if (!feedback_steps) return null;
    if (feedback_steps.length === 0) return null;
    if (!feedback_steps.includes(current_key)) return null;

    if (isSuccess) {
        return (
            <div className="rate">
                <Information
                    title="Thank you! 🤝"
                    description="Thanks for submitting your feedback! We really appreciate your time."
                    image={
                        <div className="rate_success_image">
                            <img src={SUCCESS_ICON} alt="Success" />
                        </div>
                    }
                />
                {
                    <span className="rate_link">
                        Please share your positive experience by leaving a review at this
                        <a href="https://www.g2.com/products/label-your-data/take_survey" target="_blank" rel="noopener noreferrer">
                            {" "}
                            link
                        </a>
                    </span>
                }
            </div>
        );
    }

    return (
        <Modal visible={isVisible} onCancel={() => {}} width={400} title="How's it going? 👏" closable={false}>
            <div className="rate">
                <p className="rate_description">
                    We would love to hear from you! How was your experience with <b>{descriptions[FEEDBACK_STEPS[step]]}</b>?
                </p>
                <div className="rate_stars">
                    {stars.map((_, index) => (
                        <span onClick={() => onRate(index)} className={index + 1 <= rate && rate !== 0 ? "filled" : "standart"} key={index + 1}>
                            <RATE_STAR />
                        </span>
                    ))}
                </div>
                <small className="rate_placeholder">{step_descriptions[rate]}</small>
                <textarea value={comment} onChange={(e) => setComment(e.target.value)} className="rate_textarea" rows={5} placeholder="Any comment for us?" />
                <div className="rate_btn">
                    <Button onClick={onSkip} size="small" typeStyle="ghost">
                        Skip
                    </Button>
                    <Button onClick={onSubmit} disabled={rate === 0} size="small">
                        Submit
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default Feedback;
