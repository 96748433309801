import { Link, NavLink } from "react-router-dom";
import { LYD_LOGO, DASHBOARD_ICON, CALCULATE_ICON, BILLING_ICON } from "shared/lib/helpers/images/images";
import { ROUTES } from "shared/config/interface";
import "./header.scss";
import "./header-widget.scss";
import HeaderDropdown from "../header-dropdown/header-dropdown";

const Header = (): JSX.Element => {
    return (
        <header className="header">
            <div className="header_container container">
                <Link className="header_logo" to={ROUTES.DASHBOARD}>
                    <img src={LYD_LOGO} alt="LYD" />
                </Link>
                <div className="header_nav">
                    <NavLink to={ROUTES.DASHBOARD}>
                        {" "}
                        <DASHBOARD_ICON />
                        Dashboard
                    </NavLink>
                    <NavLink to={ROUTES.CALCULATOR}>
                        {" "}
                        <CALCULATE_ICON /> Calculator
                    </NavLink>
                    <NavLink to={ROUTES.BILLING}>
                        <BILLING_ICON /> Billing
                    </NavLink>
                </div>
                <HeaderDropdown />
            </div>
        </header>
    );
};

export default Header;
