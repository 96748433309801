import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBillingState } from "../types/interface";

const INITIAL_STATE: IBillingState = {
    loading: false,
    isShowPaymentForm: false,
    client_secret_key: null,
    sumValue: "",
    addressActiveId: -1, // -1 if empty form
    billingAddressWasChange: false
};

const billingSlice = createSlice({
    name: "billing",
    initialState: INITIAL_STATE,
    reducers: {
        setClientSecretKey(state, action: PayloadAction<string>) {
            state.client_secret_key = action.payload;
        },
        setIsShowPayment(state, action: PayloadAction<boolean>) {
            state.isShowPaymentForm = action.payload;
        },
        setSumValue(state, action: PayloadAction<number>) {
            state.sumValue = action.payload;
        },
        clearBillingData(state) {
            state.loading = false;
            state.isShowPaymentForm = false;
            state.client_secret_key = null;
            state.sumValue = "";
        },
        setAddressActiveId(state, action: PayloadAction<number>) {
            state.addressActiveId = action.payload;
        },
        setBillingAddressWasChange(state, action: PayloadAction<boolean>) {
            state.billingAddressWasChange = action.payload;
        }
    }
});

export const { setClientSecretKey, setIsShowPayment, setSumValue, clearBillingData, setAddressActiveId, setBillingAddressWasChange } = billingSlice.actions;
export default billingSlice.reducer;
