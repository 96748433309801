import "./organization-api-table.scss";
import { IApiKeyInfo } from "entities/account";
import { Confirm, Loader, Spin, Status, Table, Information } from "shared/ui";
import { GlobalStatuses, IColumnTable } from "shared/config/interface";
import { dateToDisplayFormat } from "shared/lib/helpers";
import { BUSKET_ICON, LOCK_ICON } from "shared/lib/helpers/images/images";

interface IProps {
    data: IApiKeyInfo[] | undefined;
    isLoading: boolean;
    isLoadingDelete: boolean;
    deleteApiKey: (prefix: string) => void;
}

const OrganizationApiTable = ({ data, isLoading, deleteApiKey, isLoadingDelete }: IProps): JSX.Element => {
    const COLUMNS: IColumnTable<IApiKeyInfo>[] = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (key) => (
                <div className="org_api_table_name">
                    <span className="org_api_table_name_full">{key.key_name}</span>
                    <span className="org_api_table_name_prefix">{key.prefix}*******-*******</span>
                </div>
            )
        },
        {
            title: "API Key",
            dataIndex: "api_key",
            key: "api_key",
            render: (key) => <Status type={GlobalStatuses.COMPLETED} title="Enabled" />
        },
        {
            title: "Created on",
            dataIndex: "created",
            key: "created",
            render: (key) => dateToDisplayFormat(key.created)
        },
        {
            title: "Revoke API key",
            width: 200,
            dataIndex: "revoke",
            key: "revoke",
            render: (key) => (
                <span>
                    {isLoadingDelete ? (
                        <Spin />
                    ) : (
                        <Confirm title="Are you sure you want to delete this API key?" onConfirm={() => deleteApiKey(key.prefix)} okText="OK" cancelText="Cancel">
                            <BUSKET_ICON style={{ cursor: "pointer" }} />
                        </Confirm>
                    )}
                </span>
            )
        }
    ];

    if (isLoading) {
        return <Loader />;
    }

    if ((!data && isLoading === false) || (data?.length === 0 && isLoading === false)) {
        return (
            <div className="org_api_table_wrap">
                <Information image={<LOCK_ICON />} title="Sorry" description="No data" />
            </div>
        );
    }

    return (
        <div className="org_api_table_wrap">
            <Table columns={COLUMNS} data={data || []} />
        </div>
    );
};

export default OrganizationApiTable;
