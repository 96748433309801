export const rules = {
    email: {
        required: "Email is required",
        maxLength: { value: 60, message: "maxLength 60" },
        minLength: { value: 3, message: "minLength 3" },
        pattern: {
            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
            message: "Email is not valid"
        }
    },
    username: {
        required: "User Name is required",
        maxLength: { value: 150, message: "maxLength 150" },
        minLength: { value: 3, message: "minLength 3" },
        pattern: { value: /^[a-zA-Z]/, message: "Latin characters only" }
    },
    password: {
        required: "Password is required",
        maxLength: { value: 30, message: "Max length 30" },
        minLength: { value: 8, message: "Password must have at least 8 characters" },
        pattern: {
            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ -/:-@[-`{-~])[a-zA-Z\d -/:-@[-`{-~]+$/,
            message: "The password needs ≥1 Uppercase, ≥1 Digit, ≥1 Special Symbol."
        }
    },
    confirm_password: (password: string | {}, watch: any) => ({
        required: "Confirm password is required",
        maxLength: { value: 30, message: "maxLength 30" },
        minLength: { value: 8, message: "Password must have at least 8 characters" },
        validate: (value: string) => value === password || "The passwords do not match",
        onChange: (e: any) => (password = watch("password", ""))
    }),
    attribute_name: {
        required: "This field is required",
        maxLength: {
            value: 37,
            message: "Max 37 symbols"
        },
        pattern: {
            value: /^[A-Za-z0-9\s~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]+$/,
            message: "Attribute must contains only Latin characters"
        }
    },
    attribute_number: {
        required: true,
        validate: (value: any) => {
            if (typeof value === "string") {
                const numbers: number[] = value.split(";").map((number: any): number => Number.parseFloat(number));

                if (numbers.length !== 3) {
                    return "Three numbers are expected";
                }

                // eslint-disable-next-line no-restricted-syntax
                for (const number of numbers) {
                    if (Number.isNaN(number)) {
                        return `"${number}" is not a number`;
                    }
                }

                const [min, max, step] = numbers;

                if (min >= max) {
                    return "Minimum must be less than maximum";
                }

                if (max - min < step) {
                    return "Step must be less than min-max difference";
                }

                if (step <= 0) {
                    return "Step must be a positive number";
                }

                return true;
            }
        }
    },
    project_name: {
        required: "Project name is required field",
        maxLength: { value: 50, message: "Max length 50" },
        minLength: { value: 3, message: "Min length 3" },
        pattern: {
            value: /^(?=.*[A-Za-z])^[^\\/|]*$/,
            message: "Project name must contains only Latin characters"
        }
    },
    label_name: {
        required: "Label is required field",
        // maxLength: { value: 37, message: "max Length 37" },
        pattern: {
            value: /^[A-Za-z0-9\s~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]+$/,
            message: "Label must contains only Latin characters"
        }
    },
    label_name_quick_upload: {
        required: "Label is required field",
        validate: (value: string) => {
            if (value.trim().length === 0) {
                return "Label is required field";
            }
            if (/^[A-Za-z0-9\s~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]+$/.test(value) === false) {
                return "Label must contains only Latin characters";
            }

            const items = value.split(",");
            const uniqueItems = new Set(items);

            if (uniqueItems.size !== items.length) {
                return "Must be unique label name";
            }

            return true;
        }
    },
    average_objects_per_frame: {
        required: "Average number is required field",
        min: { value: 1, message: "Min value must be 1" },
        max: { value: 1000, message: "Max value must be 1000" }
    },
    project_instruction: {
        pattern: {
            value: /^[A-Za-z0-9\s~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]+$/,
            message: "Instruction must contains only Latin characters"
        }
    },
    summary: {
        required: "Summary is required field",
        maxLength: { value: 100, message: "Max Length 100" }
    }
};
