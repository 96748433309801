import React from "react";

type Props = {
    on_today: () => void;
};

const DatePickerFooter = ({ on_today }: Props): JSX.Element => {
    return (
        <div className="dp_calendar_today">
            <span onClick={on_today}>Today</span>
        </div>
    );
};

export default DatePickerFooter;
