import { memo, useEffect, useState } from "react";
import { COMPLETED_ICON } from "shared/lib/helpers/images/images";
import { useNotification } from "shared/lib/hooks";
import { Information, Loader } from "shared/ui";
import { useCheckActivationToken, ApiErrorResponse } from "entities/authorization";

const SuccessActivation = (): JSX.Element => {
    const notification = useNotification();
    const { errorActivation, isErrorActivation, isLoadingActivation, isSuccessActivation } = useCheckActivationToken();
    const [successActivation, setSuccessActivation] = useState<boolean>(false);

    useEffect(() => {
        if (isSuccessActivation) {
            setSuccessActivation(true);
        }
    }, [isSuccessActivation]);

    useEffect(() => {
        if (errorActivation?.hasOwnProperty("data")) {
            const customError = errorActivation as unknown as ApiErrorResponse;
            let arr = Object.values(customError.data);
            arr.map((message: any) => {
                notification.error({
                    message
                });
            });
        }
    }, [isErrorActivation, errorActivation, notification]);

    if (isLoadingActivation) {
        <Loader />;
    }

    return <>{successActivation && <Information image={<COMPLETED_ICON />} title="Congratulation" description="You can sign in now." />}</>;
};

export default memo(SuccessActivation);
