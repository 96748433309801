import {
    useUpdateTaskMutation,
    useLazyGetProjectTasksActiveQuery,
    useLazyGetProjectTasksHistoryQuery,
    useCreateTaskMutation,
    useUploadTasksDataMutation,
    useFinishPartialTaskMutation,
    taskApiExternal
} from "./model/api/api";
import { TaskService } from "./model/services/task-service";
import { TASK_COLUMNS } from "./model/types/config";
import { ICreateTaskPayload } from "./model/types/types";

export {
    useUpdateTaskMutation,
    useLazyGetProjectTasksActiveQuery,
    useLazyGetProjectTasksHistoryQuery,
    useCreateTaskMutation,
    useUploadTasksDataMutation,
    TASK_COLUMNS,
    useFinishPartialTaskMutation,
    TaskService,
    taskApiExternal
};

export type { ICreateTaskPayload };
