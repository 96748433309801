import { Outlet } from "react-router-dom";
import Header from "./header/header";

const Layout = (): JSX.Element => {
    return (
        <div className="container layout">
            <Header />
            <main>
                <Outlet />
            </main>
        </div>
    );
};

export default Layout;
