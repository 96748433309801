import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";

interface Props {
    children: React.ReactNode;
}

const Portal = ({ children }: Props): React.ReactPortal => {
    const [container] = useState<any>(() => document.getElementById("portal_root"));

    useEffect(() => {
        // let body = document.querySelector('body');
        // if (body) {
        //     body.style.overflow = 'hidden'
        // }

        return () => {
            // if (body) {
            //     body.style.overflow = ''
            // }
        };
    }, []);

    return createPortal(children, container);
};

export default Portal;
