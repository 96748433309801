import { lazy } from "react";

const BillingPage = lazy(() => import("./billing/billing"));
const DashboardPage = lazy(() => import("./dashboard/dashboard"));
const CreateProjectPage = lazy(() => import("./create-project/create-project"));
const ProectPage = lazy(() => import("./project/project"));
const SignUpPage = lazy(() => import("./authorization/sign-up/sign-up"));
const SignInPage = lazy(() => import("./authorization/sign-in/sign-in"));
const ResetPassword = lazy(() => import("./authorization/reset-password/reset-password"));
const ResetPasswordNew = lazy(() => import("./authorization/reset-password/reset-password-new"));
const ConfirmEmail = lazy(() => import("./authorization/confirm-email/confirm-email"));
const CalculatorPage = lazy(() => import("./calculator/calculator"));
const OrganizationPage = lazy(() => import("./organization/organization"));
const TermsOfUsePage = lazy(() => import("./terms-and-privacy/terms-of-use"));
const PrivacyPage = lazy(() => import("./terms-and-privacy/privacy"));

export {
    BillingPage,
    DashboardPage,
    CreateProjectPage,
    ProectPage,
    SignUpPage,
    SignInPage,
    ResetPassword,
    ResetPasswordNew,
    ConfirmEmail,
    CalculatorPage,
    OrganizationPage,
    TermsOfUsePage,
    PrivacyPage
};
