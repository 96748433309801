import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBillingState } from "../types/interface";

const INITIAL_STATE: IBillingState = {
    loading: false,
    isShowPaymentForm: false,
    client_secret_key: null,
    sumValue: ""
};

const billingSlice = createSlice({
    name: "billing",
    initialState: INITIAL_STATE,
    reducers: {
        setClientSecretKey(state, action: PayloadAction<string>) {
            state.client_secret_key = action.payload;
        },
        setIsShowPayment(state, action: PayloadAction<boolean>) {
            state.isShowPaymentForm = action.payload;
        },
        setSumValue(state, action: PayloadAction<number>) {
            state.sumValue = action.payload;
        },
        clearBillingData(state) {
            state.loading = false;
            state.isShowPaymentForm = false;
            state.client_secret_key = null;
            state.sumValue = "";
        }
    }
});

export const { setClientSecretKey, setIsShowPayment, setSumValue, clearBillingData } = billingSlice.actions;
export default billingSlice.reducer;
