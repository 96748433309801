import React from "react";
import "./input.scss";

interface IProps {
    label: string;
    checked?: boolean;
    disabled?: boolean;
    onChange?: () => void;
    props?: any;
    name?: string;
}

const Checkbox: React.FC<IProps> = ({ label, checked = false, name, disabled = false, onChange, ...props }): JSX.Element => {
    return (
        <label className="checkbox">
            <input type="radio" checked={checked} disabled={disabled} onChange={onChange} className={checked ? "checked" : ""} name={name} {...props} />
            <span>{label}</span>
        </label>
    );
};

export default Checkbox;
