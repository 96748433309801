import TagManager from "react-gtm-module";
import { IS_REACT_APP_DEV } from "shared/config/config";

interface GTMEventData {
    [key: string]: string;
    event: string;
}

class GTMService {
    private isInitialized: boolean;

    constructor() {
        this.isInitialized = false;
    }

    initialize(): void {
        if (IS_REACT_APP_DEV) {
            console.warn("GTM initialization skipped: not in production environment.");
            return;
        }

        const GTM_ID = process.env.REACT_APP_GTM;

        if (!GTM_ID) {
            console.error("GTM ID is required to initialize Google Tag Manager.");
            return;
        }
        console.log("GTM-success initialize");
        if (!this.isInitialized) {
            TagManager.initialize({
                gtmId: GTM_ID!
            });
            this.isInitialized = true;
        }
    }

    pushEvent(eventData: GTMEventData): void {
        if (IS_REACT_APP_DEV) return;
        if (!eventData || typeof eventData !== "object") return;

        if (!this.isInitialized) {
            console.error("GTM is not initialized. Call initialize() first.");
            return;
        }
        console.log("GTM-success pushEvent");
        TagManager.dataLayer({
            dataLayer: eventData
        });
    }

    isGTMInitialized(): boolean {
        return this.isInitialized;
    }
}

const gtmService = new GTMService();
export default gtmService;
