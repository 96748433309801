import { useGenerateApiKeyMutation, useApiKeyInfoQuery, useDeleteApiKeyMutation, accountApiSlice } from "./model/api/api";

import ErrorBoundaryContent from "./ui/error-boundary-content";
import { ORGANIZATION_PAGE_TABS, FEEDBACK_STEPS } from "./model/types/types";
import { useIsOwnerCurrentOrganization } from "./hooks/useIsOwnerCurrentOrganization";

export { is_auth_selector } from "./model/selectors/is-auth-selector";
export { balance_selector, balance_initialize_selector } from "./model/selectors/balance-selector";
export {
    account_organization_selector,
    account_organizations_list_selector,
    account_create_organization_modal_visible_selector,
    account_collaborator_cvat_ids_selector
} from "./model/selectors/account-organization-selector";

export type { IUser, IClientException, IContactUsPayload, IOrganization, ICollaborator, IApiKeyInfo, IFeedbackPayloadSkip, IFeedbackPayload } from "./model/types/types";

export { default as accountSlice } from "./model/slice/account-slice";

export { default } from "./ui/logout-button";
export { default as MeProvider } from "./ui/me-provider";

export {
    ErrorBoundaryContent,
    ORGANIZATION_PAGE_TABS,
    FEEDBACK_STEPS,
    useGenerateApiKeyMutation,
    useApiKeyInfoQuery,
    useDeleteApiKeyMutation,
    useIsOwnerCurrentOrganization,
    accountApiSlice as account_api
};

export { user_username_selector, user_email_selector, user_selector, user_groups_selector, feedback_steps_selector } from "./model/selectors/user-selector";

export { set_user, set_is_auth, logout, update_balance, set_current_organization, setCreateOrganizationModalVisible } from "./model/slice/account-slice";
