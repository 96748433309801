import { Modal } from "shared/ui";
import { memo, useEffect, useState } from "react";
import { account_api, FEEDBACK_STEPS, IFeedbackPayload, IFeedbackPayloadSkip } from "entities/account";
import FeedbackStars from "./feedback-stars";
import FeedbackActions from "./feedback-actions";
import FeedbackSuccess from "./feedback-success";
import "./styles.scss";

interface IProps {
    current_key: FEEDBACK_STEPS;
    onClose: () => void;
}
const Feedback = ({ current_key, onClose }: IProps): JSX.Element | null => {
    const [feedback_mutation, { isSuccess, isLoading }] = account_api.useFeedbackSubmitMutation();
    const [rate, setRate] = useState<number>(0);
    const [comment, setComment] = useState<string>("");
    const [isSkip, setIsSkip] = useState<boolean>(false);

    useEffect(() => {
        if (isSuccess && isSkip) {
            setIsSkip(false);
            onClose();
        }
    }, [isSuccess]);

    const onRate = (index: number): void => {
        setRate(index + 1);
    };

    const onSkip = (): void => {
        setIsSkip(true);
        let data: IFeedbackPayloadSkip = {
            step: FEEDBACK_STEPS[current_key]
        };

        feedback_mutation(data);
    };

    const onSubmit = (): void => {
        let data: IFeedbackPayload = {
            rate,
            comment,
            step: FEEDBACK_STEPS[current_key]
        };

        feedback_mutation(data);
    };
    if (isSuccess && rate > 0 && !isSkip)
        return (
            <Modal visible={true} onCancel={() => onClose()} width={400} title="How's it going? 👏" closable={true}>
                <FeedbackSuccess rate={rate} />
            </Modal>
        );
    return (
        <Modal visible={true} onCancel={() => {}} width={400} title="How's it going? 👏" closable={false}>
            <div className="rate">
                <FeedbackStars onRate={onRate} rate={rate} step={current_key} />
                <textarea value={comment} onChange={(e) => setComment(e.target.value)} className="rate_textarea" rows={5} placeholder="Any comment for us?" />
                <FeedbackActions onSkip={onSkip} onSubmit={onSubmit} rate={rate} isLoading={isLoading} />
            </div>
        </Modal>
    );
};

export default memo(Feedback);
