import Switch from "./switch/switch";
import Confirm from "./confirm/confirm";
import Divider from "./divider/divider";
import Progress from "./progress/progress";
// import UploadFileList from "./upload-file-list/upload-file-list";
import UploadFile from "./upload-file/upload-file";
import Tooltip from "./tooltip/tooltip";
import Spin from "./spin/spin";
import Loader from "./loader/loader";
import Portal from "./portal";
import Modal from "./modal/modal";
import Checkbox from "./checkbox/checkbox";
import BlockWrapper from "./block-wrapper/block-wrapper";
import Button from "./button/button";
import Pagination from "./pagination/pagination";
import FacebookButtonWrapper from "./facebook-button-wrapper/facebook-button-wrapper";
import Information from "./information/information";
import Input from "./input/input";
import FormItem from "./form-item/form-item";
import Status from "./status/status";
import Alert from "./alert/alert";
import ButtonCards from "./button-cards/button-cards";
import Steps from "./steps/steps";
import SwitchCard from "./switch-card/switch-card";
import Label from "./label/label";
import Editable from "./editable/editable";
import Table from "./table/table";
import Tabs from "./tabs/tabs";
import DatePicker from "./date-picker/date-picker";

export {
    Switch,
    Confirm,
    Divider,
    Progress,
    // UploadFileList,
    UploadFile,
    Tooltip,
    Spin,
    Loader,
    Portal,
    Modal,
    Checkbox,
    BlockWrapper,
    Button,
    Pagination,
    FacebookButtonWrapper,
    Information,
    Input,
    FormItem,
    Status,
    Alert,
    ButtonCards,
    Steps,
    SwitchCard,
    Label,
    Editable,
    Table,
    Tabs,
    DatePicker
};

export type { ITab } from "./tabs/tabs";
