export const COMPUTER_VISION_PROJECT = ["General", "Set labels", "Instructions", "Overview"];

export const DEFAULT_INSTRUCTIONS = {
    summary_instruction: {
        text: "",
        files: []
    },
    workflow_instruction: {
        text: "",
        files: []
    },
    description_instruction: {
        text: "",
        files: []
    }
};

export const INITIAL_FORM_VALUES = {
    name: "",
    attributes: []
};

export let EDITOR_CONFIG = {
    HEIGHT: 900,
    MAX_WIDTH: 1136,
    IMAGE_WIDTH: 600,
    API_KEY: process.env.REACT_APP_TINYMCE_KEY,
    PLUGINS: ["advlist", "autolink", "lists", "link", "image", "preview", "code", "image", "editimage"],
    TOOLBAR: `"undo redo | blocks | " + " fontsize bold italic forecolor | alignleft aligncenter " + "alignright alignjustify | bullist numlist outdent indent | image | help "`,
    MENU_BAR: "edit view table tools help",
    NONEDITABLE: "mceNonEditable",
    CONTENT_STYLE: `
        body {
            outline: none;
            font-family:Roboto,Arial,sans-serif;
            font-size:14px;
            overflow-x:hidden;
        },
        img { 
            max-width: 100%;
            height: auto;
        }
    `,
    COMMENTS_AUTHOR: "LYD",
    DEFAULT_BLOCK: `
        <p>Please fill out these fields</p>
        <p>&nbsp;</p>
    `
};
