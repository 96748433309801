import React from "react";
import { ErrorBoundaryContent } from "entities/account";

type MyProps = {
    err?: string;
    children: React.ReactNode;
};
type MyState = {
    error: any;
    errorInfo: any;
};

class ErrorBoundary extends React.Component<MyProps, MyState> {
    constructor(props: any) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error: any, errorInfo: any): void {
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
    }

    render(): React.ReactNode {
        if (this.state.errorInfo) {
            return <ErrorBoundaryContent message={this.state.error.message} state_error={this.state.error} componentStack={this.state.errorInfo.componentStack} />;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
