import { memo } from "react";
import "./switch.scss";

interface ISwitch {
    checked: boolean;
    onChange: () => void;
    disabled?: boolean;
}

const Switch: React.FC<ISwitch> = ({ checked, onChange, disabled = false }): JSX.Element => {
    return (
        <label className="switch">
            <input className="switch_input" disabled={disabled} checked={checked} onChange={onChange} type="checkbox" />
            <span className="switch_slider" />
        </label>
    );
};

export default memo(Switch);
