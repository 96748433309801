import createTaskSlice, { clearTaskState, setCreateTaskData, setTypeOfTask, setIsTaskChosen, setCreateTaskStep, setIsPilotTaskInProject } from "./model/slice/create-task-slice";
import { ICreateTaskUploadBatchProps, ICreateTaskBudgetingProps, ICreateTaskProps, ICreateTaskState, TaskDataKey, TaskType } from "./model/types/interface";
import { TASK_STEPS } from "./model/config/config";
import {
    create_task_files_selector,
    create_task_budgeting_type_selector,
    create_task_amount_selector,
    create_task_name_selector,
    create_task_average_objects_selector,
    create_task_type_of_task_selector,
    create_task_is_task_chosen_selector,
    create_task_step_selector,
    create_task_is_pilot_task_in_project_selector
} from "./model/selectors/create-task-selectors";

export {
    createTaskSlice,
    TASK_STEPS,
    clearTaskState,
    setCreateTaskData,
    create_task_files_selector,
    create_task_budgeting_type_selector,
    create_task_amount_selector,
    create_task_name_selector,
    create_task_average_objects_selector,
    create_task_type_of_task_selector,
    TaskType,
    setTypeOfTask,
    setIsTaskChosen,
    create_task_is_task_chosen_selector,
    create_task_step_selector,
    setCreateTaskStep,
    setIsPilotTaskInProject,
    create_task_is_pilot_task_in_project_selector
};

export type { ICreateTaskUploadBatchProps, ICreateTaskBudgetingProps, ICreateTaskProps, ICreateTaskState, TaskDataKey };
