import { balance_selector } from "entities/account";
import { currencyFormat } from "shared/lib/helpers";
import { useAppSelector } from "shared/lib/hooks";
import "./balance.scss";

const BalanceInfo = (): JSX.Element => {
    const { available_balance, involved_budgets } = useAppSelector(balance_selector);

    return (
        <>
            <div className="balance_widget_top">
                <p>Available balance</p>
                <b data-testid="balance_available">{currencyFormat(available_balance)}</b>
            </div>
            <div className="balance_widget_sub">
                <p>Involved balance</p>
                <b>{currencyFormat(involved_budgets || 0)}</b>
            </div>
            {/* <div className="balance_widget_sub">
                <p>Tasks cost in progress</p>
                <b>{currencyFormat(tasks_cost_in_progress || 0)}</b>
            </div> */}
        </>
    );
};

export default BalanceInfo;
