import { ISelectOptions } from "shared/config/interface";
import { AWSAuthentication, AzureAuthentication, GoogleAuthentication, ICloudOption, IICons, StorageProviders } from "../types/types";

export const CLOUD_STORAGE_PROVIDERS: Record<StorageProviders, { title: string }> = {
    [StorageProviders.AWS]: {
        title: "AWS S3"
    },
    [StorageProviders.AZURE]: {
        title: "Azur Blob Container"
    },
    [StorageProviders.GOOGLE_CLOUD]: {
        title: "Google Cloud Storage"
    }
};

export const AWS_AUTH_OPTIONS: ISelectOptions[] = [
    { value: AWSAuthentication.KEYS, label: "Key id and secret access key", isDisabled: false },
    { value: AWSAuthentication.ANONYMOUS, label: "Anonymous", isDisabled: false }
];

export const AZURE_AUTH_OPTIONS: ISelectOptions[] = [
    { value: AzureAuthentication.SAS, label: "Account name and SAS token", isDisabled: false },
    { value: AzureAuthentication.CONNECTION, label: "Connection string", isDisabled: false },
    { value: AzureAuthentication.ANONYMOUS, label: "Anonymous", isDisabled: true }
];
export const GOOGLE_AUTH_OPTIONS: ISelectOptions[] = [
    { value: GoogleAuthentication.KEY, label: "Key file", isDisabled: false },
    { value: GoogleAuthentication.ANONYMOUS, label: "Anonymous", isDisabled: true }
];

export const CLOUD_STORAGE_PROVIDERS_OPTIONS = (icons: IICons): ICloudOption[] => {
    return [
        { value: StorageProviders.AWS, label: CLOUD_STORAGE_PROVIDERS.aws.title, isDisabled: false, icon: icons.aws },
        { value: StorageProviders.AZURE, label: CLOUD_STORAGE_PROVIDERS.azure.title, isDisabled: false, icon: icons.azure },
        { value: StorageProviders.GOOGLE_CLOUD, label: CLOUD_STORAGE_PROVIDERS.google.title, isDisabled: false, icon: icons.google }
    ];
};

export const DEFAULT_AWS_S3_REGIONS: ISelectOptions[] = [
    { value: "ap-east-1", label: "Asia Pacific (Hong Kong)" },
    { value: "ap-northeast-1", label: "Asia Pacific (Tokyo)" },
    { value: "ap-northeast-2", label: "Asia Pacific (Seoul)" },
    { value: "ap-northeast-3", label: "Asia Pacific (Osaka)" },
    { value: "ap-south-1", label: "Asia Pacific (Mumbai)" },
    { value: "ap-south-2", label: "Asia Pacific (Hyderabad)" },
    { value: "ap-southeast-1", label: "Asia Pacific (Singapore)" },
    { value: "ap-southeast-2", label: "Asia Pacific (Sydney)" },
    { value: "ap-southeast-3", label: "Asia Pacific (Jakarta)" },
    { value: "ap-southeast-4", label: "Asia Pacific (Melbourne)" },
    { value: "ap-southeast-5", label: "Asia Pacific (Bangkok)" },
    { value: "ca-central-1", label: "Canada (Central)" },
    { value: "ca-west-1", label: "Canada (West)" },
    { value: "cn-north-1", label: "China (Beijing)" },
    { value: "cn-northwest-1", label: "China (Ningxia)" },
    { value: "eu-central-1", label: "EU (Frankfurt)" },
    { value: "eu-central-2", label: "EU (Zurich)" },
    { value: "eu-north-1", label: "Europe (Stockholm)" },
    { value: "eu-south-1", label: "EU (Milan)" },
    { value: "eu-south-2", label: "EU (Spain)" },
    { value: "eu-west-1", label: "Europe (Ireland)" },
    { value: "eu-west-2", label: "Europe (London)" },
    { value: "eu-west-3", label: "Europe (Paris)" },
    { value: "il-central-1", label: "Israel (Tel Aviv)" },
    { value: "me-central-1", label: "Middle East (UAE)" },
    { value: "me-south-1", label: "Middle East (Bahrain)" },
    { value: "sa-east-1", label: "South America (São Paulo)" },
    { value: "us-east-1", label: "US East (N. Virginia)" },
    { value: "us-east-2", label: "US East (Ohio)" },
    { value: "us-gov-east-1", label: "AWS GovCloud (US-East)" },
    { value: "us-gov-west-1", label: "AWS GovCloud (US-West)" },
    { value: "us-west-1", label: "US West (N. California)" },
    { value: "us-west-2", label: "US West (Oregon)" }
];

export const DEFAULT_GOOGLE_CLOUD_STORAGE_LOCATIONS: ISelectOptions[] = [
    { value: "NORTHAMERICA-NORTHEAST1", label: "Montréal" },
    { value: "NORTHAMERICA-NORTHEAST2", label: "Toronto" },
    { value: "US-CENTRAL1", label: "Iowa" },
    { value: "US-EAST1", label: "South Carolina" },
    { value: "US-EAST4", label: "Northern Virginia" },
    { value: "US-WEST1", label: "Oregon" },
    { value: "US-WEST2", label: "Los Angeles" },
    { value: "US-WEST3", label: "Salt Lake City" },
    { value: "US-WEST4", label: "Las Vegas" },
    { value: "SOUTHAMERICA-EAST1", label: "São Paulo" },
    { value: "EUROPE-CENTRAL2", label: "Warsaw" },
    { value: "EUROPE-NORTH1", label: "Finland" },
    { value: "EUROPE-WEST1", label: "Belgium" },
    { value: "EUROPE-WEST2", label: "London" },
    { value: "EUROPE-WEST3", label: "Frankfurt" },
    { value: "EUROPE-WEST4", label: "Netherlands" },
    { value: "EUROPE-WEST6", label: "Zürich" },
    { value: "ASIA-EAST1", label: "Taiwan" },
    { value: "ASIA-EAST2", label: "Hong Kong" },
    { value: "ASIA-NORTHEAST1", label: "Tokyo" },
    { value: "ASIA-NORTHEAST2", label: "Osaka" },
    { value: "ASIA-NORTHEAST3", label: "Seoul" },
    { value: "ASIA-SOUTH1", label: "Mumbai" },
    { value: "ASIA-SOUTH2", label: "Delhi" },
    { value: "ASIA-SOUTHEAST1", label: "Singapore" },
    { value: "ASIA-SOUTHEAST2", label: "Jakarta" },
    { value: "AUSTRALIA-SOUTHEAST1", label: "Sydney" },
    { value: "AUSTRALIA-SOUTHEAST2", label: "Melbourne" },
    { value: "ASIA", label: "Data centers in Asia" },
    { value: "EU", label: "Data centers within member states of the European Union" },
    { value: "US", label: "Data centers in the United States" },
    { value: "ASIA1", label: "ASIA-NORTHEAST1 and ASIA-NORTHEAST2" },
    { value: "EUR4", label: "EUROPE-NORTH1 and EUROPE-WEST4" },
    { value: "NAM4", label: "US-CENTRAL1 and US-EAST1" }
];
