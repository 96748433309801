import { ICountry } from "entities/account/model/types/types";
import { GlobalStatuses, IBill, ISelectOptions } from "shared/config/interface";

export interface ICreateBilling {
    amount: number;
    organization_id: number;
    address?: IBillingPersonalDataPayload | ICreateBillingAddress;
}

export interface ICreateBillingAddress {
    id: number;
    default?: boolean;
}

export type BillingStatusTypes = GlobalStatuses.WAITING | GlobalStatuses.PENDING | GlobalStatuses.PAID | GlobalStatuses.FAILED | GlobalStatuses.CANCELLED | GlobalStatuses.COST;

export interface ICreateBillingResponse {
    id: number;
    amount: string;
    status: BillingStatusTypes;
    organization_id: number;
    payment_intent: string;
    client_secret: string;
}

export interface IBilling {
    id: number;
    amount: string;
    status: BillingStatusTypes;
    created_date: string;
    task: {
        id: number;
        project_name: number;
        name: string;
        bill: IBill[];
    } | null;
}

export interface IBillingState {
    loading: boolean;
    isShowPaymentForm: boolean;
    client_secret_key: string | null;
    sumValue: string | number;
    addressActiveId: number;
    billingAddressWasChange: boolean;
}

export const TH_BILLINGS: string[] = ["Transaction", "Amount, $", "Status", "Created"];

export interface IBillingReport {
    organization_id: number;
    start_date: string;
    end_date: string;
}

export interface IBillingPersonalData {
    full_name: string;
    company: string | undefined | null;
    city: string;
    street: string;
    postal_code: string;
    default: boolean;
}

export interface IBillingPersonalDataForm extends IBillingPersonalData {
    country: ISelectOptions;
}

export interface IBillingPersonalDataResponse extends IBillingPersonalData {
    country: ICountry;
    id: number;
}

export interface IBillingPersonalDataPayload extends IBillingPersonalData {
    country: number;
}
