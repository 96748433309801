import { useEffect, useState } from "react";
import "./button-cards.scss";

interface IProps {
    items: { label: string; value: string }[];
    onChange: (value: string) => void;
    defaultValue: string;
    disabled?: boolean;
}

const ButtonCards = ({ items, onChange, defaultValue, disabled = false }: IProps): JSX.Element => {
    const [active, setActive] = useState("");

    useEffect(() => {
        setActive(defaultValue);
    }, [defaultValue]);

    const onClickItem = (value: string): void => {
        if (disabled) return;

        setActive(value);
        onChange(value);
    };

    let cursor = disabled ? "default" : "pointer";

    return (
        <div className="button-cards">
            {items.map((item) => (
                <span style={{ cursor }} key={item.value} onClick={() => onClickItem(item.value)} className={active === item.value ? "active" : ""}>
                    {item.label}
                </span>
            ))}
        </div>
    );
};

export default ButtonCards;
