import { IS_PRODUCTION } from "shared/config/config";

export function local_logger(message: string, ...args: any): void {
    if (IS_PRODUCTION) return;
    let style = `
        background: #10eee8;
        padding: 3px;
        font-weight: 700;
        font-size: 11px;
        color: black;
    `;
    console.log(`%c@  ${message} ----  @`, style);
    console.log(...args);
}
