import { RootState } from "app/store-config";
import { BudgetingType } from "shared/config/interface";
import { TaskType } from "../types/interface";

export const current_step_selector = (state: RootState): number => state.createTask.step;
export const create_task_files_selector = (state: RootState): File[] => state.createTask.files;
export const create_task_budgeting_type_selector = (state: RootState): BudgetingType => state.createTask.budgeting_type;
export const create_task_amount_selector = (state: RootState): null | number => state.createTask.amount;
export const create_task_name_selector = (state: RootState): string => state.createTask.name;
export const create_task_average_objects_selector = (state: RootState): string | number => state.createTask.average_objects;
export const create_task_type_of_task_selector = (state: RootState): TaskType => state.createTask.type_of_task;
export const create_task_is_task_chosen_selector = (state: RootState): boolean => state.createTask.is_task_chosen;
export const create_task_step_selector = (state: RootState): number => state.createTask.step;
export const create_task_is_pilot_task_in_project_selector = (state: RootState): boolean => state.createTask.is_pilot_task_in_project;
