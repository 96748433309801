import { FocusEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Divider, FacebookButtonWrapper, FormItem, Input, Loader } from "shared/ui";
import { rules, show_error } from "shared/lib/helpers";
import { useFacebookAuth, useGoogleAuth, useNotification } from "shared/lib/hooks";
import { GOOGLE_ICON } from "shared/lib/helpers/images/images";
import { MIXPANEL_TRACK_KEYS, ROUTES } from "shared/config/interface";
import { PasswordsForm, UserNameVariants } from "widgets/authorization";
import { ErrorAuth, ICheckUserName, ISignup, useLazyCheckUserNameQuery, useLogin, useRegistrationMutation } from "entities/authorization";
import { Mixpanel } from "shared/lib/services/mixpanel";

const SignUpForm = (): JSX.Element => {
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        clearErrors,
        formState: { errors },
        watch
    } = useForm<ISignup>({
        mode: "onBlur"
    });

    const [variants, setVariants] = useState<ICheckUserName | undefined>(undefined);
    const notification = useNotification();
    const navigate = useNavigate();

    const [registration, { isLoading }] = useRegistrationMutation();
    const [check_user_name, { isLoading: isLoadingCheckUserName, data: userNameVariants }] = useLazyCheckUserNameQuery();
    const { isLoading: isLoadingGoogle, isSuccess, data, google_login, error: googleError } = useGoogleAuth();
    const { isLoading: isLoadingFacebook, isSuccess: isSuccessFacebook } = useFacebookAuth();
    const { login } = useLogin();

    useEffect(() => {
        if (isSuccessFacebook) {
            Mixpanel.track(MIXPANEL_TRACK_KEYS.SIGN_UP_FACEBOOK, {});
        }
    }, [isSuccessFacebook]);

    useEffect(() => {
        if (googleError) {
            notification.error({
                message: `Google login error: ${googleError.data.detail}`
            });
        }
    }, [googleError, notification]);

    useEffect(() => {
        if (isSuccess && data) {
            Mixpanel.track(MIXPANEL_TRACK_KEYS.SIGN_UP_GOOGLE, {});
            login(data);
        }
    }, [isSuccess, navigate, data]);

    useEffect(() => {
        if (userNameVariants && userNameVariants.exists === true) {
            setVariants(userNameVariants);
            setError("username", { type: "custom", message: "This username already exists" });
            return;
        }

        if (userNameVariants?.exists === false) {
            setVariants(undefined);
        }
    }, [userNameVariants, setError]);

    const onSubmitForm = async (data: ISignup): Promise<void> => {
        let { confirm_password, ...rest } = data;

        try {
            const result: any = await registration(rest);

            if (result?.data) {
                Mixpanel.track(MIXPANEL_TRACK_KEYS.SIGN_UP, {
                    email: data.email,
                    name: data.username,
                    $email: data.email,
                    $name: data.username
                });
                localStorage.setItem("email", data.email);
                navigate(ROUTES.CONFIRM_EMAIL);
                return;
            }
            if (result.error) {
                show_error(true, result.error as ErrorAuth, notification);
            }
        } catch (error) {
            notification.error({
                message: error as string,
                duration: 6
            });
        }
    };

    const checkUserNameRules = (e: FocusEvent<HTMLInputElement>): void => {
        if (e.target.value.length < 3) {
            setError("username", { type: "minLength", message: "minLength 3" });
            return;
        }
        clearErrors("username");
        check_user_name(e.target.value);
    };

    const setVariantUserName = (username: string): void => {
        setValue("username", username);
        clearErrors("username");
        setVariants(undefined);
    };

    if (isLoading || isLoadingGoogle || isLoadingFacebook) {
        <Loader />;
    }

    return (
        <>
            <form id="signup-form" data-testid="signup-form" onSubmit={handleSubmit(onSubmitForm)}>
                <FormItem label="Email" error={errors?.email?.message}>
                    <Input {...register("email", rules.email)} type="email" placeholder="Your email" data-testid="sign-up-email" className={errors?.email?.message ? "error" : ""} />
                </FormItem>
                <FormItem label="User Name" error={errors?.username?.message}>
                    <Input
                        {...register("username", rules.username)}
                        type="text"
                        placeholder="User Name"
                        data-testid="sign-up-username"
                        className={errors?.username?.message ? "error" : ""}
                        onBlur={checkUserNameRules}
                    />
                    <UserNameVariants isLoadingCheckUserName={isLoadingCheckUserName} variants={variants} setVariantUserName={setVariantUserName} />
                </FormItem>
                <PasswordsForm register={register as any} errors={errors} watch={watch as any} />
            </form>
            <Button loading={isLoading} disabled={isLoading} form="signup-form" className="authorization_main_btn" typeStyle="danger" type="submit">
                Sign up
            </Button>
            <Divider text="OR" />
            <div className="authorization_btn_wrapper">
                <Button
                    loading={isLoading || isLoadingGoogle}
                    disabled={isLoading || isLoadingGoogle}
                    className="authorization_google"
                    icon={<img src={GOOGLE_ICON} alt="Google" />}
                    typeStyle="ghost"
                    onClick={() => google_login()}
                    block
                >
                    Sign up with Google
                </Button>
                {/* <FacebookButtonWrapper loading={isLoading || isLoadingGoogle} registration onSuccess={onSuccess} onFail={onFail} onProfileSuccess={onProfileSuccess} /> */}
            </div>
        </>
    );
};

export default SignUpForm;
