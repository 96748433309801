import { memo } from "react";
import { truncate_string } from "shared/lib/helpers";

interface IPropsShortItem {
    name: string;
    owner?: boolean;
}

const ShortOrganizationName = ({ name, owner = false }: IPropsShortItem): JSX.Element => {
    return (
        <div className="org_select_item">
            <div className="org_select_item_short" style={{ background: owner ? "#2AABFF" : "#FF8B00" }}>
                {name[0].toUpperCase()}
            </div>
            <span className="org_select_item_name">{truncate_string(name, owner ? 13 : 23)}</span>
            {owner && (
                <>
                    <div className="org_select_item_dot" />
                    <small className="org_select_item_status">Owner</small>
                </>
            )}
        </div>
    );
};

export default memo(ShortOrganizationName);
