import { GlobalStatuses } from "shared/config/interface";

interface ITaskProps {
    cost: number;
    budget: number;
    paused: boolean;
    status: GlobalStatuses;
    verify: boolean;
}

export const define_task_status = ({ cost, budget, paused, status, verify }: ITaskProps): GlobalStatuses => {
    if (status === GlobalStatuses.COMPLETED) return status;

    if (cost > budget) return GlobalStatuses.NEED_BUDGET;
    if (paused) return GlobalStatuses.PAUSED;

    if (status === GlobalStatuses.VALIDATION && verify === false) return GlobalStatuses.ANNOTATION;
    if (status === GlobalStatuses.VALIDATION && verify === true) return GlobalStatuses.VALIDATION;

    return status;
};
