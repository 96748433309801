import { INSTRUCTION_FILE_FORMATS } from "shared/config/config";
import { replace_spaces } from "shared/lib/helpers";

const PDF_TYPE = "application/pdf";
const TEXT_TYPE = "text/plain";
const DOCX_TYPE = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

type Response = {
    folder: string;
    aws_file_uploaded_link: string;
};

export const upload_instruction = async (id: number, project_name: string, instruction_file: Blob, username: string, callback: (folder: string) => Promise<string>): Promise<undefined | Response> => {
    let new_project_name = replace_spaces(project_name);

    let fileExtension: string;
    if (instruction_file.type === PDF_TYPE) {
        fileExtension = INSTRUCTION_FILE_FORMATS.PDF;
    } else if (instruction_file.type === TEXT_TYPE) {
        fileExtension = INSTRUCTION_FILE_FORMATS.TXT;
    } else if (instruction_file.type === DOCX_TYPE) {
        fileExtension = INSTRUCTION_FILE_FORMATS.DOCX;
    } else {
        throw new Error("Unsupported file type");
    }

    let fileNameSuffix = `_instruction.${fileExtension}`;

    let folder: string = `${username}-${id}/${new_project_name}/instruction/${new_project_name}${fileNameSuffix}`;

    let result: { data: string } | any = await callback(folder);

    if (result.data) {
        const aws_link = result.data;
        let upload_file_result = await upload_file(aws_link, instruction_file);

        if (upload_file_result) {
            return {
                folder,
                aws_file_uploaded_link: upload_file_result
            };
        }
        return undefined;
    }
};

async function upload_file(aws_link: string, instruction_file: Blob): Promise<string | undefined> {
    try {
        const response = await fetch(aws_link, {
            method: "PUT",
            headers: {
                "Content-Type": instruction_file.type
            },
            body: instruction_file
        });
        if (!response.ok) {
            throw new Error(`Upload failed with status: ${response.status}`);
        }
        let parse_link = aws_link.split("?")[0];
        return parse_link;
    } catch (error) {
        return undefined;
    }
}
