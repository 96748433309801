import $api from "shared/api/api";
import { API_ENDPOINTS } from "shared/config/config";
import { ITokens } from "shared/config/interface";

const signInApiSlice = $api.injectEndpoints({
    endpoints: (builder) => ({
        googleLogin: builder.mutation<ITokens, { access_token: string }>({
            query: (auth_data) => ({
                url: `${API_ENDPOINTS.authentication}oauth-login/google-oauth2/`,
                method: "POST",
                body: auth_data
            })
        })
    })
});

export const { useGoogleLoginMutation } = signInApiSlice;
