export const form_data_initialize = (data: Record<string, string>, upload_data: any): FormData => {
    const formData: FormData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
        formData.set(key, value);
    });

    Array.from(upload_data).forEach((file: any, index: any) => {
        formData.append(`client_files[${index}]`, file);
    });

    return formData;
};
