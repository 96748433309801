import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useActivationMutation } from "../../api/authorization-api";
import { ApiErrorResponse } from "../../types/types";

interface IResponse {
    isLoadingActivation: boolean;
    isErrorActivation: boolean;
    errorActivation: any | ApiErrorResponse;
    isSuccessActivation: boolean;
}

export const useCheckActivationToken = (): IResponse => {
    const params = useParams();
    // const [getIpAddress, { data: dataIPAddress, isLoading: isLoadingIp }] = useLazyGetIPaddressQuery();

    const [checkActivation, { data, isLoading: isLoadingActivation, error: errorActivation, isError: isErrorActivation, isSuccess: isSuccessActivation }] = useActivationMutation();
    const renderRef = useRef<boolean>(false);

    // useLayoutEffect(() => {
    //     if (IS_REACT_APP_DEV) return;
    //     HubSpot.loadHubSpotScript();
    //     getIpAddress(null);
    // }, []);

    // useEffect(() => {
    //     if (dataIPAddress?.ip && data) {
    //         HubSpot.activation_account(data.email, data.username, dataIPAddress?.ip);
    //     }
    // }, [data, dataIPAddress]);

    useEffect(() => {
        if (renderRef.current === true) return;

        if (params.uid && params.token) {
            renderRef.current = true;
            checkActivation({
                token: params.token,
                uid: params.uid
            });
        }
    }, [params, checkActivation]);

    return {
        isLoadingActivation: isLoadingActivation,
        isErrorActivation,
        errorActivation,
        isSuccessActivation
    };
};
