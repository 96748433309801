import $api from "shared/api/api";
import { API_ENDPOINTS } from "shared/config/config";

const onboardingAPI = $api.injectEndpoints({
    endpoints: (builder) => ({
        onboardingKeyPass: builder.query<string[], string>({
            query: (step_id) => ({
                url: `${API_ENDPOINTS.authentication}user/onboarding/${step_id}/pass/`,
                method: "GET"
            })
        })
    })
});

export const { useLazyOnboardingKeyPassQuery } = onboardingAPI;
