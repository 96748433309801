import LayoutAuth from "./layout/layout-auth";
import PasswordsForm from "./passwords-form/passwords-form";
import SignInLinks from "./sign-in-links/sign-in-links";
import SignUpLinks from "./sign-up-links/sign-up-links";
import Title from "./title/title";
import UserNameVariants from "./username-variants/username-variants";
import SignUpForm from "./sign-up-form/sign-up-form";
import SuccessActivation from "./success-activation/success-activation";
import SignInForm from "./sign-in-form/sign-in-form";
import ResetPasswordSuccess from "./reset-password/reset-password-success";

export { LayoutAuth, Title as AuthorizationTitle, UserNameVariants, SignUpLinks, PasswordsForm, SignInLinks, SignUpForm, SuccessActivation, SignInForm, ResetPasswordSuccess };
