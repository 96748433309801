import React, { memo } from "react";
import { DAYS_OF_WEEK } from "./helpers";

const DatePickerWeek = (): JSX.Element => {
    return (
        <>
            {DAYS_OF_WEEK.map((el: string) => (
                <span className="dp_calendar_main_title" key={el}>
                    {el}
                </span>
            ))}
        </>
    );
};

export default memo(DatePickerWeek);
