import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_TAGS } from "shared/config/config";
import { TOKENS } from "shared/config/interface";

const external_api = createApi({
    reducerPath: "core_api",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_HOSTNAME_CVAT}api/v1`,
        prepareHeaders: (headers) => {
            const cvat_token = localStorage.getItem(TOKENS.TOKEN_CVAT);

            if (cvat_token) {
                headers.set("Authorization", `Token ${cvat_token}`);
            }

            return headers;
        }
    }),
    tagTypes: [API_TAGS.Project_CVAT, API_TAGS.Task_CVAT],
    refetchOnReconnect: true,
    refetchOnFocus: true,
    endpoints: (builder) => ({})
});

export default external_api;
