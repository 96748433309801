import $api from "shared/api/api";
import external_api from "shared/api/external_api";
import { API_ENDPOINTS, EXTERNAL_API_PLATFORM_TYPE } from "shared/config/config";
import { IResponse, ITask } from "shared/config/interface";
import { ICreateTaskPayload, ICreateTaskResponse, IUpdateTaskPayload, IUploadData, IUploadDateResponse } from "../types/types";

export const taskApiExternal = external_api.injectEndpoints({
    endpoints: (builder) => ({
        updateTask: builder.mutation<ITask, IUpdateTaskPayload>({
            query: ({ task_data, task_id }) => ({
                url: `/tasks/${task_id}?${EXTERNAL_API_PLATFORM_TYPE}`,
                method: "PATCH",
                body: task_data
            }),
            invalidatesTags: (result, error, arg) => [{ type: "Task", id: arg.task_id }]
        }),
        getProjectTasksActive: builder.query<IResponse<ITask>, number>({
            query: (id) => ({
                url: `/projects/${id}/tasks?page_size=50&${EXTERNAL_API_PLATFORM_TYPE}`,
                method: "GET"
            }),
            providesTags: (result, error, args) => (result?.results ? result.results.map((task: ITask) => ({ type: "Task", id: task.id })) : [{ type: "Task", id: "LIST" }])
        }),
        createTask: builder.mutation<ICreateTaskResponse, ICreateTaskPayload>({
            query: (data) => ({
                url: `/tasks?${EXTERNAL_API_PLATFORM_TYPE}`,
                method: "POST",
                body: data
            })
        }),
        uploadTasksData: builder.mutation<IUploadDateResponse, IUploadData>({
            query: ({ files, id }) => ({
                url: `/tasks/${id}/data`,
                method: "POST",
                body: files,
                formData: true
            }),
            invalidatesTags: (result, error, arg) => [{ type: "Task", id: arg.id }]
        }),
        finishPartialTask: builder.mutation<IResponse<ITask>, number>({
            query: (id) => ({
                url: `tasks/${id}/complete_partial?${EXTERNAL_API_PLATFORM_TYPE}`,
                method: "GET"
            }),
            invalidatesTags: (result: any, error: any, arg: any): any => [{ type: "Task", id: arg.task_id }]
        })
    })
});

export const taskApi = $api.injectEndpoints({
    endpoints: (builder) => ({
        getProjectTasksHistory: builder.query<IResponse<ITask>, number>({
            query: (id) => ({
                url: `${API_ENDPOINTS.account}projects-history/${id}/tasks/`,
                method: "GET"
            })
        })
    })
});

export const { useUpdateTaskMutation, useLazyGetProjectTasksActiveQuery, useCreateTaskMutation, useUploadTasksDataMutation, useFinishPartialTaskMutation } = taskApiExternal;
export const { useLazyGetProjectTasksHistoryQuery } = taskApi;
