import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "app/App";
import TechnikalMeintenance from "pages/technical-maintenance/technikal-meintenance";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(<React.StrictMode>{process.env.REACT_APP_BUILD_TECH_PAGE === "True" ? <TechnikalMeintenance /> : <App />}</React.StrictMode>);

reportWebVitals();
