import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICreateTaskState, TaskType } from "../types/interface";
import { BUDGET_TYPES, CreateTaskTypes } from "shared/config/interface";

const INITIAL_STATE: ICreateTaskState = {
    step: 1,
    files: [],
    budgeting_type: BUDGET_TYPES.DEFINE_SUM,
    amount: 0,
    name: "",
    average_objects: 0,
    uploadTask: false,
    isSuccesCreateTask: false,
    type_of_task: TaskType.PILOT,
    is_task_chosen: false,
    is_pilot_task_in_project: false,
    mode: CreateTaskTypes.COMPUTER,
    selected_cloud_storage: null,
    cloud_storage_new_prefix: "",
    cloud_storage_count_files: null
};

const createTaskSlice = createSlice({
    name: "create-task",
    initialState: INITIAL_STATE,
    reducers: {
        setCreateTaskStep(state, action: PayloadAction<number>) {
            state.step = action.payload;
        },
        setCreateTaskData(state, action) {
            let type = Object.keys(action.payload)[0];
            switch (type) {
                case "amount":
                    state.amount = action.payload.amount;
                    break;
                case "files":
                    state.files = action.payload.files;
                    break;
                case "budgeting_type":
                    state.budgeting_type = action.payload.budgeting_type;
                    break;
                case "name":
                    state.name = action.payload.name;
                    break;
                case "average_objects":
                    state.average_objects = action.payload.average_objects;
                    break;
                default:
                    break;
            }
        },
        clearTaskState(state) {
            state.step = 1;
            state.files = [];
            state.budgeting_type = BUDGET_TYPES.DEFINE_SUM;
            state.name = "";
            state.average_objects = 0;
            state.is_task_chosen = false;
            state.selected_cloud_storage = null;
            state.cloud_storage_new_prefix = "";
        },
        setUploadTask(state, action: PayloadAction<boolean>) {
            state.uploadTask = action.payload;
        },
        setIsSuccesCreateTask(state, action: PayloadAction<boolean>) {
            state.isSuccesCreateTask = action.payload;
        },
        setTypeOfTask(state, action: PayloadAction<TaskType>) {
            state.type_of_task = action.payload;
        },
        setIsTaskChosen(state, action: PayloadAction<boolean>) {
            state.is_task_chosen = action.payload;
        },
        setIsPilotTaskInProject(state, action: PayloadAction<boolean>) {
            state.is_pilot_task_in_project = action.payload;
            state.type_of_task = action.payload ? TaskType.STANDART : TaskType.PILOT;
        },
        setTaskMode(state, action: PayloadAction<CreateTaskTypes>) {
            state.mode = action.payload;
        },
        seetSelectedCloudStorage(state, action: PayloadAction<number | null>) {
            state.selected_cloud_storage = action.payload;
        },
        setNewCloudStoragePrefix(state, action: PayloadAction<string>) {
            state.cloud_storage_new_prefix = action.payload;
        },
        setCloudStorageCountFiles(state, action: PayloadAction<number | null>) {
            state.cloud_storage_count_files = action.payload;
        }
    }
});

export const {
    setCreateTaskStep,
    setCreateTaskData,
    clearTaskState,
    setTypeOfTask,
    setIsTaskChosen,
    setIsPilotTaskInProject,
    setTaskMode,
    seetSelectedCloudStorage,
    setNewCloudStoragePrefix,
    setCloudStorageCountFiles
} = createTaskSlice.actions;
export default createTaskSlice.reducer;
