import { GoogleOAuthProvider } from "@react-oauth/google";
import { FC, useEffect } from "react";
import { GOOGLE_CLIENT_ID } from "shared/config/config";
import gtmService from "shared/lib/services/gtm";

type IProps = {
    children: React.ReactNode;
};

const GoogleAuthWrapper: FC<IProps> = ({ children }): JSX.Element => {
    const google_clientId = GOOGLE_CLIENT_ID || "test";

    useEffect(() => {
        gtmService.initialize();
    }, []);

    if (!google_clientId) {
        alert("Missing Google client id");
    }

    return (
        <GoogleOAuthProvider clientId={google_clientId as string} onScriptLoadError={() => alert("Error Google auth provider")}>
            {children}
        </GoogleOAuthProvider>
    );
};

export default GoogleAuthWrapper;
