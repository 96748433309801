import $api from "shared/api/api";
import { API_ENDPOINTS, API_TAGS, PAGE_SIZE } from "shared/config/config";
import { IBilling, IBillingPersonalDataResponse, IBillingReport, ICreateBilling, ICreateBillingResponse } from "../types/interface";
import { IResponse } from "shared/config/interface";

export const dashboardApi = $api.injectEndpoints({
    endpoints: (builder) => ({
        billing: builder.query<IResponse<IBilling>, { page?: number; page_size?: number }>({
            query: ({ page = 1, page_size = PAGE_SIZE }) => ({
                url: `${API_ENDPOINTS.account}billing`,
                method: "GET",
                params: { page, page_size }
            })
        }),
        createBilling: builder.mutation<ICreateBillingResponse, ICreateBilling>({
            query: (data) => ({
                url: `${API_ENDPOINTS.account}billing/`,
                method: "POST",
                body: data
            }),
            invalidatesTags: [API_TAGS.BillingPersonalData]
        }),
        billingReport: builder.query<any, IBillingReport>({
            query: ({ organization_id, start_date, end_date }) => ({
                url: `${API_ENDPOINTS.account}billing/statement-activity/?organization_id=${organization_id}&start_date=${start_date}&end_date=${end_date}`,
                method: "GET"
            })
        }),
        billingPersonalDatas: builder.query<IBillingPersonalDataResponse[], null>({
            query: () => ({
                url: `${API_ENDPOINTS.account}billing/address/`,
                method: "GET"
            }),
            providesTags: [API_TAGS.BillingPersonalData]
        })
    })
});

export const { useLazyBillingQuery, useCreateBillingMutation, useLazyBillingReportQuery, useBillingPersonalDatasQuery } = dashboardApi;
