import { useNavigate } from "react-router-dom";
import { ROUTES } from "shared/config/interface";
import { LOCK_ICON } from "shared/lib/helpers/images/images";
import { Button, Information } from "shared/ui";

const ResetPasswordSuccess = (): JSX.Element => {
    const navigate = useNavigate();

    return (
        <div className="authorization reset_password">
            <Information image={<LOCK_ICON />} title="Your password has been successfuly changed" description="You can back to Log in now." />
            <Button className="authorization_main_btn" size="large" typeStyle="danger" onClick={() => navigate(ROUTES.LOGIN)}>
                Back to sign in
            </Button>
        </div>
    );
};

export default ResetPasswordSuccess;
