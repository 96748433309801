import { short_username } from "shared/lib/helpers";
import "./user-name-information.scss";

const UserNameInformation = ({ user_name, email }: { user_name: string; email: string }): JSX.Element => {
    return (
        <div className="user_name_information">
            <span className="user_name_information_short">{short_username(user_name)}</span>
            <span className="user_name_information_full">
                {user_name}
                <br />
                <small className="user_name_information_full_email">{email}</small>
            </span>
        </div>
    );
};

export default UserNameInformation;
