export interface IProps {
    on_click_input: () => void;
    isOpen: boolean;
    selectedDate: Date | null;
}

const DatePickerInput = ({ on_click_input, isOpen, selectedDate }: IProps): JSX.Element => {
    return (
        <div onClick={on_click_input} className="dp_input" style={{ pointerEvents: isOpen ? "none" : "auto" }}>
            {selectedDate ? <span className="dp_input_value">{selectedDate.toLocaleDateString()}</span> : <span className="dp_input_value placeholder">Select date</span>}
        </div>
    );
};

export default DatePickerInput;
