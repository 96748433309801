import { FC } from "react";
import { Provider } from "react-redux";
import { setupStore } from "./store-config";

type IProps = {
    children: React.ReactNode;
};

const StoreProvider: FC<IProps> = ({ children }): JSX.Element => {
    const store = setupStore();

    return <Provider store={store}>{children}</Provider>;
};

export default StoreProvider;
