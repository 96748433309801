import React, { useRef, useState } from "react";
import { Button } from "shared/ui";
import { useClickOutside } from "shared/lib/hooks";
import { HELP_ICON } from "shared/lib/helpers/images/images";

import "./confirm.scss";

interface IConfirmProps {
    children: React.ReactNode;
    title: string;
    description?: string;
    onConfirm: () => void;
    onCancel?: () => void;
    okText?: string;
    cancelText?: string;
}

const Confirm = (props: IConfirmProps): JSX.Element => {
    const { children, onConfirm, title, description, cancelText = "No", okText = "Yes", onCancel } = props;

    const [visible, setVisible] = useState<boolean>(false);
    const confirmRef = useRef<HTMLDivElement>(null);
    useClickOutside(confirmRef, () => setVisible(false));

    function onClickChildrenWrapper(): void {
        setVisible(true);
    }

    function onOk(): void {
        onConfirm();
        setVisible(false);
    }

    function onCancelClick(): void {
        onCancel && onCancel();
        setVisible(false);
    }

    return (
        <div className="ui_confirm">
            {visible && (
                <div ref={confirmRef} className="confirm_content">
                    <div className="confirm_content_header">
                        <HELP_ICON />
                        {/* fill="#2aabff" */}
                        <h6 className="confirm_content_header_title">{title}</h6>
                    </div>
                    {description && <p className="confirm_content_description">{description}</p>}
                    <div className="confirm_content_actions">
                        <Button onClick={onCancelClick} size="small" typeStyle="default">
                            {cancelText}
                        </Button>
                        <Button onClick={onOk} size="small">
                            {okText}
                        </Button>
                    </div>
                </div>
            )}
            <div className="ui_confirm_children_wrap" onClick={onClickChildrenWrapper}>
                {children}
            </div>
        </div>
    );
};

export default Confirm;
