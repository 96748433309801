import { IS_REACT_APP_DEV } from "shared/config/config";

const HUB_SPOT_URL = "https://api.hsforms.com/submissions/v3/integration/submit/";
const HEADERS = { "Content-Type": "application/json" };

class HubSpot {
    private static PORTAL_ID = process.env.REACT_APP_HUBSPOT_PORTAL_ID;
    private static FORM_GUID = process.env.REACT_APP_HUBSPOT_FORM_GUID;

    static async sign_up(username: string, email: string, ip: string): Promise<void> {
        if (IS_REACT_APP_DEV) return;

        try {
            const response = await fetch(`${HUB_SPOT_URL}${this.PORTAL_ID}/${this.FORM_GUID}`, {
                method: "POST",
                headers: HEADERS,
                body: JSON.stringify({
                    fields: [
                        { name: "username", value: username },
                        { name: "email", value: email },
                        { name: "user_sign_up_form_submitted", value: "Yes" }
                    ],
                    context: {
                        hutk: this.getHubspotCookie(),
                        pageUri: window.location.href,
                        pageName: document.title,
                        ipAddress: ip
                    }
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error(errorData);
            }
        } catch (error) {
            console.error(error);
        }
    }

    static async activation_account(email: string, username: string, ip: string): Promise<void> {
        if (IS_REACT_APP_DEV) return;

        // this.loadHubSpotScript();

        try {
            await fetch(`${HUB_SPOT_URL}${this.PORTAL_ID}/${this.FORM_GUID}`, {
                method: "POST",
                headers: HEADERS,
                body: JSON.stringify({
                    fields: [
                        { name: "username", value: username },
                        { name: "email", value: email },
                        { name: "verified_user", value: "Yes" }
                    ],
                    context: {
                        hutk: this.getHubspotCookie(),
                        pageUri: window.location.href,
                        pageName: document.title,
                        ipAddress: ip
                    }
                })
            });
        } catch (error) {
            console.error("Hubspot error", error);
        }
    }

    private static getHubspotCookie(): string | null {
        const match = document.cookie.match(/hubspotutk=([^;]+)/);
        return match ? match[1] : null;
    }

    static loadHubSpotScript(): void {
        if (IS_REACT_APP_DEV) return;

        if (!this.PORTAL_ID || !this.FORM_GUID) {
            return console.log("PORTAL_ID or FORM_GUID is missing");
        }

        if (document.getElementById("hs-script-loader")) {
            console.log("The HubSpot tracking script has already been downloaded.");
            return;
        }

        const script = document.createElement("script");
        script.type = "text/javascript";
        script.id = "hs-script-loader";
        script.async = true;
        script.defer = true;
        script.src = `//js-eu1.hs-scripts.com/${this.PORTAL_ID}.js`;

        script.onload = () => console.log("HubSpot works.");
        script.onerror = () => console.error("Unable to load HubSpot tracking script.");

        document.head.appendChild(script);
    }

    static unloadHubSpotScript(): void {
        if (IS_REACT_APP_DEV) return;

        const script = document.getElementById("hs-script-loader");
        if (script) {
            script.remove();
            console.log("HubSpot tracking script removed");
        } else {
            console.warn("HubSpot tracking script not found for removal.");
        }
    }
}

export default HubSpot;
