import { useCallback, useEffect, useState } from "react";
import { TOKENS } from "shared/config/interface";
import { useLazyGetOneTimeTokenQuery } from "./api/api";

export const useOneTimeToken = (link: string): { isLoading: boolean; modifiedLink: string } => {
    const [modifiedLink, setModifiedLink] = useState<string>(link);
    const [getOneTimeToken, { isLoading }] = useLazyGetOneTimeTokenQuery();

    const clear = useCallback((): void => {
        localStorage.removeItem(TOKENS.ONE_TIME_TOKEN);
        localStorage.removeItem(TOKENS.ONE_TIME_TOKEN_TIMESTAMP);
    }, []);

    const isTokenAlive = (): boolean => {
        const tokenTimestamp: string | null = localStorage.getItem(TOKENS.ONE_TIME_TOKEN_TIMESTAMP);
        const one_time_token = localStorage.getItem(TOKENS.ONE_TIME_TOKEN);

        if (!one_time_token) return false;

        if (tokenTimestamp) {
            let time_now = Date.now() + 5 * 60 * 1000; // Date new + 5 min
            return !(time_now >= +tokenTimestamp); // if false,  return true
        }
        return false;
    };

    const getNewOneTimeToken = async (): Promise<null | string> => {
        let result = null;

        await getOneTimeToken(null)
            .unwrap()
            .then((res: any) => {
                const HOURS = 10 * 60; // +10 hours
                const timestamp = Date.now() + HOURS * 60 * 1000; // + 1hour
                localStorage.setItem(TOKENS.ONE_TIME_TOKEN, res.one_time_token);
                localStorage.setItem(TOKENS.ONE_TIME_TOKEN_TIMESTAMP, timestamp.toString());
                result = res.one_time_token;
            })
            .catch(() => {
                clear();
            });

        return result;
    };

    useEffect(() => {
        if (!isTokenAlive()) {
            clear();
            getNewOneTimeToken().then((res: string | null) => {
                if (res) {
                    setModifiedLink(`${link}#${res}`);
                }
            });
        } else {
            const ONE_TIME_TOKEN = localStorage.getItem(TOKENS.ONE_TIME_TOKEN);
            const CVAT_TOKEN = ONE_TIME_TOKEN ? `#${ONE_TIME_TOKEN}` : "";
            setModifiedLink(`${link}${CVAT_TOKEN}`);
        }
    }, [link]);

    return { isLoading, modifiedLink };
};
