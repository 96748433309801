import $api from "shared/api/api";
import { IApiKeyInfo, IBalance, IClientException, IConfig, IContactUsPayload, IOrganization, ISetHistory, IUpdateCurrentHistoryResponse, IUser } from "../types/types";
import { API_ENDPOINTS, API_TAGS, KEEP_UNUSED_DATA_FOR } from "shared/config/config";
import { set_user } from "../slice/account-slice";
import external_api from "shared/api/external_api";

export const accountApiSlice = $api.injectEndpoints({
    endpoints: (builder) => ({
        logout: builder.mutation<any, void>({
            query: () => ({
                url: `authentication/logout/`,
                method: "POST",
                keepUnusedDataFor: 1
            })
        }),
        me: builder.query<IUser, void>({
            query: () => ({
                url: `authentication/user/me`,
                method: "GET"
            }),
            providesTags: [API_TAGS.Current_organization],
            async onQueryStarted(result, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                dispatch(set_user(data));
            }
        }),
        balance: builder.query<IBalance, number>({
            query: (organization_id) => ({
                url: `${API_ENDPOINTS.account}balance/${organization_id}/`,
                method: "GET",
                providesTags: [API_TAGS.Balance]
            })
        }),
        setCurrentOrganization: builder.mutation<IUpdateCurrentHistoryResponse, ISetHistory>({
            query: (data) => ({
                url: `${API_ENDPOINTS.authentication}user/${data.user}/history/`,
                method: "POST",
                body: {
                    language: data.language,
                    organization_current: data.organization_current
                }
            }),
            invalidatesTags: [API_TAGS.Current_organization]
        }),
        config: builder.query<IConfig, null>({
            query: () => ({
                url: `${API_ENDPOINTS.account}config/`,
                method: "GET"
            }),
            keepUnusedDataFor: KEEP_UNUSED_DATA_FOR
        }),
        captureClientException: builder.mutation<IClientException, IClientException>({
            query: (data) => ({
                url: `core/log/`,
                method: "POST",
                body: {
                    ...data
                }
            })
        }),
        contactUs: builder.mutation<IContactUsPayload, IContactUsPayload>({
            query: (data) => ({
                url: `core/contact-us/`,
                method: "POST",
                body: data
            })
        }),
        organizationById: builder.query<IOrganization, number>({
            query: (id) => ({
                url: `${API_ENDPOINTS.authentication}organization/${id}`,
                method: "GET"
            }),
            providesTags: [API_TAGS.Organization]
        }),
        createOrganization: builder.mutation<IOrganization, { name: string }>({
            query: (name) => ({
                url: `${API_ENDPOINTS.authentication}organization/`,
                method: "POST",
                body: name
            }),
            invalidatesTags: [API_TAGS.Organization]
        }),
        updateOrganization: builder.mutation<IOrganization, { name: string; id: number }>({
            query: ({ name, id }) => ({
                url: `${API_ENDPOINTS.authentication}organization/${id}/`,
                method: "PATCH",
                body: { name }
            }),
            invalidatesTags: [API_TAGS.Organization, API_TAGS.Current_organization]
        }),
        updateOrganizationCollaborators: builder.mutation<IOrganization, { org_id: number; ids: string[] }>({
            query: ({ org_id, ids }) => ({
                url: `${API_ENDPOINTS.authentication}organization/${org_id}/collaborators/`,
                method: "PATCH",
                body: { users_remove: ids }
            }),
            invalidatesTags: [API_TAGS.Organization]
        }),
        addCollaboratorsByEmail: builder.mutation<IOrganization, { org_id: number; emails: String[] }>({
            query: ({ org_id, emails }) => ({
                url: `${API_ENDPOINTS.authentication}organization/${org_id}/collaborators/`,
                method: "PATCH",
                body: { users_add: emails }
            }),
            invalidatesTags: [API_TAGS.Organization]
        })
    })
});

export const accountExternalApiSlice = external_api.injectEndpoints({
    endpoints: (builder) => ({
        generateApiKey: builder.mutation<{ key: string }, { key_name: string; organization: string }>({
            query: (data) => ({
                url: `/organization/generate_api_key`,
                method: "POST",
                body: data
            }),
            invalidatesTags: [API_TAGS.Api_Key]
        }),
        apiKeyInfo: builder.query<IApiKeyInfo[], number>({
            query: (id) => ({
                url: `/organization/${id}/api-key-info`,
                method: "GET"
            }),
            providesTags: [API_TAGS.Api_Key]
        }),
        deleteApiKey: builder.mutation<{ key: string }, { org_cvat_id: number; prefix: string }>({
            query: (data) => ({
                url: `/organization/${data.org_cvat_id}/delete-api-key/${data.prefix}`,
                method: "DELETE",
                body: data
            }),
            invalidatesTags: [API_TAGS.Api_Key]
        })
    })
});

export const { useGenerateApiKeyMutation, useApiKeyInfoQuery, useDeleteApiKeyMutation } = accountExternalApiSlice;
