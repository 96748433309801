import { memo, useEffect, useRef, useState } from "react";
import {
    account_api,
    account_organization_selector,
    account_organizations_list_selector,
    user_selector,
    set_current_organization,
    account_create_organization_modal_visible_selector,
    setCreateOrganizationModalVisible
} from "entities/account";
import { useAppDispatch, useAppSelector, useClickOutside, useNotification } from "shared/lib/hooks";
import { Spin, Tooltip } from "shared/ui";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "shared/config/interface";
import CreateNewOrganization from "./create-new-oranization";
import ShortOrganizationName from "./short-organization-name";
import "./organization-select.scss";

interface IProps {
    background?: "white" | "grey";
}

const OrganizationSelect = ({ background = "grey" }: IProps): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [setCurrentOrganization, { isLoading, data, error, isError }] = account_api.useSetCurrentOrganizationMutation();
    const [getBalance, { isLoading: isLoadingBalance }] = account_api.useLazyBalanceQuery();
    const { id: userID, language } = useAppSelector(user_selector);
    const {
        name,
        id,
        owner: { id: currentOwnerId }
    } = useAppSelector(account_organization_selector);
    const organizations = useAppSelector(account_organizations_list_selector);
    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
    const [selectedID, setSelectedID] = useState<number | null>(null);
    const ref = useRef<HTMLDivElement>(null);
    const notification = useNotification();
    const createNewVisible = useAppSelector(account_create_organization_modal_visible_selector);

    useEffect(() => {
        if (isError && error) {
            setSelectedID(null);
            let err: any = error;
            notification.error({
                message: JSON.stringify(err?.data!),
                duration: 3
            });
        }
    }, [isError, error]);

    useEffect(() => {
        const fetchBalanceAndUpdateOrganization = async (): Promise<void> => {
            if (data) {
                if (selectedID) {
                    await getBalance(selectedID);
                }
                dispatch(set_current_organization(data));
                setDropdownVisible(false);
                notification.success({
                    message: `The organization has been successfully changed to - ${data.organization_current.name}`
                });
                navigate(location.pathname === "/" ? `/${ROUTES.ORGANIZATION_MEMBRS}` : "/");
            }
        };

        fetchBalanceAndUpdateOrganization();
    }, [data, dispatch]);

    useClickOutside(ref, () => setDropdownVisible(false));

    function setCreateNewVisible(value: boolean): void {
        dispatch(setCreateOrganizationModalVisible(value));
    }

    function onSelectOrganization(selected_organization_id: number): void {
        setSelectedID(selected_organization_id);
        setCurrentOrganization({
            language: language,
            user: userID,
            organization_current: selected_organization_id
        });
    }

    if (isLoading || isLoadingBalance) {
        return (
            <div className="org_select_wrapper">
                <Spin />
            </div>
        );
    }

    return (
        <div className="org_select_wrapper">
            <div onClick={() => setDropdownVisible(true)} className={`org_select_title ${background}`}>
                <Tooltip text={name}>
                    <ShortOrganizationName name={name} owner={userID === currentOwnerId} />
                </Tooltip>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M3.41 4L8 8.94879L12.59 4L14 5.531L8 12L2 5.531L3.41 4Z" fill="#C1C7D0" />
                </svg>
            </div>
            <div ref={ref} className={`org_select_list ${dropdownVisible ? "active" : ""}`}>
                {organizations
                    .filter((el) => el.id !== id)
                    .map((org) => {
                        return (
                            <div className="org_select_list_item" key={org.id} onClick={() => onSelectOrganization(org.id)}>
                                <Tooltip text={org.name}>
                                    <ShortOrganizationName name={org.name} owner={userID === org.owner.id} />
                                </Tooltip>
                            </div>
                        );
                    })}
                <CreateNewOrganization visible={createNewVisible} onClose={() => setCreateNewVisible(false)} />
                <div className="org_select_list_item" onClick={() => setCreateNewVisible(true)}>
                    <div className="org_select_list_item_btn">
                        <span>+</span>
                        Create new
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(OrganizationSelect);
