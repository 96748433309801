import $api from "shared/api/api";
import { API_ENDPOINTS } from "shared/config/config";

interface IResponse {
    one_time_token: string;
}

const oneTimeTokenApi = $api.injectEndpoints({
    endpoints: (builder) => ({
        getOneTimeToken: builder.query<IResponse, null>({
            query: () => ({
                url: `${API_ENDPOINTS.authentication}one-time-token/`,
                method: "GET"
            })
        })
    })
});

export const { useLazyGetOneTimeTokenQuery } = oneTimeTokenApi;
