import { ICollaborator, useIsOwnerCurrentOrganization } from "entities/account";
import "./organization-members-table.scss";
import { Confirm, Spin, Table } from "shared/ui";
import UserNameInformation from "../../../user-name-information/user-name-information";
import { BUSKET_ICON } from "shared/lib/helpers/images/images";
import { IColumnTable } from "shared/config/interface";

interface IProps {
    users: ICollaborator[];
    owner: ICollaborator;
    onDeleteCollaborator: (id: number) => void;
    isDeleting: boolean;
}

const OrganizationMembersTable = ({ users, owner, onDeleteCollaborator, isDeleting }: IProps): JSX.Element => {
    const { is_owner } = useIsOwnerCurrentOrganization();

    const COLUMNS: IColumnTable<ICollaborator>[] = [
        {
            title: "User",
            dataIndex: "user",
            key: "user",
            render: (user) => <UserNameInformation user_name={user.username} email={user.email} />
        },
        {
            title: "Role Key",
            dataIndex: "role",
            key: "role",
            render: (user) => <span className={`${user.id === owner.id ? "org_table_tbody_role_owner" : "org_table_tbody_role"}`}>{user.id === owner.id ? "Owner" : "Collaborator"}</span>
        },
        {
            title: "Remove member",
            dataIndex: "remove",
            key: "remove",
            width: 200,
            render: (user) => (
                <span>
                    {user.id === owner.id ? (
                        ""
                    ) : is_owner ? (
                        <Confirm title="Are you sure you want to delete this user from organization?" onConfirm={() => onDeleteCollaborator(user.id)} okText="OK" cancelText="Cancel">
                            {isDeleting ? <Spin /> : <BUSKET_ICON style={{ cursor: "pointer" }} />}
                        </Confirm>
                    ) : (
                        ""
                    )}
                </span>
            )
        }
    ];

    let filtered_users = users.filter((user) => user.id !== owner.id);

    return (
        <div className="org_members_table">
            <Table columns={COLUMNS} data={[owner, ...filtered_users]} />
        </div>
    );
};

export default OrganizationMembersTable;
