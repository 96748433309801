import { Link } from "react-router-dom";
import { ROUTES } from "shared/config/interface";

const SignUpLinks = (): JSX.Element => {
    return (
        <>
            <span className="authorization_link">
                By clicking Sign up, I confirm that I have read and agree with <br />{" "}
                <Link target="_blank" rel="noopener noreferrer" to={`/${ROUTES.TERMS}`}>
                    Terms and conditions
                </Link>
            </span>
            <span className="authorization_link">
                Have an account already?{" "}
                <Link to={ROUTES.LOGIN} data-testid="sign-in-link">
                    Sign in
                </Link>
            </span>
        </>
    );
};

export default SignUpLinks;
