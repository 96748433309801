import { CLOSE_ICON, FAIL_ICON, SUCCESS_ICON } from "shared/lib/helpers/images/images";
import "./useNotification.scss";

const DEFAULT_DURATION_TIME = 4;

interface INotificationContent {
    message: string;
    duration?: number;
    onClose?: (id: string) => void;
}

interface IOpenNotification {
    message: string;
    duration?: number;
    type: NotificationType;
    onClose: undefined | ((id: string) => void);
}

interface IcreateRootWrapper {
    root_wrapper: HTMLCollectionOf<HTMLBodyElement>;
    node_el: HTMLDivElement;
}

type NotificationType = "success" | "error";
// type ReturnNotification = {
//     notification: Record<NotificationType, (args: INotificationContent) => void>;
// };
type NotificationFunction = (args: INotificationContent) => void;

export const useNotification = (): Record<NotificationType, NotificationFunction> => {
    const ICONS: { [type: string]: string } = {
        success: SUCCESS_ICON,
        error: FAIL_ICON
    };

    let notification: Record<NotificationType, NotificationFunction> = {
        success: ({ message, duration, onClose }: INotificationContent) =>
            openNotification({
                type: "success",
                message,
                duration,
                onClose
            }),
        error: ({ message, duration, onClose }: INotificationContent) =>
            openNotification({
                type: "error",
                message: message,
                duration,
                onClose
            })
    };

    let { node_el, root_wrapper } = createRootWrapper();

    function createRootWrapper(): IcreateRootWrapper {
        const root_wrapper: HTMLCollectionOf<HTMLBodyElement> = document.getElementsByTagName("body");
        const check_wrapper: HTMLDivElement | null = document.querySelector(".notification_wrap");

        if (!check_wrapper) {
            let node_el: HTMLDivElement = document.createElement("div");
            node_el.classList.add(`notification_wrap`);
            return { node_el, root_wrapper };
        }

        return { node_el: check_wrapper, root_wrapper };
    }

    function onClickCloseItem(): void {}

    function openNotification({ type, message, duration = DEFAULT_DURATION_TIME, onClose = undefined }: IOpenNotification): void {
        const ID = `${Math.round(Math.random() * 1000)}_${type}`;

        node_el.insertAdjacentHTML(
            "afterbegin",
            `<div id='${ID}' class="notification ${type} ">
                <img src='${ICONS[type]}'/>
                <div class="notification_wrapper">
                    <p class="notification_wrapper_title">${type}</p>
                    <span class="notification_wrapper_description">${message}</span>
                </div>
                <span  id='${ID + "_close"}' class="notification_close">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#A5ADBA"/>
                    </svg>
                </span>
            </div>
        `
        );
        root_wrapper[0].appendChild(node_el);

        const closeBtn = document.getElementById(`${ID}_close`);
        if (closeBtn) {
            closeBtn.addEventListener("click", () => {
                closeNotification(ID, 0, onClose);
            });
        }

        closeNotification(ID, duration, onClose);
    }

    function closeNotification(ID: string, duration: number, onClose: any): void {
        setTimeout(() => {
            const elem = document.getElementById(ID);
            if (elem) {
                elem.classList.add(`hide`);
                if (onClose) {
                    onClose(ID);
                }

                elem.addEventListener("transitionend", () => {
                    elem.remove();
                });
            }
        }, duration * 1000);
    }

    return notification;
};
