import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useClickOutside } from "shared/lib/hooks";
import "./drawer.scss";

interface IProps {
    visible: boolean;
    // size: 'small' | 'medium' | 'large';
    // afterOpenChange?: () => void;
    onClose: () => void;
    // mask?: JSX.Element | null;
    children: React.ReactNode;
    title: React.ReactNode | string;
    // footer: React.ReactNode;
}

function createPortalRoot(): any {
    const drawerRoot = document.createElement("div");
    drawerRoot.setAttribute("id", "drawer-root");

    return drawerRoot;
}

const Drawer = (props: IProps): JSX.Element | null => {
    const { visible, children, onClose, title } = props;

    const [classAnimation, setClassAnimation] = useState("");
    const portalRootRef = useRef(document.getElementById("drawer-root") || createPortalRoot());
    const bodyRef = useRef(document.querySelector("body"));
    const drawerRef = useRef(null);
    useClickOutside(drawerRef, () => onClose());

    useEffect(() => {
        setClassAnimation(visible ? "active" : "");
    }, [visible]);

    useEffect(() => {
        const updatePageScroll = (): any => {
            if (!bodyRef.current) return;

            bodyRef.current.appendChild(portalRootRef.current);
            const portal = portalRootRef.current;
            const bodyEl = bodyRef.current;

            if (visible) {
                bodyRef.current.style.overflow = "hidden";
            } else {
                bodyRef.current.style.overflow = "";
            }

            return (): void => {
                portal.remove();
                bodyEl.style.overflow = "";
            };
        };

        updatePageScroll();
    }, [visible]);

    // const handleClose = (): void => {
    //     onClose();
    // };

    // Add close on ESC
    // Use Portal component
    // Add Close X
    // Add header and footer

    if (!visible) return null;

    return createPortal(
        <div aria-hidden={visible ? "false" : "true"} className={`drawer-wrapper ${classAnimation ? "active" : ""} `}>
            <div className="drawer-mask" />
            <div ref={drawerRef} className="drawer">
                <div className="drawer-header">
                    <h1 className="drawer-header_title">{title}</h1>
                </div>
                <div className="drawer-content">{children}</div>
                {/* <div className="drawer-footer">"footer"</div> */}
                {/* <span className="drawer_close" onClick={handleClose}>X</span> */}
            </div>
        </div>,
        portalRootRef.current
    );
};

export default Drawer;
