import { AnnotationTools, InstrumentsEnum } from "./types";

export const imagesColor = "#1877F2";
export const objectsPerImageColor = "#FF0000";
export const labelsColor = "#FBBC05";
export const attributesColor = "#00C17C";

export let calculator_colors = [
    { label: "Images", color: imagesColor },
    { label: "Average annotations", color: objectsPerImageColor },
    { label: "Label", color: labelsColor },
    { label: "Attribute", color: attributesColor }
];

export const RESULT_VARIANTS: Record<string, InstrumentsEnum> = {
    rectangle_image: InstrumentsEnum.rectangle_image,
    cuboid_image: InstrumentsEnum.cuboid_image,
    points_image: InstrumentsEnum.keypoint,
    polygon_image: InstrumentsEnum.polygon_accuracy_lower
    // cuboid_video: InstrumentsEnum.cuboid_image,
    // points_video: InstrumentsEnum.keypoint,
    // polygon_video: InstrumentsEnum.polygon_accuracy_lower,
    // rectangle_video: InstrumentsEnum.rectangle_video,
};

export const TOOLS = [
    { label: "Rectangle", value: AnnotationTools.RECTANGLE },
    { label: "Polygon", value: AnnotationTools.POLYGON },
    { label: "Cuboid", value: AnnotationTools.CUBOID },
    { label: "Keypoint", value: AnnotationTools.KEYPOINT }
];

export const TO_FIXED_VALUE = 4;
