import { useState } from "react";

type UseToggleType = {
    isToggle: boolean;
    toggleHandler: () => void;
};

export const useToggle = (defaultValue: boolean = false): UseToggleType => {
    const [isToggle, setIsToggle] = useState<boolean>(defaultValue);

    const toggleHandler = (): void => setIsToggle(!isToggle);

    return {
        toggleHandler,
        isToggle
    };
};
