import "./block-wrapper.scss";

interface IProps {
    children: JSX.Element | React.ReactNode;
    className?: string;
}

const BlockWrapper: React.FC<IProps> = ({ children, className = "" }): JSX.Element => {
    return <div className={`block_wrapper ${className}`}>{children}</div>;
};

export default BlockWrapper;
