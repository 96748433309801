import { RootState } from "app/store-config";
import { DashboardProjectType } from "../types/interface";
import { createSelector } from "@reduxjs/toolkit";
import { mergeProjects } from "shared/lib/helpers";

export const get_projects_loading = (state: RootState): boolean => state.dashboard.loading;
export const get_cvat_projects_selector = (state: RootState): DashboardProjectType[] => state.dashboard.cvat_projects;
const get_projects_history_selector = (state: RootState): DashboardProjectType[] => state.dashboard.projects_history;

export const get_projects_selector = createSelector([get_cvat_projects_selector, get_projects_history_selector], (cvat_projects, projects_history): DashboardProjectType[] => {
    let merged: DashboardProjectType[] = [...cvat_projects, ...projects_history];
    let finalProjects: Record<number, DashboardProjectType> = {};

    merged.forEach((project) => {
        let cvatId = project.cvat_project_id;

        if (finalProjects[cvatId]) {
            finalProjects[cvatId] = mergeProjects(finalProjects[cvatId], project);
        } else {
            finalProjects[cvatId] = project;
        }
    });

    let finalArray: DashboardProjectType[] = Object.values(finalProjects);
    finalArray.sort((a, b) => new Date(b.created_date).getTime() - new Date(a.created_date).getTime());

    return finalArray;
});
