import AWS from "shared/api/aws";
import { INSTRUCTION_FILE_FORMATS } from "shared/config/config";
import { AWS_UPLOAD_FILE_RESPONSE } from "shared/config/interface";
import { replace_spaces } from "shared/lib/helpers";

export const upload_instruction = async (id: number, project_name: string, instruction_file: Blob, username: string): Promise<number | undefined | AWS_UPLOAD_FILE_RESPONSE> => {
    let new_project_name = replace_spaces(project_name);

    let fileExtension: string;
    if (instruction_file.type === "application/pdf") {
        fileExtension = INSTRUCTION_FILE_FORMATS.PDF;
    } else if (instruction_file.type === "text/plain") {
        fileExtension = INSTRUCTION_FILE_FORMATS.TXT;
    } else if (instruction_file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        fileExtension = INSTRUCTION_FILE_FORMATS.DOCX;
    } else {
        throw new Error("Unsupported file type");
    }

    let fileNameSuffix = `_instruction.${fileExtension}`;

    let folder: string = `${username}-${id}/${new_project_name}/instruction/${new_project_name}${fileNameSuffix}`;

    const aws = await AWS.upload_file(instruction_file, folder);

    return aws;
};
