import { EDIT_ICON } from "shared/lib/helpers/images/images";
import "./label.scss";
import { ILabel } from "shared/config/interface";

interface ILabelProps {
    el: ILabel | any;
    onEditLabel: (id: string) => void;
    removeLabel: (id: string) => void;
}

const Label = ({ el, onEditLabel, removeLabel }: ILabelProps): JSX.Element => {
    return (
        <div className="label">
            <p>{el.name}</p>
            <span onClick={() => onEditLabel(el.name)} className="label_edit">
                <EDIT_ICON />
            </span>
            <span className="label_delete" onClick={() => removeLabel(el.name)}>
                x
            </span>
        </div>
    );
};

export default Label;
