import { ISelectOptions } from "shared/config/interface";

export enum StorageProviders {
    AWS = "aws",
    AZURE = "azure",
    GOOGLE_CLOUD = "google"
}

export interface IAwsCredential {
    access_key_id?: string;
    secret_access_key?: string;
    region_name: string;
}
export interface IAzureCredential {
    account_name?: string;
    connection_string?: string;
    sas_token?: string;
}
export interface IGoogleCredential {
    service_account_json: Record<string, string>;
    filename: string;
    location: string;
}

export interface ICloudCredentialCreate<T> {
    name: string;
    provider: StorageProviders;
    storage_name: string;
    credential: T;
    anonymous?: boolean;
    prefix?: string;
    save?: boolean;
}

export interface ICloudCredential {
    id: number;
    organization: number;
    name: string;
    provider: StorageProviders;
    storage_name: string;
    credential: CredentialType;
    prefix: string;
    anonymous: boolean;
    count_files: boolean;
    created_at: Date;
    updated_at: Date;
    status: boolean;
    is_connected: boolean;
    user: {
        id: number;
        email: string;
        username: string;
    };
}

export interface IFormDataBase {
    name: string;
    provider: ISelectOptions;
    prefix: string;
}

export interface IAWSProviderValues extends IFormDataBase {
    region: ISelectOptions;
    storage_name: string;
    authentication_type: ISelectOptions;
    access_key_id?: string;
    secret_access_key?: string;
}
export interface IAzureProviderValues extends IFormDataBase {
    storage_name: string;
    authentication_type: ISelectOptions;
    account_name: string;
    sas_token?: string;
    connection_string?: string;
}
export interface IGoogleProviderValues extends IFormDataBase {
    storage_name: string;
    authentication_type: ISelectOptions;
    location: ISelectOptions;
    key?: string;
}

export enum AWSAuthentication {
    KEYS = "keys",
    ANONYMOUS = "anonymous"
}

export enum AzureAuthentication {
    SAS = "SAS",
    ANONYMOUS = "anonymous",
    CONNECTION = "connection_string"
}
export enum GoogleAuthentication {
    ANONYMOUS = "anonymous",
    KEY = "key_file"
}

export interface ICloudOption extends ISelectOptions {
    icon: React.ReactNode;
}

export interface IICons {
    [StorageProviders.AWS]: React.ReactNode;
    [StorageProviders.AZURE]: React.ReactNode;
    [StorageProviders.GOOGLE_CLOUD]: React.ReactNode;
}

export type CloudStorageFormDate = IAWSProviderValues | IAzureProviderValues | IGoogleProviderValues;
export type CredentialType = IAwsCredential | IAzureCredential | IGoogleCredential;

export type ImportCloudCredentialType = Pick<ICloudCredential, "anonymous" | "credential" | "prefix" | "provider" | "storage_name"> & {
    quantity_files: number;
};
export interface ICloudError {
    detail: string;
    base_message: string;
    category: string;
}
