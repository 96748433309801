import { ILabel, AttributeValueType } from "shared/config/interface";

type ValueTypeResponse = string | string[];

export function conver_labels_to_CVAT_format(labels: ILabel[]): any {
    function check_type_values(values: AttributeValueType): ValueTypeResponse | undefined {
        if (values === null) {
            return [""];
        }
        if (typeof values === "string" || typeof values === "undefined") {
            return values;
        }
        if ("value" in values) {
            return [values.value.toString()];
        }

        if (values.length > 0) {
            return values.map((valueObj: any) => valueObj.value);
        }
    }

    return labels.map((item) => {
        const attributes =
            item.attributes &&
            item.attributes.map((attr) => {
                const values = check_type_values(attr.values);
                const { ...attrWithoutId } = attr;
                return {
                    ...attrWithoutId,
                    values,
                    input_type: attr.input_type,
                    default_value: values && values[0]
                };
            });

        return {
            ...item,
            attributes
        };
    });
}
