import { account_api, set_current_organization, user_selector } from "entities/account";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "shared/config/interface";
import { useAppDispatch, useAppSelector, useNotification } from "shared/lib/hooks";
import { Button, FormItem, Input, Modal } from "shared/ui";

const CreateNewOrganization = ({ visible, onClose }: { visible: boolean; onClose: () => void }): JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { id: userID, language } = useAppSelector(user_selector);
    const [value, setValue] = useState<string>("");
    const [setCurrentOrganization, { error: errorSetCurrent }] = account_api.useSetCurrentOrganizationMutation();
    const [create, { isLoading, error: errorCreateOrganization }] = account_api.useCreateOrganizationMutation();
    const notification = useNotification();

    useEffect(() => {
        if (errorSetCurrent || errorCreateOrganization) {
            let err: any = errorSetCurrent || errorCreateOrganization;
            notification.error({
                message: JSON.stringify(err?.data!),
                duration: 3
            });
        }
    }, [errorSetCurrent || errorCreateOrganization]);

    useEffect(() => {
        return () => {
            setValue("");
        };
    }, []);

    function onChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let value = e.target.value;
        setValue(value);
    }

    function onCreate(): void {
        create({
            name: value
        }).then((res: any) => {
            if (res && res.data) {
                notification.success({
                    message: `Organization ${value} has been created.`
                });
                setValue("");
                setCurrentOrganization({
                    language: language,
                    user: userID,
                    organization_current: res.data.id
                }).then((response: any) => {
                    if (response.data) {
                        dispatch(set_current_organization(response.data));
                        notification.success({
                            message: `The organization has been successfully changed to - ${response.data.organization_current.name}`
                        });
                        navigate(location.pathname === "/" ? `/${ROUTES.ORGANIZATION_MEMBRS}` : "/");
                    }
                    onClose();
                });
            }
        });
    }

    return (
        <Modal width={450} visible={visible} onCancel={onClose} title="New organization">
            <>
                <FormItem label="Organization name" error={undefined}>
                    <Input autoFocus value={value} onChange={onChange} placeholder="Enter organization name" />
                </FormItem>
                <Button onClick={onCreate} disabled={isLoading || value.length === 0} style={{ marginLeft: "auto" }} size="small">
                    Create organization
                </Button>
            </>
        </Modal>
    );
};

export default CreateNewOrganization;
