export const short_username = (user_name: string): string => {
    if (!user_name) return "";

    if (user_name.length < 2) return user_name[0].toUpperCase();

    const first_letter = user_name[0].toUpperCase();
    const second_letter = user_name[1].toUpperCase();

    return `${first_letter}${second_letter}`;
};
