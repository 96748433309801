import { UseFormSetValue } from "react-hook-form";
import {
    AWSAuthentication,
    AzureAuthentication,
    CloudStorageFormDate,
    CredentialType,
    GoogleAuthentication,
    IAwsCredential,
    IAWSProviderValues,
    IAzureCredential,
    IAzureProviderValues,
    ICloudCredential,
    ICloudCredentialCreate,
    ICloudOption,
    IGoogleCredential,
    IGoogleProviderValues,
    IICons,
    ImportCloudCredentialType,
    StorageProviders
} from "../types/types";
import { default_value_to_select, remove_spaces } from "shared/lib/helpers";
import { AWS_AUTH_OPTIONS, AZURE_AUTH_OPTIONS, CLOUD_STORAGE_PROVIDERS_OPTIONS, DEFAULT_AWS_S3_REGIONS, DEFAULT_GOOGLE_CLOUD_STORAGE_LOCATIONS, GOOGLE_AUTH_OPTIONS } from "../config/config";

export class CloudStorageService {
    static __quantity_files: number;
    private static __google_key_file_name: string;

    static payload_constructor(values: CloudStorageFormDate): ICloudCredentialCreate<CredentialType> {
        let handlers: Record<StorageProviders, any> = {
            [StorageProviders.AWS]: this.aws_payload_constructor,
            [StorageProviders.AZURE]: this.azure_payload_constructor,
            [StorageProviders.GOOGLE_CLOUD]: this.google_payload_constructor
        };

        const provider: StorageProviders = values.provider.value as StorageProviders;

        return handlers[provider](values);
    }

    static aws_payload_constructor(values: IAWSProviderValues): ICloudCredentialCreate<IAwsCredential> {
        let is_anonymous = values.authentication_type.value === AWSAuthentication.ANONYMOUS;

        let final_data: ICloudCredentialCreate<IAwsCredential> = {
            name: values.name,
            provider: values.provider.value as StorageProviders,
            storage_name: values.storage_name,
            credential: {
                access_key_id: values.access_key_id!,
                secret_access_key: values.secret_access_key!,
                region_name: values.region.value
            }
        };

        if (values.prefix.length > 0) {
            final_data["prefix"] = CloudStorageService.check_prefix(values.prefix);
        }

        if (is_anonymous) {
            delete final_data.credential.access_key_id;
            delete final_data.credential.secret_access_key;
            final_data["anonymous"] = true;
        }

        return final_data;
    }

    static azure_payload_constructor(values: IAzureProviderValues): ICloudCredentialCreate<IAzureCredential> {
        function set_azure_credentials(): IAzureCredential {
            let final_data: IAzureCredential = {
                account_name: values.account_name
            };
            if (values.authentication_type.value === AzureAuthentication.SAS) {
                final_data["sas_token"] = values.sas_token;
            }

            if (values.authentication_type.value === AzureAuthentication.CONNECTION) {
                delete final_data["account_name"];
                final_data["connection_string"] = values.connection_string;
            }

            return final_data;
        }

        let is_anonymous = values.authentication_type.value === AzureAuthentication.ANONYMOUS;

        let final_data: ICloudCredentialCreate<IAzureCredential> = {
            name: values.name,
            provider: values.provider.value as StorageProviders,
            storage_name: values.storage_name,
            prefix: CloudStorageService.check_prefix(values.prefix),
            credential: set_azure_credentials()
        };

        if (is_anonymous) {
            final_data["anonymous"] = true;
        }

        return final_data;
    }

    static google_payload_constructor(values: IGoogleProviderValues): ICloudCredentialCreate<IGoogleCredential> {
        let is_anonymous = values.authentication_type.value === GoogleAuthentication.ANONYMOUS;

        let final_data: ICloudCredentialCreate<IGoogleCredential> = {
            name: values.name,
            provider: values.provider.value as StorageProviders,
            storage_name: values.storage_name,
            prefix: CloudStorageService.check_prefix(values.prefix),
            credential: {
                location: values.location.value,
                filename: CloudStorageService.__google_key_file_name,
                service_account_json: {}
            }
        };

        if (is_anonymous) {
            final_data["anonymous"] = true;
        }

        if (!is_anonymous) {
            final_data["credential"]["service_account_json"] = JSON.parse(values.key!);
        }

        return final_data;
    }

    static check_prefix(prefix: string): string {
        if (!prefix) return "";

        let trimmed_prefix = remove_spaces(prefix);
        let arr = trimmed_prefix.split("");
        let last = arr[trimmed_prefix.length - 1];

        if (last === "/") return trimmed_prefix;

        return `${trimmed_prefix}/`;
    }

    static format_cloud_storages_for_tasks_select(data: ICloudCredential[], icons: IICons): ICloudOption[] {
        if (!data) return [];

        function define_icon(provider: StorageProviders, icons: IICons): any {
            if (provider === StorageProviders.AWS) {
                return icons.aws;
            }
            if (provider === StorageProviders.AZURE) {
                return icons.azure;
            }

            return icons.google;
        }

        let new_arr: ICloudOption[] = data.map((el) => ({
            value: `${el.id}`,
            label: `${el.name} - ${el.storage_name} ${el.is_connected ? "" : "- (Not connected)"}`,
            isDisabled: !el.is_connected,
            icon: define_icon(el.provider, icons)
        }));

        return new_arr;
    }

    static import_cloud_credentials(credentials: ICloudCredential, new_prefix: string): ImportCloudCredentialType {
        let prefix = new_prefix.length > 0 ? new_prefix : credentials.prefix;

        let final_data: ImportCloudCredentialType = {
            storage_name: credentials.storage_name,
            provider: credentials.provider,
            anonymous: credentials.anonymous,
            prefix,
            credential: credentials.credential,
            quantity_files: this.__quantity_files
        };

        return final_data;
    }

    static set_quantity_files(quantity_files: number): void {
        this.__quantity_files = quantity_files;
    }

    static async parse_google_key(key: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = (e: ProgressEvent<FileReader>) => {
                try {
                    const result: string = e.target?.result as string;
                    CloudStorageService.__google_key_file_name = key.name;
                    resolve(result);
                } catch (error) {
                    reject(new Error("Invalid JSON file"));
                }
            };

            fileReader.onerror = () => {
                reject(new Error("Failed to read file"));
            };

            key.size > -1 && fileReader.readAsText(key, "UTF-8");
        });
    }

    static set_data_to_edit_form(data: ICloudCredential, setValue: UseFormSetValue<CloudStorageFormDate>, icons: IICons): void {
        setValue("name", data.name);
        setValue("prefix", data.prefix);
        setValue("provider", default_value_to_select(data.provider, CLOUD_STORAGE_PROVIDERS_OPTIONS(icons)));
        setValue("storage_name", data.storage_name);

        if (data.provider === StorageProviders.AWS) {
            if (data.anonymous === true) {
                let credentials: IAwsCredential = data.credential as IAwsCredential;
                setValue("region", default_value_to_select(credentials.region_name, DEFAULT_AWS_S3_REGIONS));
                setValue("authentication_type", default_value_to_select(AWSAuthentication.ANONYMOUS, AWS_AUTH_OPTIONS));
            } else {
                let credentials: IAwsCredential = data.credential as IAwsCredential;

                setValue("authentication_type", default_value_to_select(AWSAuthentication.KEYS, AWS_AUTH_OPTIONS));
                setValue("region", default_value_to_select(credentials.region_name, DEFAULT_AWS_S3_REGIONS));
                setValue("access_key_id", credentials.access_key_id);
                setValue("secret_access_key", credentials.secret_access_key);
            }
        }

        if (data.provider === StorageProviders.AZURE) {
            if (data.anonymous === true) {
                setValue("authentication_type", default_value_to_select(AzureAuthentication.ANONYMOUS, AZURE_AUTH_OPTIONS));
            } else {
                let credentials: IAzureCredential = data.credential as IAzureCredential;
                if (credentials.connection_string) {
                    setValue("authentication_type", default_value_to_select(AzureAuthentication.CONNECTION, AZURE_AUTH_OPTIONS));
                    setValue("connection_string", credentials.connection_string);
                }
                if (credentials.sas_token) {
                    setValue("authentication_type", default_value_to_select(AzureAuthentication.SAS, AZURE_AUTH_OPTIONS));
                    setValue("account_name", credentials.account_name!);
                    setValue("sas_token", credentials.sas_token);
                }
            }
        }

        if (data.provider === StorageProviders.GOOGLE_CLOUD) {
            if (data.anonymous === true) {
                setValue("authentication_type", default_value_to_select(GoogleAuthentication.ANONYMOUS, GOOGLE_AUTH_OPTIONS));
            } else {
                let credentials: IGoogleCredential = data.credential as IGoogleCredential;
                if (credentials) {
                    setValue("authentication_type", default_value_to_select(GoogleAuthentication.KEY, GOOGLE_AUTH_OPTIONS));
                    setValue("location", default_value_to_select(credentials.location, DEFAULT_GOOGLE_CLOUD_STORAGE_LOCATIONS));
                    setValue("key", credentials.filename);
                }
            }
        }
    }
}
