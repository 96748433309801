import { Outlet } from "react-router-dom";
import "./layout-auth.scss";

const LayoutAuth = (): JSX.Element => {
    return (
        <div className="auth-layout">
            <div className="auth-layout_wrapper">
                <Outlet />
            </div>
        </div>
    );
};

export default LayoutAuth;
