import { ICheckUserName } from "entities/authorization";
import { Spin } from "shared/ui";

interface IUserNameVariantsProps {
    isLoadingCheckUserName: boolean;
    variants: ICheckUserName | undefined;
    setVariantUserName: (username: string) => void;
}

const UserNameVariants = ({ isLoadingCheckUserName, variants, setVariantUserName }: IUserNameVariantsProps): JSX.Element => {
    return (
        <>
            {isLoadingCheckUserName && <Spin />}
            {variants && variants.exists === true && (
                <div className="signup_username" data-testid="username_variants">
                    {variants.possible_usernames.map((username: string) => (
                        <span data-testid="variant_username" onClick={() => setVariantUserName(username)} className="signup_username_item" key={username}>
                            {username}
                        </span>
                    ))}
                </div>
            )}
        </>
    );
};

export default UserNameVariants;
