export enum ROUTES {
    AUTH = "authorization",
    LOGIN = "/sign-in",
    REGISTRATION = "/sign-up",
    CHECK_REGISTRATION_EMAIL = "/sign-in/:uid/:token",
    RESET_PASSWORD_NEW = "/reset_password/:uid/:token",
    RESET_PASSWORD = "/reset_password",
    CONFIRM_EMAIL = "/confirm-email",
    DASHBOARD = "/",
    CALCULATOR = "calculator",
    BILLING = "billing",
    BILLING_SUCCESS = "payment-success",
    CREATE_PROJECT_MAIN = "create-project",
    CREATE_PROJECT = "create-project/:type",
    PROJECT = "project",
    ORGANIZATION = "organization",
    ORGANIZATION_MEMBRS = "organization/members",
    ORGANIZATION_API = "organization/api",
    ORGANIZATION_CLOUD = "organization/cloud-storage",
    TERMS = "terms-of-use",
    PRIVACY = "privacy-policy"
}

export enum ATTRIBUTE_TYPE {
    SELECT = "select",
    RADIO = "radio",
    CHECKBOX = "checkbox",
    TEXT = "text",
    NUMBER = "number"
}

export enum BUDGET_TYPES {
    PHOTO = "photo",
    DEFINE_SUM = "define"
}

export enum PROJECT_TYPE {
    CV = "computer_vision",
    NLP = "natural_language_processing",
    DC = "data_collection"
}

export enum LANGUAGES {
    UKR = "urk",
    ENG = "eng"
}

export interface IResponse<T> {
    count: number;
    next: string | null;
    previous: string | null;
    results: [] | T[];
}

export interface ISelectTypeOptions<T> {
    value: T;
    label: string;
}

export interface ISelectOptions {
    value: string;
    label: string;
    isDisabled?: boolean;
}

export type AttributeValueType = string | { value: string; label: string } | { value: string; label: string }[];

export interface IAttributeLabel {
    name: string;
    mutable: boolean;
    input_type: AttributeType;
    values: any;
}

export type AttributeType = ATTRIBUTE_TYPE.CHECKBOX | ATTRIBUTE_TYPE.RADIO | ATTRIBUTE_TYPE.TEXT | ATTRIBUTE_TYPE.NUMBER | ATTRIBUTE_TYPE.SELECT;
export interface ILabel {
    name: string;
    attributes: IAttributeLabel[];
}
export type BudgetingType = BUDGET_TYPES.PHOTO | BUDGET_TYPES.DEFINE_SUM;

export type LanguageType = LANGUAGES.ENG | LANGUAGES.UKR;

export type ProjectType = PROJECT_TYPE.CV | PROJECT_TYPE.NLP | PROJECT_TYPE.DC;

export interface ITask {
    id: number;
    name: string;
    project_id: number;
    created_date: Date;
    updated_date: Date;
    status: Statuses;
    dumped_annotation: boolean;
    progress_bar: {
        progress_frames: number;
        total_frames: number;
    };
    cvat_task_id: number;
    dump_annotations_link: string;
    budget: number;
    approximate_price: number;
    bill: IBill[];
    cost: number;
    paused: boolean;
    sent: boolean;
    verify: boolean;
    pilot: boolean;
    budget_prediction: number;
}

export enum TOKENS {
    TOKEN = "token",
    TOKEN_CVAT = "token_cvat",
    ONE_TIME_TOKEN = "one_time_token",
    ONE_TIME_TOKEN_TIMESTAMP = "one_time_token_timestamp"
}

export enum GlobalStatuses {
    ANNOTATION = "annotation",
    VALIDATION = "validation",
    COMPLETED = "completed",
    WAITING = "waiting",
    PENDING = "pending",
    PAID = "paid",
    FAILED = "failed",
    CANCELLED = "cancelled",
    COST = "cost",
    PAUSED = "paused",
    NEED_BUDGET = "Need budget"
}

export type Statuses = GlobalStatuses.ANNOTATION | GlobalStatuses.VALIDATION | GlobalStatuses.COMPLETED;

export interface IProject {
    id: number;
    name: string;
    prime_owner: IPrimeOwner;
    bug_tracker: string;
    created_date: Date;
    updated_date: Date;
    status: Statuses;
    assignee_id: null;
    assignees: [];
    prime_owner_id: number;
    owner_ids: number[];
    annotators: number[];
    annotators_black_list: number[];
    owners: { user: IPrimeOwner }[];
    tasks: { id: number }[];
    guideline_link: string;
    discussion_link: null | string;
    archived: "no" | "yes";
    summary: string;
    annotation_type: ANNOTATION_TYPES;
    url: string;
    projects_history_id: number;
}

export interface IPrimeOwner {
    url: string;
    id: number;
    username: string;
    first_name: string;
    last_name: string;
}

export enum ANNOTATION_TYPES {
    object_detection = "object_detection",
    object_tracking = "object_tracking",
    object_segmentation = "object_segmentation",
    ocr = "ocr",
    data_collection = "data_collection",
    other = "other"
}

export enum ORGANIZATIONS {
    "Personal" = "personal",
    "Organization" = "Organization"
}

export interface ITokens {
    key: string;
    key_cvat: string;
    first_sign_in?: boolean;
    // one_time_token: string;
}

export enum ROLES {
    ADMIN = "admin",
    Client = "client"
}

export enum InstrumentsEnum {
    rectangle_image = "rectangle_image",
    rectangle_video = "rectangle_video",
    keypoint = "points",
    polygon_accuracy_lower = "polygon_accuracy_lower",
    polygon_accuracy_higher = "polygon_accuracy_higher",
    cuboid_video = "cuboid_video",
    cuboid_image = "cuboid_image"
}

export interface IBill {
    label: InstrumentsEnum;
    cost: number;
    quantity: number;
}

export enum MIXPANEL_TRACK_KEYS {
    LOGIN = "LOGIN",
    LOGIN_GOOGLE = "LOGIN_GOOGLE",
    LOGIN_FACEBOOK = "LOGIN_FACEBOOK",
    SIGN_UP = "SIGN_UP",
    SIGN_UP_GOOGLE = "SIGN_UP_GOOGLE",
    SIGN_UP_FACEBOOK = "SIGN_UP_FACEBOOK",
    BALANCE_TOP_UP = "BALANCE_TOP_UP",
    CREATE_PROJECT = "CREATE_PROJECT",
    CREATE_TASK = "CREATE_TASK",
    CREATE_TASK_PILOT = "CREATE_TASK_PILOT",
    CREATE_TASK_UPLOAD_FILES_TIME = "CREATE_TASK_UPLOAD_FILES_TIME",
    NEED_EXTERNAL_API_VIEW = "NEED_EXTERNAL_API_VIEW",
    CONTACT_US = "CONTACT_US",
    UPDATE_TASK_BUDGET = "UPDATE_TASK_BUDGET",
    ERROR_PAGE_VIEW = "ERROR_PAGE_VIEW",
    PARTIAL_COMPLETION_OF_THE_TASK = "PARTIAL_COMPLETION_OF_THE_TASK",
    API_ERRORS = "API_ERRORS"
}
export interface IColumnTable<T> {
    title: React.ReactNode;
    dataIndex: keyof T | string;
    key: string;
    width?: number;
    render?: (record: T) => React.ReactNode;
}

export enum TaskStausOnOffActions {
    "NONE",
    "PLAY",
    "PAUSE",
    "COMPLETED"
}

export enum CreateTaskTypes {
    COMPUTER = "computer",
    CLOUD_STORAGE = "cloud_storage"
}
