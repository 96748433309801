import { memo } from "react";
import "./alert.scss";

type Props = {
    type: AlertTypes;
    text: string;
};

type AlertTypes = "warning" | "info" | "error" | "success";

const Alert = ({ text, type }: Props): JSX.Element => {
    const WARNING_ICON = (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                d="M7.2 10.4H8.8V12H7.2V10.4ZM7.2 4H8.8V8.8H7.2V4ZM8 0C3.576 0 0 3.6 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.08601 14.3997 3.96793 14.989 4.93853 15.391C5.90914 15.7931 6.94943 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 6.94943 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 0 8 0ZM8 14.4C6.30261 14.4 4.67475 13.7257 3.47452 12.5255C2.27428 11.3253 1.6 9.69739 1.6 8C1.6 6.30261 2.27428 4.67475 3.47452 3.47452C4.67475 2.27428 6.30261 1.6 8 1.6C9.69739 1.6 11.3253 2.27428 12.5255 3.47452C13.7257 4.67475 14.4 6.30261 14.4 8C14.4 9.69739 13.7257 11.3253 12.5255 12.5255C11.3253 13.7257 9.69739 14.4 8 14.4Z"
                fill="#FF8B00"
            />
        </svg>
    );

    const INFO_ICON = (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <rect x="0.75" y="0.75" width="14.5" height="14.5" rx="7.25" stroke="#2AABFF" strokeWidth="1.5" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.57585 5.2813C8.90719 5.2813 9.19052 5.17196 9.42585 4.9533C9.66119 4.73463 9.77919 4.4693 9.77919 4.15796C9.77919 3.84796 9.66119 3.5813 9.42585 3.36063C9.19119 3.1393 8.90719 3.0293 8.57585 3.0293C8.24519 3.0293 7.95985 3.1393 7.72252 3.36063C7.48719 3.5813 7.36719 3.84796 7.36719 4.15796C7.36719 4.46863 7.48652 4.73463 7.72252 4.9533C7.95985 5.17196 8.24519 5.2813 8.57585 5.2813ZM8.54386 12.5714C8.78586 12.4874 9.10853 12.3661 9.51386 12.2061L9.64919 11.6548C9.57853 11.6874 9.46653 11.7254 9.31186 11.7681C9.15586 11.8108 9.01786 11.8328 8.89453 11.8328C8.63319 11.8328 8.44853 11.7894 8.34319 11.7034C8.23653 11.6174 8.18386 11.4554 8.18386 11.2181C8.18386 11.1241 8.19986 10.9841 8.23253 10.8008C8.26586 10.6174 8.30253 10.4534 8.34386 10.3101L8.84853 8.52345C8.89786 8.35878 8.93186 8.17945 8.94986 7.98278C8.96786 7.78678 8.97719 7.64945 8.97719 7.57145C8.97719 7.19545 8.84453 6.88945 8.58119 6.65411C8.31653 6.41945 7.94053 6.30078 7.45319 6.30078C7.18319 6.30078 6.89653 6.34878 6.59319 6.44611C6.28986 6.54211 5.97186 6.65811 5.63986 6.79345L5.50586 7.34545C5.60319 7.30811 5.72186 7.27011 5.85853 7.22811C5.99653 7.18745 6.13119 7.16811 6.26186 7.16811C6.52919 7.16811 6.70853 7.21211 6.80386 7.30211C6.89786 7.39211 6.94519 7.55211 6.94519 7.78011C6.94519 7.90811 6.93053 8.04878 6.89986 8.20211C6.86919 8.35411 6.83053 8.51745 6.78586 8.68945L6.27853 10.4814C6.23386 10.6701 6.20119 10.8381 6.18053 10.9874C6.16053 11.1368 6.15053 11.2834 6.15053 11.4268C6.15053 11.7954 6.28586 12.0988 6.55786 12.3381C6.82986 12.5774 7.21186 12.6974 7.70253 12.6974C8.02186 12.6974 8.30253 12.6554 8.54386 12.5714Z"
                fill="#2AABFF"
            />
        </svg>
    );

    const ERROR_ICON = (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g clipPath="url(#clip0_3301_11283)">
                <path
                    d="M8 14.4C4.472 14.4 1.6 11.528 1.6 8C1.6 4.472 4.472 1.6 8 1.6C11.528 1.6 14.4 4.472 14.4 8C14.4 11.528 11.528 14.4 8 14.4ZM8 0C3.576 0 0 3.576 0 8C0 12.424 3.576 16 8 16C12.424 16 16 12.424 16 8C16 3.576 12.424 0 8 0ZM10.072 4.8L8 6.872L5.928 4.8L4.8 5.928L6.872 8L4.8 10.072L5.928 11.2L8 9.128L10.072 11.2L11.2 10.072L9.128 8L11.2 5.928L10.072 4.8Z"
                    fill="#FF0000"
                />
            </g>
            <defs>
                <clipPath id="clip0_3301_11283">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );

    const SUCCESS_ICON = (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM8 14.4C4.472 14.4 1.6 11.528 1.6 8C1.6 4.472 4.472 1.6 8 1.6C11.528 1.6 14.4 4.472 14.4 8C14.4 11.528 11.528 14.4 8 14.4ZM11.672 4.464L6.4 9.736L4.328 7.672L3.2 8.8L6.4 12L12.8 5.6L11.672 4.464Z"
                fill="#00A167"
            />
        </svg>
    );

    const ICONS: Record<AlertTypes, JSX.Element> = {
        warning: WARNING_ICON,
        info: INFO_ICON,
        error: ERROR_ICON,
        success: SUCCESS_ICON
    };

    return (
        <div className={`alert ${type}`}>
            <div className="alert_icon">{ICONS[type]}</div>
            <div className="alert_text">
                <span>{text}</span>
            </div>
        </div>
    );
};

export default memo(Alert, (prevProps, nextProps): boolean => {
    if (prevProps.text === nextProps.text) {
        return true;
    } else {
        return false;
    }
});
