import $api from "shared/api/api";
import external_api from "shared/api/external_api";
import { IProject } from "shared/config/interface";
import { IAwsS3Presigned, ICreateProjectPayload } from "../model/types/interface";
import { API_TAGS } from "shared/config/config";

export const createProjectApiExternal = external_api.injectEndpoints({
    endpoints: (builder) => ({
        createProject: builder.mutation<IProject, ICreateProjectPayload>({
            query: (project) => ({
                url: `/projects`,
                method: "POST",
                body: project
            }),
            invalidatesTags: [API_TAGS.Project_CVAT]
        }),
        deleteProject: builder.mutation<IProject, number>({
            query: (id) => ({
                url: `/projects/${id}`,
                method: "DELETE"
            })
        })
    })
});

export const createProjectApi = $api.injectEndpoints({
    endpoints: (builder) => ({
        awsS3Presigned: builder.mutation<string, IAwsS3Presigned>({
            query: ({ method, object_key }) => ({
                url: `/external-api/aws-s3-presigned/`,
                method: "POST",
                body: {
                    method,
                    object_key
                }
            })
        })
    })
});

export const { useCreateProjectMutation, useDeleteProjectMutation } = createProjectApiExternal;
export const { useAwsS3PresignedMutation } = createProjectApi;
