import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DashboardProjectType } from "../types/interface";
import { dashboardApi, dashboardExternalApi } from "../../api/dashboard-api";
import { IResponse } from "shared/config/interface";

interface IDashboard {
    loading: boolean;
    errors: {
        cvat_projects: any;
        projects_history: any;
    };
    cvat_projects: DashboardProjectType[];
    projects_history: DashboardProjectType[];
    projects: DashboardProjectType[];
}

const INITIAL_STATE: IDashboard = {
    loading: false,
    errors: {
        cvat_projects: null,
        projects_history: null
    },
    cvat_projects: [],
    projects_history: [],
    projects: []
};

const dashboardSlice = createSlice({
    name: "dashboard_slice",
    initialState: INITIAL_STATE,
    reducers: {
        setLoading(state) {
            state.loading = !state.loading;
        },
        setError(state) {
            state.loading = false;
        },
        setCVATProjects(state, action: PayloadAction<DashboardProjectType[]>) {
            state.cvat_projects = action.payload;
        },
        setProjectsHistory(state, action: PayloadAction<DashboardProjectType[]>) {
            state.projects_history = action.payload;
        },
        setProjects(state, action: PayloadAction<DashboardProjectType[]>) {
            state.projects = action.payload;
        },
        clearDashboard(state) {
            state.cvat_projects = [];
            state.projects_history = [];
        }
    },
    extraReducers(builder) {
        builder.addMatcher(dashboardExternalApi.endpoints.getProjects.matchPending, (state, action) => {
            state.loading = true;
        });
        builder.addMatcher(dashboardExternalApi.endpoints.getProjects.matchFulfilled, (state, action: PayloadAction<IResponse<DashboardProjectType>>) => {
            state.cvat_projects = action.payload.results || [];
            state.loading = false;
        });
        builder.addMatcher(dashboardExternalApi.endpoints.getProjects.matchRejected, (state, action) => {
            state.errors.cvat_projects = action.payload;
            state.loading = false;
        });
        builder.addMatcher(dashboardApi.endpoints.projectsHistory.matchPending, (state, action) => {
            state.loading = true;
        });
        builder.addMatcher(dashboardApi.endpoints.projectsHistory.matchFulfilled, (state, action: PayloadAction<IResponse<DashboardProjectType>>) => {
            state.projects_history = action.payload.results || [];
            state.loading = false;
        });
        builder.addMatcher(dashboardApi.endpoints.projectsHistory.matchRejected, (state, action) => {
            state.loading = false;
            state.errors.projects_history = action.payload;
        });
    }
});

export const { clearDashboard } = dashboardSlice.actions;
export default dashboardSlice.reducer;
