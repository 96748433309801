import { useEffect } from "react";

export const useConfirmRefresh = (): void => {
    useEffect(() => {
        window.addEventListener("beforeunload", refresh);

        return () => {
            window.removeEventListener("beforeunload", refresh);
        };
    }, []);

    const refresh = (e: any): void => {
        e.preventDefault();
        e.returnValue = "";
    };
};
