import { ICheckUserName, ErrorAuth, ISignup, ApiErrorResponse, ILogin, PasswordsType, EmailType } from "./model/types/types";
import {
    useLazyCheckUserNameQuery,
    useRegistrationMutation,
    useLoginMutation,
    useResetPasswordConfirmMutation,
    useResetPasswordMutation,
    useResendActivationMutation
} from "./model/api/authorization-api";
import { useCheckActivationToken } from "./model/lib/useCheckActivationTokens/useCheckActivationTokens";
import { useLogin } from "./model/lib/useLogin/useLogin";

export {
    useLazyCheckUserNameQuery,
    useRegistrationMutation,
    useCheckActivationToken,
    useLogin,
    useLoginMutation,
    useResetPasswordConfirmMutation,
    useResetPasswordMutation,
    useResendActivationMutation
};

export type { ICheckUserName, ErrorAuth, ISignup, ApiErrorResponse, ILogin, PasswordsType, EmailType };
