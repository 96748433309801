import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "app/store-config";
import { AnnotationTools, ICalculatorData, IPrice, InstrumentsEnum, TypeFiles } from "../types/types";
import { TO_FIXED_VALUE } from "../types/config";

export const annotation_tool_selector = (state: RootState): AnnotationTools => state.calculator.annotationTool;
export const current_price_key_selector = (state: RootState): InstrumentsEnum => state.calculator.current_price_key;
export const get_approximate_price_selector = (state: RootState): number => state.calculator.approximate_price;
export const active_type_selector = (state: RootState): TypeFiles => state.calculator.activeType;
export const current_price_element_selector = (state: RootState): IPrice => state.calculator.currentPriceElement;
export const images_count_selector = (state: RootState): number => state.calculator.imagesCount;
export const labels_count_selector = (state: RootState): number => state.calculator.labelsCount;
export const attributes_count_selector = (state: RootState): number => state.calculator.attributesCount;
export const average_annotations_per_frame_count_selector = (state: RootState): number => state.calculator.averageAnnotationsPerFrameCount;
const prices_selector = (state: RootState): ICalculatorData => state.calculator.prices;

export const sums_and_prices_selector = createSelector(
    [current_price_element_selector, images_count_selector, labels_count_selector, attributes_count_selector, average_annotations_per_frame_count_selector, prices_selector],
    (currentPriceElement, imagesCount, labelsCount, attributesCount, averageAnnotationsPerFrameCount, prices) => {
        if (!prices || !currentPriceElement)
            return {
                ImageSum: 0,
                LabelsSum: 0,
                AttributesSum: 0,
                ObjectsPerImageSum: 0,
                imagePrice: 0,
                labelPrice: 0,
                attributePrice: 0,
                ObjectsPerImagePrice: 0,
                TotalAllSum: 0,
                AttributesSumWithoutImage: 0
            };
        let imagePrice = currentPriceElement.base_cost;
        let labelPrice = labelsCount >= prices.up_to_limit ? currentPriceElement.global_label : currentPriceElement.global_label_up_to;
        let attributePrice = attributesCount >= prices.up_to_limit ? currentPriceElement.global_attribute : currentPriceElement.global_attribute_up_to;
        let ObjectsPerImagePrice = currentPriceElement.annotation_attribute + currentPriceElement.annotation_label;

        let ImageSum: number = +(imagePrice * imagesCount).toFixed(TO_FIXED_VALUE);
        let LabelsSum: number = +(labelPrice * imagesCount).toFixed(TO_FIXED_VALUE);
        let AttributesSum: number = +(attributePrice * attributesCount * imagesCount).toFixed(TO_FIXED_VALUE);
        let ObjectsPerImageSum: number = +((AttributesSum + LabelsSum + ImageSum) * averageAnnotationsPerFrameCount).toFixed(TO_FIXED_VALUE);
        let TotalAllSum = (ImageSum + LabelsSum + AttributesSum) * averageAnnotationsPerFrameCount;

        let AttributesSumWithoutImage: number = +(attributePrice * attributesCount).toFixed(TO_FIXED_VALUE);

        return {
            ImageSum,
            LabelsSum,
            AttributesSum,
            ObjectsPerImageSum,
            imagePrice,
            labelPrice,
            attributePrice,
            ObjectsPerImagePrice,
            TotalAllSum,
            AttributesSumWithoutImage
        };
    }
);
