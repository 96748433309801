import { NonOAuthError, TokenResponse, useGoogleLogin } from "@react-oauth/google";
import { useGoogleLoginMutation } from "../api/useGoogleLogin-api";
import { ITokens } from "shared/config/interface";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import { useNotification } from "shared/lib/hooks";

interface IResponseUseGoogleAuth {
    google_login: () => void;
    isLoading: boolean;
    isSuccess: boolean;
    data: ITokens | undefined;
    error: FetchBaseQueryError | SerializedError | any;
}

const useGoogleAuth = (): IResponseUseGoogleAuth => {
    const [googleLoginAsync, { isLoading, isSuccess, data, error }] = useGoogleLoginMutation();
    const notification = useNotification();

    const google_login = useGoogleLogin({
        onSuccess: (tokenResponse: Omit<TokenResponse, "error" | "error_description" | "error_uri">): void => {
            const { access_token } = tokenResponse;

            if (access_token) {
                googleLoginAsync({ access_token });
            }
        },
        onError: (errorResponse: Pick<TokenResponse, "error" | "error_description" | "error_uri">): void => {
            notification.error({
                message: errorResponse.error_description as string,
                duration: 6
            });
        },
        onNonOAuthError: (nonOAuthError: NonOAuthError): void => {
            notification.error({
                message: nonOAuthError.type,
                duration: 6
            });
        }
    });

    return {
        google_login,
        data,
        isLoading,
        isSuccess,
        error
    };
};

export default useGoogleAuth;
