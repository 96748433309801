import { useCallback, useEffect, useMemo } from "react";
import { ImportCloudCredentialType, useImportCloudDataMutation } from "entities/cloud-storage";
import { useUploadTasksDataMutation } from "entities/task";
import { MIXPANEL_TRACK_KEYS } from "shared/config/interface";
import { useNotification } from "shared/lib/hooks";
import { Mixpanel } from "shared/lib/services/mixpanel";

interface IUploadPayload {
    id: number;
    files?: FormData;
    credential?: ImportCloudCredentialType;
}

type IUseUploadFilesToTask = [upload: (params: IUploadPayload) => Promise<void>, isLoading: boolean];

const useUploadFilesToTask = ({ project_id }: { project_id: number }): IUseUploadFilesToTask => {
    const notification = useNotification();

    const [upload_data_mutation, { isLoading, fulfilledTimeStamp, startedTimeStamp }] = useUploadTasksDataMutation();
    const [importCloudDataMutation, { isLoading: importCloudDataIsLoading, error: importCloudDataError, fulfilledTimeStamp: fulfilledTimeStampImport, startedTimeStamp: startedTimeStampImport }] =
        useImportCloudDataMutation();

    const saveUploadFilesTime = useCallback(
        (start: number | undefined, end: number | undefined) => {
            if (start && end) {
                const differenceInMilliseconds = end - start;
                const differenceInSeconds = differenceInMilliseconds / 1000;

                Mixpanel.track(MIXPANEL_TRACK_KEYS.CREATE_TASK_UPLOAD_FILES_TIME, {
                    upload_time: `${differenceInSeconds} sec`,
                    project_id
                });
            }
        },
        [project_id]
    );

    useEffect(() => {
        if (importCloudDataError) {
            notification.error({
                message: JSON.stringify(importCloudDataError),
                duration: 6
            });
        }
    }, [importCloudDataError, notification]);

    useEffect(() => {
        if (fulfilledTimeStamp && startedTimeStamp) {
            saveUploadFilesTime(startedTimeStamp, fulfilledTimeStamp);
        }
    }, [startedTimeStamp, fulfilledTimeStamp, saveUploadFilesTime]);

    useEffect(() => {
        if (fulfilledTimeStampImport && startedTimeStampImport) {
            saveUploadFilesTime(startedTimeStampImport, fulfilledTimeStampImport);
        }
    }, [startedTimeStampImport, fulfilledTimeStampImport, saveUploadFilesTime]);

    const upload = useCallback(
        async ({ id, files, credential }: IUploadPayload): Promise<void> => {
            try {
                if (files) {
                    await upload_data_mutation({
                        files,
                        id
                    });
                } else if (credential) {
                    await importCloudDataMutation({
                        credential,
                        id
                    });
                }
            } catch (error) {
                notification.error({
                    message: JSON.stringify(error)
                });
            }
        },
        [upload_data_mutation, importCloudDataMutation, notification]
    );

    const uploadLoading = useMemo(() => isLoading || importCloudDataIsLoading, [isLoading, importCloudDataIsLoading]);

    return [upload, uploadLoading];
};

export default useUploadFilesToTask;
