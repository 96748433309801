import { useAppSelector } from "shared/lib/hooks";
import { accountApiSlice as account_api } from "../model/api/api";
import { user_selector } from "../model/selectors/user-selector";
import { useEffect } from "react";
import { IClientException } from "../model/types/types";
import { detect_browser } from "shared/lib/helpers";
import { ERROR } from "shared/lib/helpers/images/images";
import { Button } from "shared/ui";
import { useNavigate } from "react-router-dom";
import { Mixpanel } from "shared/lib/services/mixpanel";
import { MIXPANEL_TRACK_KEYS } from "shared/config/interface";
import { IS_PRODUCTION, IS_REACT_APP_DEV } from "shared/config/config";

const KEY = "logsLastCalledTime";
const TIME = 900000; // 900000 ms = 15 min

interface IProps {
    message: string;
    state_error: string;
    componentStack: string;
}

const ErrorBoundaryContent = ({ message, state_error, componentStack }: IProps): JSX.Element => {
    const [logs] = account_api.useCaptureClientExceptionMutation();
    const user = useAppSelector(user_selector);
    const navigate = useNavigate();

    useEffect(() => {
        if (!IS_PRODUCTION) return;

        let lastCalledTime: any = localStorage.getItem(KEY);

        const error_data: IClientException = {
            user_agent: navigator.userAgent,
            platform: detect_browser(),
            language: navigator.language,
            location_pathname: window.location.pathname,
            message: message,
            stack: componentStack.slice(0, 160),
            time_stamp: new Date().toLocaleString(),
            screen: `${window.screen.width}*${window.screen.height}-${window.innerWidth}*${window.innerHeight}`
        };

        if (user) {
            error_data.user_id = user.id;
        }
        Mixpanel.track(MIXPANEL_TRACK_KEYS.ERROR_PAGE_VIEW, error_data);

        if (lastCalledTime) {
            lastCalledTime = parseInt(lastCalledTime);
            const elapsedTime = Date.now() - lastCalledTime;
            if (elapsedTime >= TIME) {
                // 15 minutes have passed since the last call to the logs function.
                if (IS_PRODUCTION) {
                    logs(error_data);
                }
                localStorage.setItem(KEY, Date.now().toString()); // Update last call time
            }
        } else {
            // If the logs function has never been called, call it and set the initial time
            if (IS_PRODUCTION) {
                logs(error_data);
            }
            localStorage.setItem(KEY, Date.now().toString());
        }
    }, []);

    function onReturn(): void {
        navigate("/");
        window.location.reload();
    }

    return (
        <div className="errorboundary">
            <img className="errorboundary_error" src={ERROR} alt="error" />
            <h1>Page not found</h1>
            <h2>Something went wrong at our end. Don't worry, it's not you - it's us. Sorry about that.</h2>
            <Button onClick={onReturn}>Return to dashboard</Button>
            {IS_REACT_APP_DEV && (
                <>
                    <code style={{ textAlign: "center" }}>{message}</code>
                    <br />
                    <code style={{ textAlign: "center" }}>{state_error && state_error.toString()}</code>
                    <br />
                    <code style={{ textAlign: "center" }}>{componentStack.slice(0, 160)}</code>
                    <details style={{ whiteSpace: "pre-wrap" }}>
                        <code>{state_error && state_error.toString()}</code>
                        <br />
                        {componentStack}
                    </details>
                </>
            )}
        </div>
    );
};

export default ErrorBoundaryContent;
