type ErrorAuth = {
    [key: string]: string[];
};

export function show_error(isError: boolean, errors: ErrorAuth, notification: any): void {
    let er: ErrorAuth = errors;
    if (isError && er.hasOwnProperty("status")) {
        Object.keys(er?.data).map((el: any) =>
            notification.error({
                message: `${el.toUpperCase()} - ${er.data[el]}`,
                duration: 12
            })
        );
    }
}
