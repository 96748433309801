import { ADD_WHITE_ICON } from "shared/lib/helpers/images/images";
import { Button } from "shared/ui";
import { memo, useState } from "react";
import "./organization-members-top-panel.scss";
import OrganizationMembersInviteNewMembers from "../organization-members-invite/organization-members-invite";
import { useIsOwnerCurrentOrganization } from "entities/account";

const OrganizationMembersTopPanel = ({ count }: { count: number }): JSX.Element => {
    const { is_owner } = useIsOwnerCurrentOrganization();
    const [visible, setVisible] = useState<boolean>(false);

    function invite_new_members(): void {
        setVisible(true);
    }

    return (
        <div className="organization_members_top">
            <span className="organization_members_top_members">
                {" "}
                Members: <b>{count + 1}</b>
            </span>
            <Button disabled={!is_owner} onClick={invite_new_members} icon={<ADD_WHITE_ICON />}>
                Invite new member
            </Button>
            {visible && <OrganizationMembersInviteNewMembers visible={visible} onClose={() => setVisible(false)} />}
        </div>
    );
};

export default memo(OrganizationMembersTopPanel);
