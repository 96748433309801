import { ITask } from "shared/config/interface";

export class TaskService {
    task: ITask = {} as ITask;

    constructor(task: ITask) {
        this.task = task;
    }

    one_frame_sum(): number {
        return this.task.cost / this.task.progress_bar.progress_frames;
    }

    other_frame_sum(): number {
        let {
            cost,
            budget,
            progress_bar: { progress_frames, total_frames }
        } = this.task;

        return cost - budget + (total_frames - progress_frames) * this.one_frame_sum();
    }

    check_balance_for_add_budget(available_balance: number): boolean {
        return available_balance > Number(this.task.cost) - Number(this.task.budget);
    }
}
