import React from "react";
import "./input.scss";

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement>, React.AriaAttributes {}

const Input = React.forwardRef<HTMLInputElement, IInputProps>((props, ref): JSX.Element => {
    let { className, ...rest } = props;

    let classes = ["custom_input"];

    if (className) {
        classes.push(className);
    }

    return <input data-testid="input" ref={ref} className={classes.join(" ")} {...rest} />;
});

export default Input;
