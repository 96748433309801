import { useAppDispatch, useAppSelector, useNotification } from "shared/lib/hooks";
import { FC, useEffect } from "react";
import { user_selector } from "../model/selectors/user-selector";
import { accountApiSlice } from "../model/api/api";
import { set_is_auth, set_user, logout as logout_reducer } from "../model/slice/account-slice";
import { is_auth_selector } from "../model/selectors/is-auth-selector";
import { Loader } from "shared/ui";
import ChooseOrganization from "./choose-organization";
import { setInitatialValues } from "entities/onboarding";
import { Mixpanel } from "shared/lib/services/mixpanel";
import HubSpot from "shared/lib/services/hubspot";

type IProps = {
    children: React.ReactNode;
};

const MeProvider: FC<IProps> = ({ children }): JSX.Element => {
    const is_auth = useAppSelector(is_auth_selector);
    const user = useAppSelector(user_selector);
    const [me, { isFetching, isLoading, data: dataMe, isSuccess, isError, error: ErrorMe }] = accountApiSlice.useLazyMeQuery();
    const [getBalance, { error: balanceError }] = accountApiSlice.useLazyBalanceQuery();
    const notification = useNotification();

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!isError) return;

        notification.error({
            duration: 5,
            message: JSON.stringify(ErrorMe)
        });
        dispatch(logout_reducer());
        dispatch(set_is_auth(false));
    }, [isError]);

    useEffect(() => {
        if (user.onboarding && Boolean(user.onboarding.length > 0)) {
            dispatch(setInitatialValues(user.onboarding));
        }
    }, [user]);

    useEffect(() => {
        if (balanceError) {
            let err: any = balanceError;
            notification.error({
                duration: 10,
                message: JSON.stringify(err?.data?.detail)
            });
        }
    }, [balanceError]);

    useEffect(() => {
        const get_me = async (): Promise<void> => {
            if (is_auth === true && Boolean("id" in user) === false) {
                try {
                    const user = await me().unwrap();
                    dispatch(set_user(user));
                    Mixpanel.set_user(user);
                    Mixpanel.identify();
                    HubSpot.unloadHubSpotScript();
                    if (Boolean(user?.organization_current)) {
                        getBalance(user.organization_current.id);
                    }
                } catch (error) {
                    dispatch(logout_reducer());
                    dispatch(set_is_auth(false));
                }
            }
        };

        get_me();
    }, [is_auth, me, dispatch, user, getBalance]);

    if ((isLoading || isFetching) && !dataMe) return <Loader />;

    if (isSuccess && !Boolean(user.organization_current) && is_auth) {
        return <ChooseOrganization language={dataMe?.language!} userID={dataMe?.id!} organizationList={dataMe?.organizations!} />;
    }

    return <>{children}</>;
};

export default MeProvider;
