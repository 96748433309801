import { useEffect, useState } from "react";
import "./pagination.scss";

interface Props {
    defaultCurrent?: number;
    total: number;
    current: number;
    onChange: (page: number) => void;
    pageSize?: number; // default 10
}

const Pagination = ({ total, onChange, current }: Props): JSX.Element | null => {
    const [pageSize] = useState<number>(10);
    const [currentStep, setCurrentStep] = useState<number>(() => current);
    const [pages, setPages] = useState<number[]>([]);
    let countStep = Math.ceil(total / pageSize);

    useEffect(() => {
        createPages();
    }, [currentStep, countStep]);

    const createPages = (): void => {
        let arr = [];

        if (countStep > 10) {
            if (currentStep > 5) {
                for (let i = currentStep - 4; i < currentStep + 5; i++) {
                    arr.push(i);
                    if (i === countStep) break;
                }
            } else {
                for (let i = 1; i <= 10; i++) {
                    arr.push(i);
                    if (i === countStep) break;
                }
            }
        } else {
            for (let i = 1; i <= countStep; i++) {
                arr.push(i);
            }
        }

        setPages([...arr]);
    };

    const onChangeStep = (page: number): void => {
        if (page === currentStep) return;
        createPages();
        setCurrentStep(page);
        onChange(page);
    };

    const onChangeArrowStep = (page: number): void => {
        if (page > Math.ceil(total / pageSize)) return;
        if (page <= 0) return;
        if (page === currentStep) return;

        createPages();
        onChange(page);
        setCurrentStep(page);
    };

    if (total < pageSize) return null;

    return (
        <div className="pagination">
            <div className="block" />
            <div data-testid="dual_prev" onClick={() => onChangeArrowStep(1)} className="dual_prev step_btn">{`<<`}</div>
            <div data-testid="once_prev" onClick={() => onChangeArrowStep(currentStep - 1)} className="once_prev step_btn">{`<`}</div>
            {pages.map((page) => (
                <div data-testid={"page"} key={page} onClick={() => onChangeStep(page)} className={`step_item step_btn ${currentStep === page ? "active" : ""}`}>
                    {page}
                </div>
            ))}
            {/* {currentStep < countStep && <div className="step_item  step_btn total" onClick={() => onChangeArrowStep(countStep)}>{countStep}</div>} */}
            <div data-testid="once_next" onClick={() => onChangeArrowStep(currentStep + 1)} className="once_next step_btn">{`>`}</div>
            <div data-testid="dual_next" onClick={() => onChangeArrowStep(Math.ceil(total / pageSize))} className="dual_next step_btn">{`>>`}</div>
            <div className="block" />
        </div>
    );
};

export default Pagination;
