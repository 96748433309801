import external_api from "shared/api/external_api";
import $api from "shared/api/api";
import { DashboardProjectType } from "../model/types/interface";
import { IResponse } from "shared/config/interface";
import { API_ENDPOINTS, API_TAGS, EXTERNAL_API_PLATFORM_TYPE } from "shared/config/config";

export const dashboardExternalApi = external_api.injectEndpoints({
    endpoints: (builder) => ({
        getProjects: builder.query<IResponse<DashboardProjectType>, string>({
            query: (organization) => ({
                url: `/projects?page_size=30&page=1&${EXTERNAL_API_PLATFORM_TYPE}&organization=${organization}`,
                method: "GET",
                tags: [API_TAGS.Project_CVAT]
            })
        })
    })
});

export const dashboardApi = $api.injectEndpoints({
    endpoints: (builder) => ({
        projectsHistory: builder.query<IResponse<DashboardProjectType>, string>({
            query: (organization) => ({
                url: `${API_ENDPOINTS.projects_history}?organization=${organization}`,
                method: "GET",
                tags: [API_TAGS.Project]
            })
        })
    })
});

export const { useLazyProjectsHistoryQuery } = dashboardApi;
export const { useLazyGetProjectsQuery } = dashboardExternalApi;
