import { Button } from "shared/ui";

type RateActionsProps = {
    onSubmit: () => void;
    onSkip: () => void;
    rate: number;
    isLoading: boolean;
};

const FeedbackActions = ({ onSubmit, onSkip, rate, isLoading }: RateActionsProps): JSX.Element => {
    return (
        <div className="rate_btn">
            <Button loading={isLoading} disabled={isLoading} onClick={onSkip} size="small" typeStyle="ghost">
                Skip
            </Button>
            <Button loading={isLoading} onClick={onSubmit} disabled={rate === 0 || isLoading} size="small">
                Submit
            </Button>
        </div>
    );
};

export default FeedbackActions;
