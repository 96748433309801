import external_api from "shared/api/external_api";
import $api from "shared/api/api";
import { EmailType, IAuthorizationTokens, IRegistrationData, IResetPasswordConfirm, ISignInPayload, RegistrationPayload } from "../types/types";
import { ITokens } from "shared/config/interface";
import { API_ENDPOINTS } from "shared/config/config";

export const authorizationApi = {
    ...$api.injectEndpoints({
        endpoints: (builder) => ({
            registration: builder.mutation<IRegistrationData, RegistrationPayload>({
                query: (user) => ({
                    url: `${API_ENDPOINTS.authentication}user/`,
                    method: "POST",
                    body: user
                })
            }),
            login: builder.mutation<ITokens, ISignInPayload>({
                query: ({ user }) => ({
                    url: `${API_ENDPOINTS.authentication}login/`,
                    method: "POST",
                    body: user
                })
            }),
            resetPassword: builder.mutation<any, EmailType>({
                query: ({ email }) => ({
                    url: `${API_ENDPOINTS.authentication}user/reset_password/`,
                    method: "POST",
                    body: { email }
                })
            }),
            resetPasswordConfirm: builder.mutation<any, IResetPasswordConfirm>({
                query: (data) => ({
                    url: `${API_ENDPOINTS.authentication}user/reset_password_confirm/`,
                    method: "POST",
                    body: data
                })
            }),
            resendActivation: builder.mutation<any, EmailType>({
                query: ({ email }) => ({
                    url: `${API_ENDPOINTS.authentication}user/resend_activation/`,
                    method: "POST",
                    body: { email }
                })
            }),
            activation: builder.mutation<any, IAuthorizationTokens>({
                query: (tokens) => ({
                    url: `${API_ENDPOINTS.authentication}user/activation/`,
                    method: "POST",
                    body: tokens
                })
            })
        })
    }),
    ...external_api.injectEndpoints({
        endpoints: (builder) => ({
            checkUserName: builder.query<any, string>({
                query: (user_name) => ({
                    url: `/auth/check-username?username=${user_name}`,
                    method: "GET"
                })
            })
        })
    })
};

export const { useRegistrationMutation, useLazyCheckUserNameQuery, useLoginMutation, useResetPasswordMutation, useResetPasswordConfirmMutation, useResendActivationMutation, useActivationMutation } =
    authorizationApi;
