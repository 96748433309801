import Select from "react-select";
import { CLOUD_STORAGE_PROVIDERS_OPTIONS, ICloudOption } from "entities/cloud-storage";
import { customStylesSelect } from "shared/config/config";
import { AZURE_ICON, GOOGLE_CLOUD_ICON, AWS_ICON } from "shared/lib/helpers/images/images";
import { CloudStorageSelectCustomOption, CloudStorageSelectCustomSingleValue } from "../cloud-storage-select-option/cloud-storage-select-option";

const CloudStorageProviders = ({ value, onChange }: { value: ICloudOption | null; onChange: (value: ICloudOption | null) => void }): JSX.Element => {
    return (
        <Select
            styles={customStylesSelect}
            value={value}
            onChange={onChange}
            options={CLOUD_STORAGE_PROVIDERS_OPTIONS({ aws: <AWS_ICON />, azure: <AZURE_ICON />, google: <GOOGLE_CLOUD_ICON /> })}
            placeholder="Choose provider"
            components={{ Option: CloudStorageSelectCustomOption, SingleValue: CloudStorageSelectCustomSingleValue }}
        />
    );
};

export default CloudStorageProviders;
