import { useWindowSize } from "shared/lib/hooks";
import { MIN_WIDTH } from "shared/config/config";
import "./form-item.scss";

interface IFormItemProps {
    label: string;
    error: string | undefined;
    children: React.ReactNode | JSX.Element;
    hint?: React.ReactNode | null;
}
const FormItem = ({ label, children, error, hint }: IFormItemProps): JSX.Element => {
    const { width } = useWindowSize();

    return (
        <div className="form_item">
            <span className="form_item_label">
                {label}
                {width > MIN_WIDTH && hint}
            </span>
            {children}
            {error && (
                <span data-testid="form_item_error" className="form_item_error">
                    {error || `${label} validation`}
                </span>
            )}
        </div>
    );
};

export default FormItem;
