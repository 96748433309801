/* eslint-disable react/no-array-index-key */
import { FEEDBACK_STEPS } from "entities/account";
import { descriptions, stars, step_descriptions } from "./data";
import { RATE_STAR } from "shared/lib/helpers/images/images";

type StarsProps = {
    onRate: (index: number) => void;
    rate: number;
    step: FEEDBACK_STEPS;
};

const FeedbackStars = ({ onRate, rate, step }: StarsProps): JSX.Element => {
    return (
        <>
            <p className="rate_description">
                We would love to hear from you! How was your experience with <b>{descriptions[FEEDBACK_STEPS[step]]}</b>?
            </p>
            <div className="rate_stars">
                {stars.map((_, index) => (
                    <span onClick={() => onRate(index)} className={index + 1 <= rate && rate !== 0 ? "filled" : "standart"} key={index + 1}>
                        <RATE_STAR />
                    </span>
                ))}
            </div>
            <small className="rate_placeholder">{step_descriptions[rate]}</small>
        </>
    );
};

export default FeedbackStars;
