import { memo, useMemo } from "react";
import { GlobalStatuses } from "shared/config/interface";
import "./status.scss";

type StatusTypes = "success" | "waiting" | "error" | "progress" | "info";
type StatusTitles = "Completed" | "Waiting" | "Error" | "In progress" | "Pending review" | "Unknown status";

interface IStatusProps {
    type: GlobalStatuses;
    title?: string;
}

const COMPLETED_STATUSES = [GlobalStatuses.COMPLETED, GlobalStatuses.COST, GlobalStatuses.PAID];
const PRIMARY_STATUSES = [GlobalStatuses.PENDING, GlobalStatuses.WAITING];
const ERROR_STATUSES = [GlobalStatuses.CANCELLED, GlobalStatuses.FAILED];
const IN_PROGRESS_STATUSES = [GlobalStatuses.ANNOTATION];
const INFO_STATUSES = [GlobalStatuses.VALIDATION];

const Status = ({ type, title = "" }: IStatusProps): JSX.Element => {
    const statusStyle = useMemo((): StatusTypes => {
        if (COMPLETED_STATUSES.includes(type)) {
            return "success";
        }
        if (PRIMARY_STATUSES.includes(type)) {
            return "waiting";
        }
        if (ERROR_STATUSES.includes(type)) {
            return "error";
        }
        if (IN_PROGRESS_STATUSES.includes(type)) {
            return "progress";
        }
        if (INFO_STATUSES.includes(type)) {
            return "info";
        }
        return "error";
    }, [type]);

    const statusTitle = useMemo((): StatusTitles | typeof type | string => {
        if (title) return title;

        if (COMPLETED_STATUSES.includes(type)) {
            return "Completed";
        }
        if (PRIMARY_STATUSES.includes(type)) {
            return "Waiting";
        }
        if (ERROR_STATUSES.includes(type)) {
            return "Error";
        }
        if (IN_PROGRESS_STATUSES.includes(type)) {
            return "In progress";
        }
        if (INFO_STATUSES.includes(type)) {
            return "Pending review";
        }
        return type;
    }, [type]);

    return (
        <span data-testid="status" className={`status ${statusStyle}`}>
            {statusTitle}
        </span>
    );
};

export default memo(Status);
