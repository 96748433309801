import { useState } from "react";
import { HELP_ICON } from "shared/lib/helpers/images/images";
import "./tooltip.scss";

interface IProps {
    text: string | React.ReactNode;
    children?: React.ReactNode;
    // node?: React.ReactNode;
}

const Tooltip: React.FC<IProps> = ({ text, children }): JSX.Element => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const icon = children ? children : <HELP_ICON className="tooltip_icon" />;

    if (typeof text !== "string") {
        return (
            <div onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)} className="tooltip">
                {icon}
                {isVisible && (
                    <div className="tooltip_text" style={{ minWidth: 300 }}>
                        {text}
                    </div>
                )}
            </div>
        );
    }

    if (typeof text === "string" && text.length === 0) return <>{children}</>;

    const calculateMinWidth = (): number => {
        const default_width = text.length * 3;

        if (default_width < 120) {
            return 100;
        }

        return default_width;
    };

    return (
        <div onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)} className="tooltip">
            {icon}
            {isVisible && (
                <div className="tooltip_text" style={{ minWidth: calculateMinWidth() }}>
                    {text}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
