import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { Button, FormItem, Input, Modal, Divider } from "shared/ui";
import { rules } from "shared/lib/helpers";
import "./organization-members-invite.scss";
import { ADD_SMALL_ICON, BUSKET_ICON } from "shared/lib/helpers/images/images";
import { account_api, account_organization_selector } from "entities/account";
import { useAppSelector, useNotification } from "shared/lib/hooks";
import Alert from "shared/ui/alert/alert";
import { useEffect } from "react";

interface IFormInput {
    emails: { email: string }[];
}

const OrganizationMembersInviteNewMembers = ({ visible, onClose }: { visible: boolean; onClose: () => void }): JSX.Element => {
    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<IFormInput>({
        mode: "onChange",
        defaultValues: {
            emails: [{ email: "" }]
        }
    });

    const { id } = useAppSelector(account_organization_selector);
    const [addCollaborators, { isLoading, data, isSuccess, error: errorsData }] = account_api.useAddCollaboratorsByEmailMutation();
    const notification = useNotification();

    const { fields, append, remove } = useFieldArray({
        control,
        name: "emails"
    });

    useEffect(() => {
        if (data && isSuccess) {
            reset({ emails: [] });
            notification.success({
                message: ""
            });
            onCloseModal();
        }
    }, [data, isSuccess]);

    const onSubmit: SubmitHandler<IFormInput> = (data): void => {
        let emails: string[] = [];
        data.emails.forEach((el) => emails.push(el.email));
        addCollaborators({
            org_id: id,
            emails
        });
    };

    function onCloseModal(): void {
        onClose();
    }

    function removeItem(index: number): void {
        if (fields.length === 1) return;
        remove(index);
    }

    let error_messages = errorsData as { status: string; data: { non_field_errors: string[] } };

    return (
        <Modal width={600} visible={visible} onCancel={onCloseModal} title="Invite new members">
            <>
                {error_messages?.data && <Alert type="error" text={JSON.stringify(error_messages?.data.non_field_errors[0])} />}
                <form onSubmit={handleSubmit(onSubmit)}>
                    {fields.map((item: any, index) => (
                        <div key={item.id} className="org_invite_wrap">
                            <Controller
                                name={`emails[${index}].email` as any}
                                control={control}
                                defaultValue={item.email}
                                render={({ field }: any) => (
                                    <FormItem label="Email" error={errors.emails && errors?.emails[index]?.email?.message}>
                                        <Input {...field} value={item.value} {...register(`emails.${index}.email`, { ...rules.email })} placeholder="Enter email" type="text" />
                                    </FormItem>
                                )}
                            />
                            {fields.length > 1 && (
                                <span className="org_invite_add_more" onClick={() => removeItem(index)}>
                                    <BUSKET_ICON />
                                </span>
                            )}
                        </div>
                    ))}
                    <span onClick={() => append({ email: "" })} className="org_invite_add_more">
                        <ADD_SMALL_ICON fill="#a5adba" />
                        Add more
                    </span>
                    <Divider />
                    <Button disabled={isLoading} loading={isLoading} style={{ marginLeft: "auto" }} type="submit">
                        Send invitations
                    </Button>
                </form>
            </>
        </Modal>
    );
};

export default OrganizationMembersInviteNewMembers;
