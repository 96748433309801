import { ADD_WHITE_ICON, FILE_ICON } from "shared/lib/helpers/images/images";
import "./organization-api-top-panel.scss";
import { Button } from "shared/ui";
import OrganizationApiCreate from "../organization-api-create/organization-api-create";
import { useState } from "react";

const OrganizationApiTopPanel = ({ createApiKeyEnabled }: { createApiKeyEnabled: boolean }): JSX.Element => {
    const [visible, setVisible] = useState<boolean>(false);

    return (
        <div className="organization_api_top_panel">
            <Button icon={<FILE_ICON />} disabled>
                API documentation
            </Button>
            <Button disabled={createApiKeyEnabled} onClick={() => setVisible(true)} icon={<ADD_WHITE_ICON />}>
                Create new API key
            </Button>
            <OrganizationApiCreate visible={visible} onClose={() => setVisible(false)} />
        </div>
    );
};

export default OrganizationApiTopPanel;
