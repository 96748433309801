import "./steps.scss";

interface IProps {
    step: number;
    setStep: (step: number) => void;
    steps: string[];
    padding?: boolean;
}

const Steps = ({ step, setStep, steps, padding = false }: IProps): JSX.Element => {
    function changeStep(value: number): void {
        if (value < step) {
            setStep(value);
        }
    }

    return (
        <div className="steps" style={{ padding: padding ? "20px" : "0px 0 20px" }}>
            {steps.map((el: string, index: number) => (
                <div
                    onClick={() => changeStep(index + 1)}
                    key={el}
                    style={{ cursor: `${index + 1 < step ? "pointer" : "default"}` }}
                    className={`step 
                    ${index + 1 === step ? "active" : ""} ${index + 1 < step ? "prev" : ""}`}
                >
                    <span>{index + 1}</span>
                    <p>{el}</p>
                </div>
            ))}
        </div>
    );
};

export default Steps;
