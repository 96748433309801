import { DeepMap, FieldError, FieldValues, UseFormRegister, UseFormWatch } from "react-hook-form";
import { useRef } from "react";
import { rules } from "shared/lib/helpers";
import { EYE_ICON } from "shared/lib/helpers/images/images";
import { useToggle } from "shared/lib/hooks";
import { FormItem, Input } from "shared/ui";

interface IPropsPasswordsForm<TFieldValues extends FieldValues> {
    register: UseFormRegister<{ password: string; confirm_password: string }>;
    errors: DeepMap<TFieldValues, FieldError>;
    watch: UseFormWatch<{ password: string }>;
}

const PasswordsForm = <TFieldValues extends FieldValues>({ register, errors, watch }: IPropsPasswordsForm<TFieldValues>): JSX.Element => {
    const { isToggle, toggleHandler } = useToggle(false);
    const password = useRef({});

    password.current = watch("password", "");

    return (
        <>
            <FormItem label="Password" error={errors?.password?.message}>
                <Input
                    {...register("password", rules.password)}
                    type={isToggle ? "text" : "password"}
                    placeholder="Your password"
                    data-testid="sign-up-password"
                    className={errors?.password?.message ? "error" : ""}
                />
                <span data-testid="password-eye" className="eye" onClick={toggleHandler}>
                    <EYE_ICON />
                </span>
            </FormItem>
            <FormItem label="Confirm password" error={errors?.confirm_password?.message}>
                <Input
                    {...register("confirm_password", rules.confirm_password(password.current, watch))}
                    type={isToggle ? "text" : "password"}
                    placeholder="Confirm password"
                    data-testid="sign-up-confirm_password"
                    className={errors?.confirm_password?.message ? "error" : ""}
                />
            </FormItem>
        </>
    );
};

export default PasswordsForm;
