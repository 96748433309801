import "./button.scss";
import { SPIN_SVG } from "shared/lib/helpers/images/images";
import { IButtonProps } from "./model/config/type";
import { memo } from "react";

const Button: React.FC<IButtonProps> = ({
    children,
    typeStyle = "primary",
    icon,
    htmlType = "button",
    size = "medium",
    className = "",
    disabled = false,
    loading = false,
    block = false,
    ...props
}): JSX.Element => {
    let classes = [`btn ${typeStyle} ${size} ${className}`];

    if (block) {
        classes.push("block");
    }
    let loading_spin = <SPIN_SVG width={13} />;

    return (
        <button disabled={disabled} type={htmlType} className={classes.join(" ")} {...props}>
            {loading ? (
                loading_spin
            ) : (
                <>
                    {icon && icon}
                    {children}
                </>
            )}
        </button>
    );
};

export default memo(Button);
