import { useCallback, useEffect, useRef, useState } from "react";
import { CLOSE_ICON } from "shared/lib/helpers/images/images";
import Portal from "../portal";
import "./modal.scss";

interface IModalProps {
    children: JSX.Element;
    visible: boolean;
    onCancel: () => void;
    width?: number;
    closable?: boolean;
    disabled_padding?: boolean;
    title?: string;
}

const ANIMATION_DELAY = 300;
const ACTIVE_CLASS = "modal_visible";

// Global array to track open modal IDs
const openModals: string[] = [];

const Modal = ({ children, visible, onCancel, width = 1000, closable = true, disabled_padding = false, title = "" }: IModalProps): JSX.Element | null => {
    const timerRef = useRef<ReturnType<typeof setTimeout>>();
    const [isClosing, setIsClosing] = useState(false);
    const [classAnimation, setClassAnimation] = useState("");
    const [modalId] = useState(() => `modal_${Date.now()}_${Math.random()}`); // Unique modal ID

    const closeModal = useCallback((): void => {
        if (!closable) return;
        setIsClosing(true);
        timerRef.current = setTimeout(() => {
            const index = openModals.indexOf(modalId);
            if (index > -1) {
                openModals.splice(index, 1);
            }
            onCancel();
            setIsClosing(false);
        }, ANIMATION_DELAY);
    }, [closable, onCancel, modalId]);

    const onCloseModal = (event: React.MouseEvent<HTMLSpanElement>): void => {
        event.stopPropagation();
        closeModal();
    };

    const onCloseOverlay = (event: React.MouseEvent<HTMLDivElement>): void => {
        if (!closable) return;

        const target = event.target as Element;
        const hasActive = target.className.includes(ACTIVE_CLASS);

        // Check if the modal being clicked is the top modal
        const isTopModal = openModals[openModals.length - 1] === modalId;

        if (hasActive && isTopModal) {
            closeModal();
        }
    };

    const onKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                // Only close the top-most modal
                const isTopModal = openModals[openModals.length - 1] === modalId;
                if (isTopModal) {
                    closeModal();
                }
            }
        },
        [closeModal, modalId]
    );

    useEffect(() => {
        setClassAnimation(visible ? ACTIVE_CLASS : "");
    }, [visible]);

    useEffect(() => {
        if (visible) {
            openModals.push(modalId); // Add modal ID to the list of open modals
            window.addEventListener("keydown", onKeyDown);

            const body = document.querySelector("body");
            if (body) {
                body.style.overflow = "hidden";
            }
        }

        return () => {
            if (visible) {
                const index = openModals.indexOf(modalId);
                if (index > -1) {
                    openModals.splice(index, 1); // Remove modal ID from the array
                }

                const body = document.querySelector("body");
                if (body && openModals.length === 0) {
                    body.style.overflow = "auto";
                }

                clearTimeout(timerRef.current);
                window.removeEventListener("keydown", onKeyDown);
            }
        };
    }, [visible, modalId, onKeyDown]);

    if (!visible) return null;

    return (
        <Portal>
            <div data-id={modalId} className={`modal ${classAnimation ? ACTIVE_CLASS : "hide"} ${isClosing ? "hide" : ""}`} onClick={onCloseOverlay}>
                <div style={{ maxWidth: width, paddingBottom: title ? 0 : 25 }} className="modal_content">
                    {title ? (
                        <div className={disabled_padding ? "modal_header_absolute" : "modal_header"}>
                            <p data-testid="modal_title">{title}</p>
                            {closable && (
                                <span style={{ marginLeft: 0 }} onClick={onCloseModal}>
                                    <CLOSE_ICON fill="#C1C7D0" />
                                </span>
                            )}
                        </div>
                    ) : (
                        <div style={{ padding: 15, borderBottom: 0 }} className={disabled_padding ? "modal_header_absolute" : "modal_header"}>
                            {closable && (
                                <span style={{ marginLeft: "auto" }} onClick={onCloseModal}>
                                    <CLOSE_ICON fill="#C1C7D0" />
                                </span>
                            )}
                        </div>
                    )}

                    <div style={{ overflowX: "auto" }} className="modal_main">
                        {children}
                    </div>
                </div>
            </div>
        </Portal>
    );
};

export default Modal;
