import { useState } from "react";
import { TOKENS } from "shared/config/interface";
import { useNotification } from "shared/lib/hooks";

interface IPropsUseExport {
    link: string;
    file_name: string;
    endpoint: string;
}
interface IResponseUseExport {
    isLoading: boolean;
    onExport: () => Promise<void>;
}

export const useExport = ({ link, file_name, endpoint }: IPropsUseExport): IResponseUseExport => {
    const [isLoading, setIsloading] = useState<boolean>(false);
    const notification = useNotification();

    function get_file_format(): string {
        if (!link) return "";

        let split_string = link.split(".");
        return `${file_name}.${split_string[split_string.length - 1]}`;
    }

    const token = localStorage.getItem(TOKENS.TOKEN);

    const onSubmitExport = async (): Promise<void> => {
        try {
            setIsloading(true);
            const response = await fetch(endpoint, {
                method: "GET",
                mode: "cors",
                headers: {
                    Authorization: `Token ${token}`
                }
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }

            const reader = response.body!.getReader();
            const stream = new ReadableStream({
                start(controller) {
                    function push(): any {
                        reader.read().then(({ done, value }) => {
                            if (done) {
                                controller.close();
                                return;
                            }
                            controller.enqueue(value);
                            push();
                        });
                    }
                    push();
                }
            });

            const newResponse = new Response(stream);
            const blob = await newResponse.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = downloadUrl;
            a.download = get_file_format();
            document.body.appendChild(a);
            a.click();

            window.URL.revokeObjectURL(downloadUrl);
            a.remove();
            notification.success({
                message: "File downloaded successfully",
                duration: 4
            });
            setIsloading(false);
        } catch (error) {
            setIsloading(false);
            notification.error({
                message: "There was an error downloading the file, please try again later",
                duration: 4
            });
        }
    };

    return {
        isLoading,
        onExport: onSubmitExport
    };
};
