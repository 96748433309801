import $api from "shared/api/api";
import { ICalculatorData } from "../model/types/types";
import { KEEP_UNUSED_DATA_FOR } from "shared/config/config";

export const calculatorApi = $api.injectEndpoints({
    endpoints: (builder) => ({
        getCalculatorPrices: builder.query<ICalculatorData, null>({
            query: () => ({
                url: `account/prices-option/`,
                method: "GET"
            }),
            keepUnusedDataFor: KEEP_UNUSED_DATA_FOR
        })
    })
});
