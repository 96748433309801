import { CREATE_TASK_DEFAULT_DATA, PILOT_TASK_NAME } from "shared/config/config";
import { ILabel } from "shared/config/interface";
import { TaskType } from "../types/interface";
import { ICreateTaskPayload } from "entities/task";

interface IConstructTaskData {
    project_id: number;
    name: string;
    tasks_count: number;
    type_of_task: TaskType;
    project_name: string;
    approximate_price: number;
    newApproxPrice: number;
    average_objects: number;
}

export class CreateTaskService {
    static calculate_aproximate_price(files: number, average_objects: number, base_cost: number): number {
        let approximate_price = files * average_objects * base_cost;

        return Number(approximate_price.toFixed(2));
    }

    static generate_pilot_task_name(project_name: string, task_name: string): string {
        return `${project_name}_${task_name}_${PILOT_TASK_NAME}`;
    }
    static generate_task_name(tasks_count: number, task_name: string): string {
        return task_name || `Task #${tasks_count + 1}`;
    }

    static count_attributes(labels: ILabel[] | number, label_attributes_quantity: number): number {
        if (!labels) return 0;
        if (typeof labels === "number") return label_attributes_quantity;

        return labels.reduce((count: number, label: ILabel) => count + (label?.attributes?.length || 0), 0);
    }

    static construct_task_data({ project_id, name, tasks_count, type_of_task, project_name, approximate_price, newApproxPrice, average_objects }: IConstructTaskData): ICreateTaskPayload {
        const data: ICreateTaskPayload = {
            ...CREATE_TASK_DEFAULT_DATA,
            project_id,
            name: this.generate_task_name(tasks_count, name),
            approximate_price: type_of_task === TaskType.PILOT ? Math.ceil(approximate_price) : Math.ceil(newApproxPrice),
            budget: type_of_task === TaskType.PILOT ? 0 : newApproxPrice
        };

        if (type_of_task === TaskType.PILOT) {
            data.pilot = true;
            data.name = this.generate_pilot_task_name(project_name, name || `Task #${tasks_count + 1}`);
        }

        if (tasks_count < 1) {
            data.average_objects_per_frame = +average_objects;
        }

        return data;
    }
}
