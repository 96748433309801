import { ChangeEvent, DragEvent, useCallback, useRef, useState } from "react";
import { UPLOAD_ICON } from "shared/lib/helpers/images/images";
import { Button } from "shared/ui";
import { useNotification } from "shared/lib/hooks";
import UploadFileList from "./upload-file-list";
import { ACCEPT_IMAGES } from "shared/config/config";
import UploadFileMessages from "./upload-file-messages";
import "./upload-file.scss";

interface IProps {
    setFiles: (files: File[]) => void;
    files: File[];
    maxFiles?: number;
    files_size_limit?: number;
}

function get_file_size(files_size: number): number {
    return files_size / 1024 / 1024;
}

const UploadFile = ({ setFiles, files, maxFiles = 10, files_size_limit = 1024 }: IProps): JSX.Element => {
    const ref = useRef<HTMLInputElement>(null);
    const notification = useNotification();

    const [dragActive, setDragActive] = useState<boolean>(false);
    const [visibleFileFormatError, setVisibleFileFormatError] = useState<boolean>(false);
    const [sizeLimitError, setSizeLimitError] = useState<boolean>(false);

    const handleDrag = (e: DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        setDragActive(true);
    };

    const handleDragLeave = (e: DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        setDragActive(false);
    };

    const handleDrop = (e: DragEvent<HTMLDivElement>): void => {
        e.preventDefault();

        const droppedFiles: File[] = Array.from(e.dataTransfer.files);
        const new_file_list = [...files, ...droppedFiles];

        if (new_file_list.length > maxFiles) {
            notification.error({
                message: `Maximum ${maxFiles} files can be uploaded at a time.`
            });
            return;
        }
        setFiles(new_file_list);
        setDragActive(false);
    };
    const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        if (!e.target.files) return;

        const INPUT_FILES: File[] = Array.from(e.target.files);
        const new_file_list = [...files, ...INPUT_FILES];

        if (new_file_list.length > maxFiles) {
            notification.error({
                message: `Maximum ${maxFiles} files can be uploaded at a time.`
            });
            return;
        }

        let files_size: number = 0;
        Array.from(e.target.files).forEach((file: File) => (files_size += file.size));

        if (get_file_size(files_size) > files_size_limit) {
            setSizeLimitError(true);
            notification.error({
                message: `Maximum files size - 1024MB`
            });
            return;
        }

        setSizeLimitError(false);
        setFiles(new_file_list);
    };

    const onRemoveFile = useCallback(
        (file: File): void => {
            let filtered_files = files.filter((fileItem) => fileItem.name !== file.name);
            setFiles(filtered_files);
        },
        [files]
    );

    const deleteAll = (): void => {
        setFiles([]);
    };

    return (
        <div className="upload">
            <div onDragOver={handleDrag} onDragEnter={handleDrag} onDragLeave={handleDragLeave} onDrop={handleDrop} className={`upload_main ${dragActive ? "active" : ""}`}>
                <label htmlFor="upload" className="upload_label">
                    <input maxLength={maxFiles} data-testid="file-input" multiple ref={ref} type="file" id="upload" onChange={handleChange} style={{ display: "none" }} accept={ACCEPT_IMAGES} />
                    <UPLOAD_ICON />
                    <b>Drag & Drop your batch files here</b>
                    <small>OR</small>
                    <Button disabled={files.length === maxFiles} size="small" data-testid="file-upload-browse-file" onClick={() => ref.current?.click()} typeStyle="default">
                        Choose files
                    </Button>
                </label>
            </div>

            {files.length > 0 && (
                <div className="upload_info">
                    <p>Files uploaded: {files.length}</p>
                    <span onClick={deleteAll}>Delete all</span>
                </div>
            )}
            <UploadFileList files={files} onRemoveFile={onRemoveFile} />
            <UploadFileMessages files={files.length} maxFiles={maxFiles} visibleFileFormatError={visibleFileFormatError} sizeLimitError={sizeLimitError} />
        </div>
    );
};

export default UploadFile;
