import { BudgetingType, CreateTaskTypes, ILabel } from "shared/config/interface";

export interface ICreateTaskState {
    step: number;
    files: File[];
    budgeting_type: BudgetingType;
    amount: null | number;
    name: string;
    average_objects: string | number;
    uploadTask: boolean;
    isSuccesCreateTask: boolean;
    type_of_task: TaskType;
    is_task_chosen: boolean;
    is_pilot_task_in_project: boolean;
    mode: CreateTaskTypes;
    selected_cloud_storage: number | null;
    cloud_storage_new_prefix: string;
    cloud_storage_count_files: number | null;
}

export interface ICreateTaskProps {
    project_id: number;
    tasks_count: number;
    labels: ILabel[] | number;
    label_attributes_quantity: number;
    annotation_tool: string | null;
    // visible: boolean;
    setVisible: (value: boolean) => void;
    updateTaskList: () => void;
    setIsSuccessCreateTask: (value: boolean) => void;
    project_name: string;
}

export type TaskDataKey = keyof ICreateTaskState;

export interface ICreateTaskUploadBatchProps {
    labelsCount: number;
    attributesCount: number;
    updateState: <T extends TaskDataKey>(key: T, value: ICreateTaskState[T]) => void;
    annotation_tool: string | null;
    average_objects_per_frame_required?: boolean;
    create_task: () => Promise<void>;
}

export interface ICreateTaskBudgetingProps {
    create_task: () => Promise<void>;
    budgeting_type: BudgetingType;
    updateState: <T extends TaskDataKey>(key: T, value: ICreateTaskState[T]) => void;
}
export enum TaskType {
    PILOT = "pilot",
    STANDART = "standart"
}
