import { memo } from "react";
import { truncate_string } from "shared/lib/helpers";
import { CLOSE_ICON, FILE_SMALL_ICON } from "shared/lib/helpers/images/images";

interface IProps {
    files: File[];
    onRemoveFile: (file: File) => void;
}

const UploadFileList = ({ files, onRemoveFile }: IProps): JSX.Element | null => {
    if (Object.values(files).length === 0 || !files) return null;

    return (
        <div className="upload_files">
            {files.map((file: File, index: number) => {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={`${file.name}_${index}`}>
                        <FILE_SMALL_ICON />
                        <p>{truncate_string(file.name, 63)}</p>
                        <span onClick={() => onRemoveFile(file)}>
                            <CLOSE_ICON fill="#A5ADBA" />
                        </span>
                    </div>
                );
            })}
        </div>
    );
};

export default memo(UploadFileList);
