import external_api from "shared/api/external_api";
import { IProject } from "shared/config/interface";
import { ICreateProjectPayload } from "../model/types/interface";
import { API_TAGS } from "shared/config/config";

export const createProjectApi = external_api.injectEndpoints({
    endpoints: (builder) => ({
        createProject: builder.mutation<IProject, ICreateProjectPayload>({
            query: (project) => ({
                url: `/projects`,
                method: "POST",
                body: project
            }),
            invalidatesTags: [API_TAGS.Project_CVAT]
        }),
        deleteProject: builder.mutation<IProject, number>({
            query: (id) => ({
                url: `/projects/${id}`,
                method: "DELETE"
            })
        })
    })
});

export const { useCreateProjectMutation, useDeleteProjectMutation } = createProjectApi;
