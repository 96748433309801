import { Middleware, MiddlewareAPI, combineReducers, configureStore, isRejectedWithValue } from "@reduxjs/toolkit";
import { accountSlice, set_is_auth, logout as logout_reducer } from "entities/account";
import endpoints from "shared/api/api";
import external_api from "shared/api/external_api";
import { onboardingSlice } from "entities/onboarding";
import { createProjectSlice } from "entities/create-project";
import { useNotification } from "shared/lib/hooks";
import { createTaskSlice } from "entities/create-task/";
import { calculatorSlice } from "entities/calculator";
import { dashboardSlice } from "entities/dashboard";
import { billingSlice } from "entities/billing";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { IS_REACT_APP_DEV } from "shared/config/config";

const rootReducer = combineReducers({
    createProject: createProjectSlice,
    onboarding: onboardingSlice,
    account: accountSlice,
    createTask: createTaskSlice,
    calculator: calculatorSlice,
    dashboard: dashboardSlice,
    billing: billingSlice,
    [endpoints.reducerPath]: endpoints.reducer,
    [external_api.reducerPath]: external_api.reducer
});

const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        switch (action.payload.status) {
            case 401:
                useNotification().error({ message: `CVAT - ${action.payload.data.detail}` });
                api.dispatch(logout_reducer());
                api.dispatch(set_is_auth(false));
                break;
            default:
                break;
        }
    }

    return next(action);
};

export const setupStore = (preloadedState?: Partial<RootState>): any => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
        devTools: Boolean(IS_REACT_APP_DEV),
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false
            }).concat([endpoints.middleware, external_api.middleware, rtkQueryErrorLogger])
    });
};

setupListeners(setupStore().dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
