import $api from "shared/api/api";
import { CredentialType, ICloudCredential, ICloudCredentialCreate, ImportCloudCredentialType } from "../model/types/types";
import { API_TAGS, EXTERNAL_API_PLATFORM_TYPE } from "shared/config/config";
import external_api from "shared/api/external_api";

const cloudStorageApi = $api.injectEndpoints({
    endpoints: (builder) => ({
        getCloudCredential: builder.query<ICloudCredential[], null>({
            query: () => ({
                url: `/external-api/cloud-credential/`,
                method: "GET"
            }),
            providesTags: [API_TAGS.Cloud]
        }),
        createCloudCredential: builder.mutation<ICloudCredential, ICloudCredentialCreate<CredentialType>>({
            query: (data) => ({
                url: `/external-api/cloud-credential/`,
                method: "POST",
                body: data
            }),
            invalidatesTags: [API_TAGS.Cloud]
        }),
        cloudCredentialImportDataset: builder.mutation<ICloudCredential, ICloudCredentialCreate<CredentialType>>({
            query: (data) => ({
                url: `/external-api/cloud-credential/import-dataset/`,
                method: "POST",
                body: data
            })
        }),
        getCloudCredentialById: builder.query<ICloudCredential, number>({
            query: (id) => ({
                url: `/external-api/cloud-credential/${id}`,
                method: "GET"
            })
        }),
        updateCloudCredentialById: builder.mutation<ICloudCredential, { id: number; data: ICloudCredentialCreate<CredentialType> }>({
            query: ({ id, data }) => ({
                url: `/external-api/cloud-credential/${id}/`,
                method: "PATCH",
                body: data
            }),
            onQueryStarted: async ({ id }, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        cloudStorageApi.util.updateQueryData("getCloudCredential", null, (draft) => {
                            return draft.map((el) => {
                                if (+el.id === +id) {
                                    return {
                                        ...el,
                                        ...data
                                    };
                                }

                                return el;
                            });
                        })
                    );
                    await queryFulfilled;
                } catch (error) {
                    console.error("Failed to delete:", error);
                }
            }
        }),
        deleteCloudCredential: builder.mutation<ICloudCredential, number>({
            query: (id) => ({
                url: `/external-api/cloud-credential/${id}/`,
                method: "DELETE"
            }),
            onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
                try {
                    dispatch(
                        cloudStorageApi.util.updateQueryData("getCloudCredential", null, (draft) => {
                            return draft.filter((credential) => credential.id !== id);
                        })
                    );
                    await queryFulfilled;
                } catch (error) {
                    console.error("Failed to delete:", error);
                }
            }
        }),
        getCloudCredentialCountFiles: builder.mutation<{ quantity_files: number }, { id: number; prefix?: string }>({
            query: ({ id, prefix }) => ({
                url: `/external-api/cloud-credential/${id}/count-files/`,
                method: "POST",
                body: {
                    ...(prefix !== undefined && { prefix })
                }
            })
        })
    })
});

const cloudStorageApiExternal = external_api.injectEndpoints({
    endpoints: (builder) => ({
        importCloudData: builder.mutation<any, { id: number; credential: ImportCloudCredentialType }>({
            query: ({ id, credential }) => ({
                url: `/tasks/${id}/import-cloud-data?${EXTERNAL_API_PLATFORM_TYPE}`,
                method: "POST",
                body: credential
            }),
            invalidatesTags: (result, error, arg) => [{ type: "Task", id: arg.id }]
        })
    })
});

export const {
    useCloudCredentialImportDatasetMutation,
    useCreateCloudCredentialMutation,
    useLazyGetCloudCredentialByIdQuery,
    useGetCloudCredentialQuery,
    useUpdateCloudCredentialByIdMutation,
    useDeleteCloudCredentialMutation,
    useGetCloudCredentialCountFilesMutation
} = cloudStorageApi;

export const { useImportCloudDataMutation } = cloudStorageApiExternal;
