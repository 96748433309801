import { memo, useState } from "react";
import Input from "../input/input";
import "./editable.scss";
import { EDIT_ICON } from "shared/lib/helpers/images/images";
import Spin from "../spin/spin";

interface IProps {
    text: string;
    onFinish: (new_value: string) => void;
    isLoading?: boolean;
    editable?: boolean;
}

const Editable = ({ text, onFinish, isLoading = false, editable = true }: IProps): JSX.Element => {
    const [value, setValue] = useState<string>(() => text);
    const [editing, setEditing] = useState<boolean>(false);

    function onBlur(): void {
        setEditing(false);
        if (value === text) return;
        onFinish(value);
    }

    if (isLoading) {
        return <Spin />;
    }

    if (!editable) {
        return (
            <div className="editable">
                <h2>{value}</h2>
            </div>
        );
    }

    return (
        <div className="editable">
            {editing ? (
                <Input
                    data-testid="editable_input"
                    autoFocus
                    className="editable_input"
                    value={value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
                    onBlur={onBlur}
                />
            ) : (
                <>
                    <h2 data-testid="editable_value">{value}</h2>
                    <span data-testid="editable_btn" onClick={() => setEditing(true)} className="editable_btn">
                        {<EDIT_ICON fill="#2AABFF" style={{ marginLeft: 5 }} />}
                    </span>
                </>
            )}
        </div>
    );
};

export default memo(Editable);
