import { components } from "react-select";

export const CloudStorageSelectCustomOption = (props: any): JSX.Element => {
    const { data, innerRef, innerProps, isDisabled, isFocused, isSelected } = props;

    const styles = {
        display: "flex",
        alignItems: "center",
        padding: "12px 15px",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: 500,
        color: isDisabled ? "#a5adba" : isFocused || isSelected ? "white" : "#000000",
        backgroundColor: isDisabled ? "#f4f5f7" : isFocused || isSelected ? "black" : "white",
        cursor: isDisabled ? "not-allowed" : "pointer",
        borderRadius: "5px",
        transition: "0.2s all",
        opacity: isDisabled ? 0.3 : 1
    };

    return (
        <div ref={innerRef} {...innerProps} style={styles}>
            {data.icon}
            <span style={{ marginLeft: "8px" }}>{data.label}</span>
        </div>
    );
};

export const CloudStorageSelectCustomSingleValue = (props: any): any => {
    const { data } = props;
    return (
        <components.SingleValue {...props}>
            <div style={{ display: "flex", alignItems: "center" }}>
                {data.icon}
                <span style={{ marginLeft: "8px" }}>{data.label}</span>
            </div>
        </components.SingleValue>
    );
};
