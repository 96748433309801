import createTaskSlice, {
    clearTaskState,
    setCreateTaskData,
    setTypeOfTask,
    setIsTaskChosen,
    setCreateTaskStep,
    setIsPilotTaskInProject,
    setTaskMode,
    seetSelectedCloudStorage,
    setNewCloudStoragePrefix,
    setCloudStorageCountFiles
} from "./model/slice/create-task-slice";
import { ICreateTaskUploadBatchProps, ICreateTaskBudgetingProps, ICreateTaskProps, ICreateTaskState, TaskDataKey, TaskType } from "./model/types/interface";
import { TASK_STEPS } from "./model/config/config";
import {
    create_task_files_selector,
    create_task_budgeting_type_selector,
    create_task_amount_selector,
    create_task_name_selector,
    create_task_average_objects_selector,
    create_task_type_of_task_selector,
    create_task_is_task_chosen_selector,
    create_task_step_selector,
    create_task_is_pilot_task_in_project_selector,
    create_task_mode_selector,
    create_task_selected_cloud_storage_selector,
    create_task_cloud_storage_new_prefix_selector,
    create_task_cloud_storage_count_files_selector
} from "./model/selectors/create-task-selectors";
import { CreateTaskService } from "./model/services/create-task-service";
import useUploadFilesToTask from "./model/hooks/useUploadFilesToTask";

export {
    createTaskSlice,
    TASK_STEPS,
    clearTaskState,
    setCreateTaskData,
    create_task_files_selector,
    create_task_budgeting_type_selector,
    create_task_amount_selector,
    create_task_name_selector,
    create_task_average_objects_selector,
    create_task_type_of_task_selector,
    TaskType,
    setTypeOfTask,
    setIsTaskChosen,
    create_task_is_task_chosen_selector,
    create_task_step_selector,
    setCreateTaskStep,
    setIsPilotTaskInProject,
    create_task_is_pilot_task_in_project_selector,
    CreateTaskService,
    setTaskMode,
    create_task_mode_selector,
    seetSelectedCloudStorage,
    create_task_selected_cloud_storage_selector,
    setNewCloudStoragePrefix,
    create_task_cloud_storage_new_prefix_selector,
    useUploadFilesToTask,
    setCloudStorageCountFiles,
    create_task_cloud_storage_count_files_selector
};

export type { ICreateTaskUploadBatchProps, ICreateTaskBudgetingProps, ICreateTaskProps, ICreateTaskState, TaskDataKey };
