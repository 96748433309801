import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILabel, PROJECT_TYPE, ProjectType } from "shared/config/interface";
import { CreateProjectInstructionType, ICreateProjectState } from "../types/interface";

const INITIAL_STEP = 1;

const INITIAL_STATE: ICreateProjectState = {
    loading: false,
    current_step: INITIAL_STEP,
    current_project_type_view: PROJECT_TYPE.CV,
    current_project: {},
    create_project: {
        name: "",
        summary: "",
        annotation_type: "",
        annotation_tool: "",
        labels: [],
        pdf_instruction: {} as Blob,
        instruction_content_initialized: false,
        instruction_content: "",
        instruction_file: null
    },
    selection_mode: false,
    isLoadingCreateProject: false,
    labels: [],
    edit_mode: false,
    quick_upload_mode: false,
    show_attributes: false,
    edit_original_name: "",
    show_drawer: false,
    create_instruction_mode: "create"
};

const createProjectSlice = createSlice({
    name: "create-project",
    initialState: INITIAL_STATE,
    reducers: {
        createProjectData(state, action: PayloadAction<any>) {
            state.create_project = { ...state.create_project, ...action.payload };
        },
        setSelectionMode(state, action: PayloadAction<boolean>) {
            state.selection_mode = action.payload;
        },
        setCurrentStep(state, action: PayloadAction<number>) {
            state.current_step = action.payload;
        },
        setCurrentProjectTypeView(state, action: PayloadAction<ProjectType>) {
            state.current_project_type_view = action.payload;
        },
        clearProjectDetails(state) {
            state.current_step = INITIAL_STEP;
            state.create_project = {
                ...state.create_project,
                name: "",
                annotation_type: "",
                annotation_tool: "",
                labels: [],
                pdf_instruction: {} as Blob,
                instruction_content: "",
                instruction_content_initialized: false,
                instruction_file: null
            };
            state.selection_mode = false;
            state.isLoadingCreateProject = false;
            state.labels = [];
            state.edit_mode = false;
            state.quick_upload_mode = false;
            state.show_attributes = false;
            state.edit_original_name = "";
            state.show_drawer = false;
            state.create_instruction_mode = "create";
        },

        setIsLoadingCreateProject(state, action: PayloadAction<boolean>) {
            state.isLoadingCreateProject = action.payload;
        },
        add_label(state, action: PayloadAction<ILabel>) {
            state.labels = [...state.labels, action.payload];
        },
        update_original_label(state, action: PayloadAction<ILabel>) {
            const { name } = action.payload;

            const editOriginalName = state.edit_original_name;
            const index = state.labels.findIndex((label) => (editOriginalName ? label.name === editOriginalName : label.name === name));

            if (index !== -1) {
                state.labels[index] = action.payload; // Replacing an existing label with new data
            } else {
                state.labels.push(action.payload); // add new label
            }
        },
        remove_label(state, action: PayloadAction<string>) {
            state.labels = state.labels.filter((label) => label.name !== action.payload);
        },
        set_edit_mode(state, action: PayloadAction<boolean>) {
            state.edit_mode = action.payload;
        },
        set_show_attributes(state, action: PayloadAction<boolean>) {
            state.show_attributes = action.payload;
        },
        set_edit_original_name(state, action: PayloadAction<string>) {
            state.edit_original_name = action.payload;
        },
        set_quick_upload(state, action: PayloadAction<boolean>) {
            state.quick_upload_mode = action.payload;
        },
        set_show_drawer(state, action: PayloadAction<boolean>) {
            state.show_drawer = action.payload;
        },
        set_create_instruction_mode(state, action: PayloadAction<CreateProjectInstructionType>) {
            state.create_instruction_mode = action.payload;
        }
    }
});

export const {
    createProjectData,
    setSelectionMode,
    setCurrentStep,
    setCurrentProjectTypeView,
    clearProjectDetails,
    setIsLoadingCreateProject,
    set_edit_mode,
    add_label,
    set_show_attributes,
    remove_label,
    update_original_label,
    set_edit_original_name,
    set_quick_upload,
    set_show_drawer,
    set_create_instruction_mode
} = createProjectSlice.actions;
export default createProjectSlice.reducer;
