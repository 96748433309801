import { LOADER_WRAP_ICON } from "shared/lib/helpers/images/images";
import { Spin } from "shared/ui";
import "./loader.scss";

interface ILoaderProps {
    title?: string;
    description?: string;
    mask?: boolean;
}

const Loader = ({ title = "Loading result...", description = "Please wait", mask = true }: ILoaderProps): JSX.Element => {
    return (
        <div className={`${mask ? "app_loader loader" : "app_loader"}`}>
            <div className="app_loader_wrapper">
                <Spin />
                <LOADER_WRAP_ICON />
            </div>
            <span className="app_loader_title">{title}</span>
            <span className="app_loader_description">{description}</span>
        </div>
    );
};

export default Loader;
