import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AnnotationTools, ICalculatorData, InstrumentsEnum, InstrumentType, IPrice, TypeFiles } from "../types/types";
import { RESULT_VARIANTS } from "../types/config";

interface ICalculatorState {
    loading: boolean;
    prices: ICalculatorData;
    currentPriceElement: IPrice;
    annotationTool: AnnotationTools;
    activeType: TypeFiles;
    imagesCount: number;
    labelsCount: number;
    attributesCount: number;
    averageAnnotationsPerFrameCount: number;
    approximate_price: number;
    current_price_key: InstrumentsEnum;
}

const INITIAL_STATE: ICalculatorState = {
    loading: false,
    prices: {} as ICalculatorData,
    currentPriceElement: {} as IPrice,
    annotationTool: AnnotationTools.RECTANGLE,
    activeType: TypeFiles.IMAGE,
    imagesCount: 0,
    labelsCount: 0,
    attributesCount: 0,
    averageAnnotationsPerFrameCount: 0,
    approximate_price: 0,
    current_price_key: InstrumentsEnum.rectangle_image
};

const calculatorSlice = createSlice({
    name: "calculator",
    initialState: INITIAL_STATE,
    reducers: {
        setActiveType(state, action: PayloadAction<TypeFiles>) {
            state.activeType = action.payload;
        },
        setAnnotationTool(state, action: PayloadAction<AnnotationTools>) {
            state.annotationTool = action.payload;
        },
        setCurrentPriceElement(state) {
            let current: string = `${state.annotationTool}_${state.activeType}`;
            let current_key: InstrumentsEnum = RESULT_VARIANTS[current];
            let current_price_object = state.prices[current_key];

            state.current_price_key = current_key;
            state.currentPriceElement = current_price_object;
        },
        setImages(state, action: PayloadAction<number>) {
            state.imagesCount = action.payload;
        },
        setLabels(state, action: PayloadAction<number>) {
            state.labelsCount = action.payload;
        },
        setAttributes(state, action: PayloadAction<number>) {
            state.attributesCount = action.payload;
        },
        setAverageAnnotationsPerFrame(state, action: PayloadAction<number>) {
            state.averageAnnotationsPerFrameCount = action.payload;
        },
        setPrices(state, action: PayloadAction<ICalculatorData>) {
            state.prices = action.payload;
        },
        setSum(state, action: PayloadAction<number>) {
            state.approximate_price = action.payload;
        }
    }
});

export const { setActiveType, setAnnotationTool, setCurrentPriceElement, setImages, setLabels, setAttributes, setAverageAnnotationsPerFrame, setPrices, setSum } = calculatorSlice.actions;
export default calculatorSlice.reducer;
