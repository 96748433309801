import { memo } from "react";
import { balance_selector } from "entities/account";
import { currencyFormat } from "shared/lib/helpers";
import { useAppSelector } from "shared/lib/hooks";
import "./balance.scss";

const BalanceAlert = ({ onCLick }: { onCLick: () => void }): JSX.Element => {
    const { available_balance } = useAppSelector(balance_selector);

    return (
        <div className="balance_alert">
            <p>
                Available balance: <b> {currencyFormat(available_balance)}</b>
            </p>
            <span onClick={onCLick} className="balance_alert_btn">
                Top up the balance
            </span>
        </div>
    );
};

export default memo(BalanceAlert);
