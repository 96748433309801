import "./switch-card.scss";

interface ISwitchCardsProps {
    children: React.ReactNode | JSX.Element;
    active: boolean;
    onChange: () => void;
    disabled?: boolean;
}

const SwitchCard = ({ children, active, onChange, disabled = false }: ISwitchCardsProps): JSX.Element => {
    const disabled_styles: React.CSSProperties = {
        pointerEvents: "none",
        opacity: 0.4,
        border: "1px solid #D1D4DB"
    };

    return (
        <div className={`switch_card ${active ? "visible_card" : ""}`} onClick={() => onChange()} style={disabled ? disabled_styles : {}}>
            <div className="switch_card_circle" />
            {children}
        </div>
    );
};

export default SwitchCard;
