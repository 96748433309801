import React, { forwardRef, memo } from "react";
import "./input.scss";

interface IProps {
    checked: boolean;
    disabled?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    props?: any;
    name?: string;
}

const Checkbox: React.FC<IProps> = forwardRef(({ disabled, onChange, checked, ...rest }, ref: any): JSX.Element => {
    const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>): void => {
        onChange(event);
    };

    return (
        <div className="checkbox-wrapper">
            <input ref={ref} {...rest} onChange={onChangeCheckbox} disabled={disabled} checked={checked} className="inp-cbx" id="default" type="checkbox" />
            <label className="cbx" htmlFor="default">
                <span>
                    <svg width="12px" height="10px">
                        <use xlinkHref="#check-4" />
                    </svg>
                </span>
                <span>Use by default</span>
            </label>
            <svg className="inline-svg">
                <symbol id="check-4" viewBox="0 0 12 10">
                    <polyline points="1.5 6 4.5 9 10.5 1" />
                </symbol>
            </svg>
        </div>
    );
});

export default memo(Checkbox);
