export function dateToDisplayFormat(date: Date, locale: string = "en-US"): string {
    const dateObj = new Date(date);

    const options: Intl.DateTimeFormatOptions = {
        // weekday: "long", // "Monday"
        year: "numeric", // "2021"
        month: "long", // "July"
        day: "numeric" // "19"
    };

    return new Intl.DateTimeFormat(locale, options).format(dateObj);
}
