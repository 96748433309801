import { memo, useEffect, useMemo, useRef, useState } from "react";
import "./styles.scss";
import { _days_in_month, _get_next_days, _get_prev_days, DayType, ICell, MONTH, ANIMDATION_DELAY, _is_current_day } from "./helpers";
import DatePickerNavigationPanel from "./date-picker-navigation";
import { useClickOutside } from "shared/lib/hooks";
import DatePickerFooter from "./date-picker-footer";
import DatePickerWeek from "./date-picker-week";
import DatePickerInput from "./date-picker-input";

type Props = {
    onChange: (date: Date) => void;
    default_value?: Date;
};

const DatePicker = ({ onChange, default_value }: Props): JSX.Element => {
    // date states
    const [selectedDate, setSelectedDate] = useState<Date | null>(() => default_value || null);
    const [panelYear, setPanelYear] = useState(() => selectedDate?.getFullYear() || new Date().getFullYear());
    const [panelMonth, setPanelMonth] = useState(() => selectedDate?.getMonth() || new Date().getMonth());
    // additional states
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [animation, setAnimation] = useState<boolean>(false);
    const [isClosing, setIsClosing] = useState<boolean>(false);

    const timerRef = useRef<ReturnType<typeof setTimeout>>();
    const ref = useRef<HTMLDivElement>(null);

    useClickOutside(ref, () => on_сlose_delay());

    useEffect(() => {
        return () => {
            clearTimeout(timerRef.current);
        };
    }, []);

    useEffect(() => {
        setAnimation(isOpen);
    }, [isOpen]);

    const [year_selected, month_selected, day_selected] = useMemo(() => {
        if (!selectedDate) return [];

        const currentYear = selectedDate.getFullYear();
        const currentDay = selectedDate.getDate();
        const currentMonth = selectedDate.getMonth();

        return [currentYear, currentMonth, currentDay];
    }, [selectedDate]);

    const prev_month = (): void => {
        if (panelMonth === 0) {
            setPanelMonth(11);
            setPanelYear(panelYear - 1);
        } else {
            setPanelMonth(panelMonth - 1);
        }
    };

    const next_month = (): void => {
        if (panelMonth === 11) {
            setPanelMonth(0);
            setPanelYear(panelYear + 1);
        } else {
            setPanelMonth(panelMonth + 1);
        }
    };

    const prev_year = (): void => {
        setPanelYear(panelYear - 1);
    };

    const next_year = (): void => {
        setPanelYear(panelYear + 1);
    };

    const on_click_input = (): void => {
        setIsOpen(true);
    };

    const render_cell = (): ICell[] => {
        const rows: ICell[] = [];
        const year = panelYear;
        const month = panelMonth;
        const DAYS_IN_MONTH = _days_in_month(year, month);

        for (let i = 1; i <= DAYS_IN_MONTH; i++) {
            rows.push({
                label: i,
                is_current: i === day_selected && month === month_selected && year === year_selected,
                is_selected: i === day_selected,
                type: DayType.CURRENT,
                year,
                month,
                day: i
            });
        }

        return [..._get_prev_days(year, month), ...rows, ..._get_next_days(year, month)];
    };

    const on_select_date = (selected_date: Date): void => {
        setSelectedDate(selected_date);
        onChange(selected_date);
        setIsOpen(false);
    };

    const on_today = (): void => {
        setSelectedDate(new Date());
        setPanelYear(new Date().getFullYear());
        setPanelMonth(new Date().getMonth());
        setIsOpen(false);
    };

    const on_сlose_delay = (): void => {
        setIsClosing(true);
        timerRef.current = setTimeout(() => {
            setIsOpen(false);
            setIsClosing(false);
        }, ANIMDATION_DELAY);
    };

    return (
        <div className="dp">
            <DatePickerInput isOpen={isOpen} on_click_input={on_click_input} selectedDate={selectedDate} />
            {isOpen && (
                <div className={`${animation ? "dp_calendar visible" : "dp_calendar"} ${isClosing ? "closing" : ""}`} ref={ref}>
                    <DatePickerNavigationPanel date={`${MONTH[panelMonth]} ${panelYear}`} next_month={next_month} prev_year={prev_year} next_year={next_year} prev_month={prev_month} />
                    <div className="dp_calendar_main">
                        <DatePickerWeek />
                        {render_cell().map((el: ICell) => (
                            <span
                                data-day={`${el.year}-${el.month}-${el.day}`}
                                className={`${el.is_current ? "dp_calendar_main_day current_day" : "dp_calendar_main_day"} ${el.type}`}
                                key={el.label + el.type}
                                onClick={el.type === DayType.CURRENT ? () => on_select_date(new Date(el.year, el.month, el.day)) : () => {}}
                                style={{ border: _is_current_day(el.year, el.month, el.day) ? "1px solid #20b9e357" : "none" }}
                            >
                                {el.label}
                            </span>
                        ))}
                    </div>
                    <DatePickerFooter on_today={on_today} />
                </div>
            )}
        </div>
    );
};

export default memo(DatePicker);
