import React, { memo, useEffect, useState } from "react";
import Input from "../input/input";
import "./editable.scss";
import { EDIT_ICON } from "shared/lib/helpers/images/images";
import Spin from "../spin/spin";
import { useForm } from "react-hook-form";
import { useNotification } from "shared/lib/hooks";

interface IProps {
    text: string;
    onFinish: (new_value: string) => void;
    isLoading?: boolean;
    editable?: boolean;
    size?: "small" | "large";
    children: React.ReactNode;
}

const Editable = ({ text, onFinish, isLoading = false, editable = true, size = "large", children }: IProps): JSX.Element => {
    const [value, setValue] = useState<string>("");
    const [editing, setEditing] = useState<boolean>(false);
    const notification = useNotification();

    const {
        register,
        reset,
        formState: { errors }
    }: any = useForm<{ new_value: string }>({ mode: "onBlur" });

    useEffect(() => {
        setValue(text);
    }, [text]);

    useEffect(() => {
        if (!errors.new_value) return;

        notification.error({
            message: errors.new_value.message
        });
    }, [errors]);

    function onBlur(): void {
        reset();

        setEditing(false);
        if (value === text) return;
        onFinish(value);
    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
        if (event.key === "Escape") {
            setEditing(false);
            setValue(text);
        } else if (event.key === "Enter") {
            onBlur();
        }
    }

    if (isLoading) {
        return <Spin />;
    }

    if (!editable) {
        return <>{children}</>;
    }
    return (
        <form className={`editable ${size}`}>
            {children}
            <span data-testid="editable_btn" onClick={() => setEditing(true)} className="editable_btn">
                {<EDIT_ICON fill="#2AABFF" style={{ marginLeft: 5 }} />}
            </span>
            {editing && (
                <Input
                    {...register("new_value", {
                        minLength: { value: 2, message: "The new value must have at least 2 characters" },
                        required: "The new value is required field"
                    })}
                    data-testid="editable_input"
                    autoFocus
                    className="editable_input"
                    value={value}
                    onBlur={onBlur}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
            )}
        </form>
    );
};

export default Editable;
