import { useEffect, useState } from "react";

export const useSticky = (scroll: number): boolean => {
    const [isSticky, setIsSticky] = useState<boolean>(false);

    useEffect(() => {
        const handleScroll = (): void => {
            const scrollThreshold = scroll;

            if (window.scrollY > scrollThreshold) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return isSticky;
};
