import { AttributeValueType } from "shared/config/interface";

export function showAttributeValues(values: AttributeValueType): string[] | string | undefined {
    if (typeof values === "string" || typeof values === "undefined") {
        return values;
    }

    if (Array.isArray(values)) {
        return values.map((valueObj) => valueObj.value).join(", ");
    }
    if (values === null) {
        return "null";
    }
    return values.value.toString();
}
