import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOnboardingState } from "../types/interface";

const INITIAL_STATE: IOnboardingState = {
    is_initialized: false,
    run: true,
    current_key: "",
    onboarding_keys: []
};

export const onboardingSlice = createSlice({
    name: "onboarding",
    initialState: INITIAL_STATE,
    reducers: {
        setInitatialValues(state, action: PayloadAction<string[]>) {
            state.run = action.payload.length === 0 ? false : true;
            state.is_initialized = action.payload.length === 0 ? false : true;
            state.onboarding_keys = action.payload;
            state.current_key = "";
        },
        updateKeys(state, action: PayloadAction<string>) {
            const new_keys = state.onboarding_keys.filter((key) => key !== action.payload);
            state.current_key = "";
            state.onboarding_keys = new_keys;

            if (new_keys.length === 0) {
                state.run = false;
            }
        },
        setCurrentkey(state, action: PayloadAction<string>) {
            state.current_key = action.payload;
        },
        turnOffOnboarding(state) {
            state.run = false;
            state.is_initialized = false;
        },
        clearOnboarding(state) {
            state.run = false;
            state.current_key = "";
            state.is_initialized = false;
            state.onboarding_keys = [];
        }
    }
});

export const { setInitatialValues, updateKeys, setCurrentkey, turnOffOnboarding, clearOnboarding } = onboardingSlice.actions;
export default onboardingSlice.reducer;
