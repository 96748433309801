import { useAppSelector } from "shared/lib/hooks";
import { user_selector } from "../model/selectors/user-selector";
import { account_organization_selector } from "../model/selectors/account-organization-selector";

interface IResponse {
    organization_id: number;
    user_id: number;
    is_owner: boolean;
    organization_name: string;
}

export const useIsOwnerCurrentOrganization = (): IResponse => {
    const {
        id,
        name,
        owner: { id: ownerID }
    } = useAppSelector(account_organization_selector);
    const { id: userID } = useAppSelector(user_selector);

    return {
        organization_id: id,
        user_id: userID,
        is_owner: ownerID === userID,
        organization_name: name
    };
};
