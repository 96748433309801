import {
    get_approximate_price_selector,
    sums_and_prices_selector,
    annotation_tool_selector,
    active_type_selector,
    current_price_key_selector,
    images_count_selector,
    labels_count_selector,
    attributes_count_selector,
    average_annotations_per_frame_count_selector,
    current_price_element_selector
} from "./model/selectors/calculator-selectors";
import calculatorSlice, { setAnnotationTool, setImages, setLabels, setAttributes, setAverageAnnotationsPerFrame, setPrices, setCurrentPriceElement, setSum } from "./model/slices/calculator-slice";
import { TOOLS, TO_FIXED_VALUE } from "./model/types/config";
import { AnnotationTools, InstrumentsEnum, IDataResult, IOnChange, IPropsCalculator, TypeFiles } from "./model/types/types";
import { calculatorApi } from "./api/calculator-api";

export {
    calculatorSlice,
    get_approximate_price_selector,
    sums_and_prices_selector,
    annotation_tool_selector,
    active_type_selector,
    current_price_key_selector,
    images_count_selector,
    labels_count_selector,
    attributes_count_selector,
    average_annotations_per_frame_count_selector,
    setAnnotationTool,
    TOOLS,
    calculatorApi,
    current_price_element_selector,
    setLabels,
    setImages,
    setAttributes,
    setAverageAnnotationsPerFrame,
    setPrices,
    setCurrentPriceElement,
    setSum,
    TO_FIXED_VALUE,
    TypeFiles
};

export type { InstrumentsEnum, AnnotationTools, IDataResult, IOnChange, IPropsCalculator };
