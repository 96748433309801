export const ANIMDATION_DELAY = 300;

export enum DayType {
    PREV = "prev",
    CURRENT = "current",
    NEXT = "next"
}

export interface ICell {
    label: number;
    is_current?: boolean;
    is_selected?: boolean;
    type: DayType;
    year: number;
    month: number;
    day: number;
}

export const VISIBLE_CELLS_AMOUNT = 7 * 6;

export const DAYS_OF_WEEK = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const MONTH = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const sundayWeekToMondayWeekDayMap: Record<number, number> = {
    0: 6,
    1: 0,
    2: 1,
    3: 2,
    4: 3,
    5: 4,
    6: 5
};

export const _get_current_info = (selectedDate: Date): [day: number, month: number, year: number] => {
    let day = selectedDate.getDate();
    let month = selectedDate.getMonth();
    let year = selectedDate.getFullYear();

    return [day, month, year];
};

export const _days_in_month = (year: number, month: number): number => new Date(year, month + 1, 0).getDate();

export const _is_current_day = (year: number, month: number, day: number): boolean => {
    let now: Date = new Date();

    let day_now = now.getDate();
    let month_now = now.getMonth();
    let year_now = now.getFullYear();

    return day === day_now && month === month_now && year === year_now;
};

export const _get_day_of_the_week = (date: Date): any => {
    const day = date.getDay();

    return sundayWeekToMondayWeekDayMap[day];
};

export const _get_prev_days = (year: number, month: number): ICell[] => {
    const currentMonthFirstDay = new Date(year, month, 1);
    const prevMonthCellsAmount = _get_day_of_the_week(currentMonthFirstDay);
    const daysAmountInPrevMonth = _days_in_month(year, month - 1);

    const dateCells: ICell[] = [];

    for (let i = prevMonthCellsAmount; i >= 0; i--) {
        dateCells.push({
            label: daysAmountInPrevMonth - i,
            is_current: false,
            type: DayType.PREV,
            year,
            month: Number(month - 1),
            day: i
        });
    }

    return dateCells;
};

export const _get_next_days = (year: number, month: number): ICell[] => {
    const currentMonthFirstDay = new Date(year, month, 1);
    const prevMonthCellsAmount = sundayWeekToMondayWeekDayMap[currentMonthFirstDay.getDay()];

    const daysAmount = _days_in_month(year, month);

    const nextMonthDays = VISIBLE_CELLS_AMOUNT - daysAmount - prevMonthCellsAmount;

    // const [cellYear, cellMonth] = month === 11 ? [year + 1, 0] : [year, month + 1];

    const dateCells: ICell[] = [];

    for (let i = 1; i <= nextMonthDays - 1; i++) {
        dateCells.push({
            label: i,
            is_current: false,
            type: DayType.NEXT,
            year,
            month: month + 1,
            day: i
        });
    }

    return dateCells;
};
