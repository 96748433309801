export enum AnnotationTools {
    KEYPOINT = "points",
    POLYGON = "polygon",
    CUBOID = "cuboid",
    RECTANGLE = "rectangle"
}

export enum TypeFiles {
    IMAGE = "image",
    VIDEO = "video"
}

export enum InstrumentsEnum {
    rectangle_image = "rectangle_image",
    rectangle_video = "rectangle_video",
    keypoint = "points",
    polygon_accuracy_lower = "polygon_accuracy_lower",
    polygon_accuracy_higher = "polygon_accuracy_higher",
    cuboid_image = "cuboid_image",
    cuboid_video = "cuboid_video"
}

export type InstrumentType = InstrumentsEnum;
export interface IPrice {
    base_cost: number;
    global_label_up_to: number;
    global_label: number;
    annotation_label: number;
    global_attribute_up_to: number;
    global_attribute: number;
    annotation_attribute: number;
}

type PriceMap = {
    [key in InstrumentType]: IPrice;
};

export interface ICalculatorData extends PriceMap {
    up_to_limit: number;
}

export interface IPropsCalculator {
    filesCountDefault?: number;
    objPerImagesDefault?: number;
    labelsCountDefault?: number;
    attributesCountDefault?: number;
    isTask?: boolean;
    disabled?: boolean;
    annotation_tool?: string | null;
    view?: "full" | "simple";
    free?: boolean;
}

export interface IDataResult {
    name: string;
    value: number;
    color: string;
}

export interface IOnChange {
    [key: string]: (value: number) => void;
}
