import { SUCCESS_ICON } from "shared/lib/helpers/images/images";
import { Information } from "shared/ui";
import Button from "shared/ui/button/button";

const FeedbackSuccess = ({ rate }: { rate: number }): JSX.Element => {
    return (
        <div className="rate">
            <Information
                title="Thank you!"
                description="Thanks for submitting your feedback! We really appreciate your time."
                image={
                    <div className="rate_success_image">
                        <img src={SUCCESS_ICON} alt="Success" />
                    </div>
                }
            />
            {rate === 5 && (
                <>
                    <span className="rate_link">
                        Please share your positive experience by leaving a review at this
                        <a href="https://www.g2.com/products/label-your-data/take_survey" target="_blank" rel="noopener noreferrer">
                            {" "}
                            link
                        </a>
                    </span>
                </>
            )}
        </div>
    );
};

export default FeedbackSuccess;
