import { ACCEPT_IMAGES } from "shared/config/config";
import Alert from "../alert/alert";

interface IUploadFileMessagesProps {
    visibleFileFormatError: boolean;
    sizeLimitError: boolean;
    files: number;
    maxFiles: number;
}

const UploadFileMessages = ({ visibleFileFormatError, sizeLimitError, files, maxFiles }: IUploadFileMessagesProps): JSX.Element => {
    return (
        <div className="upload_message">
            {visibleFileFormatError && <Alert type="error" text="Error! File format is not valid." />}
            {sizeLimitError && <Alert type="error" text="Maximum files size - 1024MB" />}
            <Alert type="warning" text={files === maxFiles ? "Limited to 10 frames. To label more, generate a few extra tasks." : `Regular task is limited to ${maxFiles} frames.`} />
            <Alert type="info" text={"Use only " + ACCEPT_IMAGES + "  image formats"} />
        </div>
    );
};

export default UploadFileMessages;
