import { Button, Confirm, FormItem, Input, Modal } from "shared/ui";
import "./organization-api-create.scss";
import { useEffect, useState } from "react";
import { useAppSelector, useNotification } from "shared/lib/hooks";
import { account_organization_selector, useGenerateApiKeyMutation } from "entities/account";
import { remove_spaces } from "shared/lib/helpers";

const OrganizationApiCreate = ({ visible, onClose }: { visible: boolean; onClose: () => void }): JSX.Element => {
    const [generateApiKey, { isLoading, isSuccess, error, data, reset }] = useGenerateApiKeyMutation();
    const { name } = useAppSelector(account_organization_selector);
    const [value, setValue] = useState<string>("");
    const [apiKey, setApiKey] = useState<string>("");
    const [copied, setCopied] = useState<boolean>(false);
    const notification = useNotification();

    useEffect(() => {
        if (isSuccess && data) {
            notification.success({
                message: `API key has been created.`
            });
            setValue("");
            setApiKey(data.key);
        }
    }, [isSuccess, data]);

    useEffect(() => {
        return () => {
            setValue("");
            setApiKey("");
            setCopied(false);
        };
    }, []);

    function onChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let value = e.target.value;
        setValue(value);
    }

    async function onCopy(): Promise<void> {
        try {
            await navigator.clipboard.writeText(apiKey);
            notification.success({
                message: "API key copied to buffer!",
                duration: 2
            });
            setCopied(true);
        } catch (err) {
            notification.error({
                message: `Failed to copy text - ${JSON.stringify(err)}`,
                duration: 4
            });
        }
    }

    function createAPIKey(): void {
        if (remove_spaces(value).length === 0) return;
        generateApiKey({
            key_name: value,
            organization: name
        });
    }

    function show_error(error: { status: number; data: { organization: string[] } }): string | undefined {
        if (error && error.data) {
            return error.data.organization[0];
        }

        return undefined;
    }

    function onCloseModal(): void {
        setValue("");
        setApiKey("");
        setCopied(false);
        reset();
        onClose();
    }

    return (
        <Modal closable={apiKey ? false : true} width={550} visible={visible} onCancel={onCloseModal} title="Create API key">
            <div>
                {apiKey ? (
                    <>
                        <p className="org_create_api_description">Please save this key in a secure and safe place. You will not be able to see the key after closing this window.</p>
                        <div className="org_create_api">
                            <FormItem label="Your API key" error={show_error(error as any)}>
                                <Input contentEditable="false" value={apiKey} onChange={() => {}} placeholder="Enter API name" />
                            </FormItem>
                            {copied ? (
                                <span className="org_create_api_copied">Copied</span>
                            ) : (
                                <span className="org_create_api_copy" onClick={onCopy}>
                                    Copy
                                </span>
                            )}
                        </div>
                        <Confirm title="Did you copy the API key?" onConfirm={() => onCloseModal()}>
                            <Button style={{ marginLeft: "auto" }}>Done</Button>
                        </Confirm>
                    </>
                ) : (
                    <>
                        <FormItem label="API name" error={show_error(error as any)}>
                            <Input maxLength={50} value={value} onChange={onChange} placeholder="Enter API name" />
                        </FormItem>
                        <Button loading={isLoading} disabled={remove_spaces(value).length === 0 || isLoading} onClick={createAPIKey} style={{ marginLeft: "auto" }}>
                            Create API key
                        </Button>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default OrganizationApiCreate;
