import { FEEDBACK_STEPS, feedback_steps_selector } from "entities/account";
import { useMemo } from "react";
import { useAppSelector } from "shared/lib/hooks";

export const useCheckFeedback = (step: FEEDBACK_STEPS): boolean => {
    const feedback_steps = useAppSelector(feedback_steps_selector);

    const is_available_feedback = useMemo(() => {
        if (!feedback_steps) return false;

        return feedback_steps.includes(step);
    }, []);

    return is_available_feedback;
};
