import { memo } from "react";
import "./tabs.scss";

export interface ITab<T> {
    value: T;
    label: string;
    visible: boolean;
}

interface ITabProps<T> {
    tabs: ITab<T>[];
    active: T;
    onChange: (value: T) => void;
    width?: number;
}

const Tabs = <T,>({ onChange, active, tabs, width = 445 }: ITabProps<T>): JSX.Element => {
    return (
        <div className="ui_tabs" style={{ maxWidth: width }} data-testid="tabs-container">
            {tabs.map(
                ({ value, label, visible }: ITab<T>) =>
                    visible && (
                        <span key={label} onClick={() => onChange(value as T)} className={`ui_tabs_item ${active === value ? "active" : ""}`} data-testid={`tab-${value}`}>
                            {label}
                        </span>
                    )
            )}
        </div>
    );
};

export default memo(Tabs) as <T>(props: ITabProps<T>) => JSX.Element;
