import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./error-boundary";
import AppRoutes from "./routes";
import StoreProvider from "./store-provider";
import GoogleAuthWrapper from "./google-auth-wrapper";
import { MeProvider } from "entities/account";
import MixPanelProvider from "./mixpanel-provider";

function App(): JSX.Element {
    return (
        <div className="App">
            <BrowserRouter>
                <MixPanelProvider>
                    <StoreProvider>
                        <GoogleAuthWrapper>
                            <ErrorBoundary>
                                <MeProvider>
                                    <AppRoutes />
                                </MeProvider>
                            </ErrorBoundary>
                        </GoogleAuthWrapper>
                    </StoreProvider>
                </MixPanelProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
